import React, {  useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import {
  IonContent,
  IonPage,
  IonButton,
  IonInput,
  IonImg,
  IonLoading,
  IonActionSheet,
  IonIcon,
  IonRange,
  IonRippleEffect
} from "@ionic/react";
import { closeOutline, colorPaletteOutline, trash, create, eyeOutline, createOutline, addCircleOutline, chevronForwardOutline, addOutline, removeOutline, saveOutline, trashOutline, notifications, arrowRedo, removeCircleOutline, arrowBackOutline } from 'ionicons/icons';
import './../styles/primerapagina.css';
import {ReactComponent as ColumnIcon} from './../vectors/columns.svg';
import {ReactComponent as MicatalogoIcon } from './../vectors/micatalogo.svg';
import RetrieveImage from './../retrieveImg';
import Resizer from 'react-image-file-resizer';
import { ColorExtractor } from 'react-color-extractor';
import Reorder, { reorder } from 'react-reorder';
import Cropper from 'react-easy-crop';
import InputColor from 'react-input-color';

import VirtualElements from './VirtualElements';

// MOBX
import { MobXProviderContext, observer } from "mobx-react";
import AddItemModal from "./AddItemModal2";
import AddProductModal from "./AddItemModal";

const TabOnePage = ({ addItem }) => {
	const history = useHistory();
	
	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [showProductModal, setShowProductModal] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [mensajeCargando, setMensajeCargando] = useState('Cargando...');
	const [imagenportada, setImagenportada] = useState('');
	const [logo, setLogo] = useState('');
	const [nombreempresa, setNombreempresa] = useState('');
	const [paleta, setPaleta] = useState([]);
	const [editTitulo, setEditTitulo] = useState(false);
	const [totalcategorias, setTotalcategorias] = useState(0);
	const [showActionSheet, setShowActionSheet] = useState(false);
	const [currentCategory, setCurrentCategory] = useState('');
	const [columnas, setColumnas] = useState('dos');
	const [productToEdit, setProductToEdit] = useState(null);
	const [categorias, setCategorias] = useState([]);
	const [viendoHija, setViendoHija] = useState(false);
	const [mostrarCropper, setMostrarCropper] = useState(false);
	
	const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
	const [inputImg, setInputImg] = useState('');
	const [blobPortada, setBlobPortada] = useState(null);
	const [avisoGratis, setAvisoGratis] = useState({
		mostrar: false,
		dias: 15,
		texto: '',
		accion: '',
	});
	
	const fieldTitulo = useRef(null);
	
	const {store} = React.useContext(MobXProviderContext);
	
	useEffect(() => {
		var tienePopup = window.location.href.includes('?from=registration');
		if (tienePopup) {
			var nuevaurl = window.location.href.replace('?from=registration', '');
			history.push('/tabs/home');
			// window.history.pushState(document.body.innerHTML, document.querySelector('title').innerHTML, nuevaurl);
			var newDiv = document.createElement('div');
			newDiv.innerHTML = document.getElementById('backdropModal').innerHTML;
			newDiv.classList = 'bodybackdrop';
			document.querySelector('body').insertAdjacentElement('beforebegin', newDiv)

			document.querySelector('html .bodybackdrop button').addEventListener('click', () => {
				document.querySelector('html .bodybackdrop').remove();
			})
		}
	}, [])

	useEffect(() => {
		setImagenportada(store.activeUser.portada_base ? RetrieveImage(store.activeUser.portada_base) : '/img/portada.png');
		setLogo(store.activeUser.logo_url ? RetrieveImage(store.activeUser.logo_url) : null);
		setNombreempresa(store.activeUser.nombreempresa ? store.activeUser.nombreempresa : 'Especificar nombre');
		setPaleta(store.activeUser.paleta ? store.activeUser.paleta : []);
		// console.log(store.activeUser.paleta);
		if (store.itemCategories) {
			let acomodado = store.itemCategories.filter(([key, value]) => !value.hasOwnProperty('parent')).sort(([key1, value1], [key2, value2]) => {
				return (value1.position > value2.position) ? 1 : ((value2.position > value1.position) ? -1 : 0);
			});
			setCategorias(acomodado);
			setViendoHija(false);
		}

		if(store.plan && store.facturas) {
			// console.log(store.plan)
			var idplan = store.plan.id_plan;
			var facturas = {...store.facturas};

			var facturaPrev = Object.values(facturas).filter(item => {
				// console.log(idplan, item)
				return item.id_plan === idplan;
			})
			var factura = {...facturaPrev[0]};
			// console.log(factura)
			var firstDate = new Date(factura.mes_pago + '/' + factura.dia_pago + '/' + factura.year_pago);
			var secondDate = new Date(Date.now());

			var Difference_In_Time = secondDate.getTime() - firstDate.getTime();
			var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
			// console.log(store.plan)
			var diasrestantes = (store.plan.duracionendias - Difference_In_Days)
			// alert(diasrestantes)
			if (((factura.hasOwnProperty('isFree') && factura.isFree) || diasrestantes <= 5) && factura.estado === 'pendiente') {
				setAvisoGratis({
					mostrar: true,
					dias: diasrestantes,
					texto: (factura.hasOwnProperty('isFree') && factura.isFree ? 'Tu prueba gratuita' : 'Tu plan'),
					accion: (factura.hasOwnProperty('isFree') && factura.isFree ? 'Actualizar membresía' : 'Pagar ahora')
				});
			}
		}
    }, [store.itemCategories]) // eslint-disable-line react-hooks/exhaustive-deps
	
	const sizes = {
		portada: {
			'medium': {
				'width': 414,
				'height': 414
			},
			'large': {
				'width': 600,
				'height': 600,
			},
			'full': {
				'width': 1200,
				'height': 1200
			}
		},
		icons: {
			'72': {
				'width': 72,
				'height': 72
			},
			'96': {
				'width': 96,
				'height': 96
			},
			'128': {
				'width': 128,
				'height': 128
			},
			'144': {
				'width': 144,
				'height': 144
			},
			'152': {
				'width': 152,
				'height': 152
			},
			'192': {
				'width': 192,
				'height': 192
			},
			'384': {
				'width': 384,
				'height': 384
			},
			'512': {
				'width': 512,
				'height': 512
			},
			'120': {
				'width': 120,
				'height': 120
			},
			'180': {
				'width': 180,
				'height': 180
			},
			'167': {
				'width': 167,
				'height': 167
			}
		}
	}

	if (!store.activeUser) return null;
	
	const resizeFileNotSquare = (file, sizes) => new Promise(resolve => {
		Resizer.imageFileResizer(file, sizes.width, sizes.height, 'JPEG', 100, 0,
		uri => {
		  resolve(uri);
		},
		'blob'
		);
	});
	
	const resizeFileInPNG = (file, sizes) => new Promise(resolve => {
		Resizer.imageFileResizer(file, sizes.width, sizes.height, 'PNG', 100, 0,
		uri => {
		  resolve(uri);
		},
		'blob', sizes.width, sizes.height
		);
	});
	
	const resizeFile = (file, sizes) => new Promise(resolve => {
		Resizer.imageFileResizer(file, sizes.width, sizes.height, 'PNG', 100, 0,
		uri => {
		  resolve(uri);
		},
		'blob', sizes.width, sizes.height
		);
	});
  
	const setLoadingText = (text) => {
		let elem = document.querySelector(".homepageloader div.loading-wrapper div.loading-content");
		if (elem) {
			elem.innerHTML = text;
		}
	}
	
	const handlePortada = async (event) => {
		const file = event.target.files[0]
        const reader = new FileReader();
		reader.addEventListener('load', () => {
            setInputImg(reader.result);
			setMostrarCropper(true);
        }, false)
		
		if (file) {
            reader.readAsDataURL(file)
        }
	}
	
	const handlePortada_back = async (event) => {
		setShowLoading(true);
		setLoadingText('Procesando imagen...');

		let file = blobPortada;
		// console.log(file);
		let original_image = `configuration/${store.activeUser.id}/portada/full.jpg`;
		// await store.uploadFile(original_image, file);
		
		setLoadingText('Generando paleta de colores...');
		await setPaleta([]);
		setImagenportada(URL.createObjectURL(file));
		
		// console.log(firstUpload);
		
		// newsnap.ref.update({
				// portada_base: '/configuration/'+id_employee+'/portada/'
			// });
		
		for (let k in sizes.portada) {
			// console.log(sizes.portada[k]);
			setLoadingText('Creando imagen tamaño ' + k + '...');
			let size = sizes.portada[k];
			let imagenew = await resizeFileNotSquare(file, size);
			await store.uploadFile(`configuration/${store.activeUser.id}/portada/`+k+`.jpg`, imagenew);
			// const storageRef2 = firebase.storage().ref(`configuration/${id_employee}/portada/`+k+`.jpg`);
			// const task2 = storageRef2.put(imagenew);
		}
		setLoadingText('Guardando registro...');
		await store.saveInClientProfile({portada_base: original_image, paleta: paleta});
		setShowLoading(false);
		setMostrarCropper(false);
		setBlobPortada(null);
	}
	
	const handleLogo = async (event) => {
		setShowLoading(true);
		setLoadingText('Procesando imagen...');
		
		let file = event.target.files[0];
		let original_image = `configuration/${store.activeUser.id}/logo.png`;
		const finalFile = await resizeFileInPNG(file, {width: 150, height: 150});
		await store.uploadFile(original_image, finalFile);
		
		setLogo(URL.createObjectURL(finalFile));
		setLoadingText('Generando favicons...');
		for (let kk in sizes.icons) {
			var cursize = sizes.icons[kk];
			let nuevaImagenSizeada = await resizeFileInPNG(file, cursize);
			await store.uploadFile(`configuration/${store.activeUser.id}/icons/`+kk+`.png`, nuevaImagenSizeada);
		}
		setLoadingText('Guardando registro...');
		await store.saveInClientProfile({logo_url: original_image});
		setShowLoading(false);
	}
	
  
	const handleClickUploader = () => {
		let element = document.getElementById('portadaUploader');
		element.click();
	}
	
	const handleClickLogo = () => {
		let element = document.getElementById('logoUploader');
		element.click();
	}
	
	const toggleEditTitulo = () => {
		setEditTitulo(!editTitulo);
		fieldTitulo.current.setFocus();
	}
	
	const handleChangeTitulo = (e) => {
		// console.log(e.target.value);
		setNombreempresa(e.target.value);
	}
	
	const handleSaveTitulo = async () => {
		if (nombreempresa !== '') {
			setShowLoading(true);
			setMensajeCargando('Guardando registro...');
			// setLoadingText('Guardando registro...');
			await store.saveInClientProfile({nombreempresa: nombreempresa});
			setShowLoading(false);
			toggleEditTitulo();
			return true;
		}
		alert('El nombre de la empresa es obligatorio');
	}
	
	const _renderItemsCategories = () => {
		return categorias.map(([key, value]) => {
		  // console.log(key, value, value.name);
		  return (
			<div key={value.id} className="categoryItContainer">
				<IonButton onClick={() => {setShowActionSheet(true); setCurrentCategory(value.id);} } className="categoryItem editable">
					<IonImg className="imagenCategory" src={value.imagen_def ? RetrieveImage(value.imagen_def) : '/img/categoria.png'} />
				</IonButton>
				<h4 className="categoryTitle">{value.name}</h4>
			</div>
		  );
		});
	};
	
	const updatePositionOfCategories = async (reordered) => {
		const promised = await reordered.map(async ([key, value], k) => {
			let toSend = {
				position: k,
				updated: Date.now()
			}
			const response = await store.updateCategory(key, { ...toSend });
			return response;
		});
		await Promise.all(promised);
		// console.log(result);
	}
	
	const onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
		const reordered = reorder(categorias, previousIndex, nextIndex);
		setCategorias(reordered);
		updatePositionOfCategories(reordered);
	}

	const _renderList = () => {
		return (
			<>
				{viendoHija ? <IonButton onClick={(e) => { setViendoHija(false); volverCategorias(); }} className="categoryItem">
					<IonIcon icon={arrowBackOutline} style={{color:'#000', fontSize: 30}} />
				</IonButton> : <IonButton onClick={(e) => { setShowAddItemModal(true); setCurrentCategory(''); }} className="categoryItem">
					<IonImg className="imagenCategory" src="/img/subirportada.jpg" />
				</IonButton>}
				<Reorder
					  reorderId="test"
					  reorderGroup="reorder-categories"
					  lock='vertical'
					  holdTime={500}
					  listClass='dragged'
					  itemClass='list-item'
					  onReorder={onReorder.bind(this)}
				>
				{_renderItemsCategories()}
				</Reorder>
			</>
		);
	};
	
	const _renderProductList = () => {
		return (
			<>
				<div className="cuadradoProductos">
					<div className="contenedorCuadrado">
						<IonButton onClick={(e) => { setProductToEdit(null); setShowProductModal(true); }} mode="md" className="logoUploader notabsolute cuadrado">
							<IonImg src="/img/addproduct.jpg" />
						</IonButton>
					</div>
				</div>
				{_renderItemProducts()}
			</>
		);
	}
	const addCommas = (nStr) => {
		nStr += '';
		var x = nStr.split('.');
		var x1 = x[0];
		var x2 = x.length > 1 ? '.' + x[1] : '';
		var rgx = /(\d+)(\d{3})/;
		while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + '.' + '$2');
		}
		return x1 + x2;
	}
	const returnPrice = (price) => {
		return addCommas(price);
	}
	
	const _renderItemProducts = () => {
		const { innerWidth: width } = window;
		let finalWidth = (width > 600 ? 600 : width);
		let finalContainerWidth = finalWidth * 0.98;
		let minHeight = ((finalContainerWidth - (finalContainerWidth * 0.02)) / 2) - 15;
		return store.itemProducts.map(([key, value]) => {
		  // console.log(key, value, value.name);
		  return (
			  <VirtualElements className={'cuadradoProductos'} height={minHeight} key={key}>
				<div className="contenedorCuadrado">
					<IonButton onClick={(e) => { setShowProductModal(true); setProductToEdit({id: value.id, item: value}) }} mode="md" className="logoUploader notabsolute cuadrado item">
						{(value.cover && typeof value.cover === 'string') ? (<IonImg src={RetrieveImage(value.cover)} alt="Cover" />) : ((value.cover && typeof value.cover === 'object' && value.cover.length) ? (<IonImg src={RetrieveImage(value.cover[0])} alt="Cover" />) : '')}
						<IonIcon slot="icon-only" icon={createOutline} />
						<div className="infoContentEdit">
							<h4>{value.name}</h4>
							<h5>
								<span>Ref: {value.reference}</span>
								<span>$ {returnPrice(value.price)}</span>
							</h5>
						</div>
					</IonButton>
				</div>
			  </VirtualElements>
		  );
		});
	}
	
	const handleCategorySubmit = async (data) => {
		setShowLoading(true);
		setMensajeCargando('Procesando datos...');
		
		// setLoadingText('Guardando registro...');
		// await store.saveInClientProfile({logo_url: original_image});
		// setShowLoading(false);
		let newTotalCategories = (parseInt(totalcategorias) + 1);
		let toSend = {
			name: data.name,
			position: 10000
		}
		if (data.hasOwnProperty('parent') && data.parent !== '') {
			toSend.parent = data.parent;
		}
		const result = await store.addCategory({ ...toSend });
		if (result.hasOwnProperty('id')) {
			if (data.categoryimage != null) {
				setLoadingText('Procesando imagen...');
				
				let urlImagenCategory = `categories/${store.activeUser.id}/${result.id}/category.jpg`;
				const finalFile = await resizeFile(data.categoryimage, {width: 100, height: 100});
				await store.uploadFile(urlImagenCategory, finalFile);
				console.log(result.id);
				await store.updateCategory(result.id, { ...{imagen_def: urlImagenCategory} })
			}
		} else {
			setShowLoading(false);
			return false;
		}
		setLoadingText('Contabilizando categorías...');
		await store.saveInClientProfile({total_categories: newTotalCategories});
		await setTotalcategorias(newTotalCategories);
		// let newArray = [...categorias];
		let acomodado = store.itemCategories.filter(([key, value]) => !value.hasOwnProperty('parent')).sort(([key1, value1], [key2, value2]) => {
			return (value1.position > value2.position) ? 1 : ((value2.position > value1.position) ? -1 : 0);
		});
		setCategorias(acomodado);
		// console.log(acomodado);
		setShowLoading(false);
	}
	
	const handleDelete = async () => {
		setShowLoading(true);
		await setMensajeCargando('Eliminando categoría...');
		await setLoadingText('Eliminando categoría...');
		
		let newTotalCategories = (parseInt(totalcategorias) - 1);
		// console.log(currentCategory); return false;
		await store.deleteCategory(currentCategory);
		
				
		await setLoadingText('Contabilizando categorías...');
		await store.saveInClientProfile({total_categories: newTotalCategories});
		await setTotalcategorias(newTotalCategories);
		
		setShowLoading(false);
	}

	const handleHijas = async () => {
		let acomodado = store.itemCategories.filter(([key, value]) => value.hasOwnProperty('parent') && value.parent === currentCategory).sort(([key1, value1], [key2, value2]) => {
			return (value1.position > value2.position) ? 1 : ((value2.position > value1.position) ? -1 : 0);
		});
		setCategorias(acomodado);
		setViendoHija(true);
	}

	const volverCategorias = () => {
		let acomodado = store.itemCategories.filter(([key, value]) => !value.hasOwnProperty('parent')).sort(([key1, value1], [key2, value2]) => {
			return (value1.position > value2.position) ? 1 : ((value2.position > value1.position) ? -1 : 0);
		});
		setCategorias(acomodado);
	}
	
	const handleProductSubmit = async (data) => {
		// console.log(data);
		setShowLoading(true);
		setMensajeCargando('Procesando datos...');
		
		let currentImages = data.imagesPre;
		delete data.productImage;
		
		let toSend = data;
		// console.log(toSend);
		delete toSend['imagesPre'];
		const keepCombinations = toSend['combinations'];

		delete toSend['combinations'];

		// console.log(toSend)
		if (typeof toSend.categories === 'undefined') {
			toSend.categories = '';
		}

		if (toSend.adiciones.length) {
			// toSend.adiciones = JSON.stringify(toSend.adiciones);
		}

		// console.log(toSend);
		let result = {};
		if (toSend.hasOwnProperty('id')) {
			let idf = toSend.id;
			delete toSend['id'];
			result = await store.updateProduct(idf, { ...toSend});
		} else {
			toSend.status = 1;
			result = await store.addProduct({ ...toSend });
		}
		// console.log(result, currentImages);
		if (result.hasOwnProperty('id')) {
			fetch('https://micatalogovirtual.com/recibirCombis.php', {
				method: 'POST',
				body: JSON.stringify({id: store.activeUser.id, id_product: result.id, data: {...toSend, combinations: keepCombinations}})
			})
			if (currentImages !== null && currentImages.length) {
				setLoadingText('Procesando imagenes...');
				console.log('DASDSA', currentImages)
				var enviarImages = [];
				for (let index in currentImages) {
					var currentImage = currentImages[index];
					if (typeof currentImage !== 'string') {
						let urlImagenProduct = `products/${store.activeUser.id}/${result.id}/cover_${Date.now()}.jpg`;
						console.log('SADADAS', urlImagenProduct, currentImage, typeof currentImage)
						const finalFile = await resizeFile(currentImage, {width: 600, height: 600});
						console.log('CZXZXC', finalFile)
						await store.uploadFile(urlImagenProduct, finalFile);
						console.log('REEWREWEW FINAL')
						enviarImages.push(urlImagenProduct);
					} else {
						enviarImages.push(currentImage);
					}
				}
				// console.log(enviarImages)
				await store.updateProduct(result.id, { ...{cover: enviarImages} })
			}
		} else {
			setShowLoading(false);
		}
		setShowLoading(false);
	}
	
	
	const createImage = (url) =>
		new Promise((resolve, reject) => {
			const image = new Image()
			image.addEventListener('load', () => resolve(image))
			image.addEventListener('error', error => reject(error))
			image.setAttribute('crossOrigin', 'anonymous')
			image.src = url
		})
	
	const getCroppedImg = async (imageSrc, crop) => {
		const image = await createImage(imageSrc)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')

		/* setting canvas width & height allows us to 
		resize from the original image resolution */
		canvas.width = 600
		canvas.height = 217

		ctx.drawImage(
			image,
			crop.x,
			crop.y,
			crop.width,
			crop.height,
			0,
			0,
			canvas.width,
			canvas.height
		)

		return new Promise((resolve) => {
			canvas.toBlob((blob) => {
				resolve(blob)
			}, 'image/jpeg')
		})
	}
	
	const onCropComplete = async (croppedArea, croppedAreaPixels) => {
		// console.log(croppedArea, croppedAreaPixels);
		const croppedImage = await getCroppedImg(
            inputImg,
            croppedAreaPixels
        )
		await setBlobPortada(croppedImage);
		// handlePortada_back();
	}
	
	const handleSendNotifications = async () => {
		setShowLoading(true);
		setMensajeCargando('Enviando notificaciones...');
		var prompt = window.prompt('¿Qué notifiación desea enviar a sus clientes?', ('¡Tenemos nuevas actualizaciones para ti en ' + nombreempresa + '!'))
		if (prompt) {
			let url = 'https://enviarnotifiaciones-splaq4s7fa-uc.a.run.app?id=' + store.activeUser.id +'&mensaje=' + encodeURIComponent(prompt);
			const response = await fetch(url);
			const data = await response.json();
			alert(data.mensaje);
		}
		setShowLoading(false);
	}

	const handleColorTheme = async (color) => {
		var value = color.hex;
		if (value.length > 7) {
			value = value.slice(0, -2);
		} else if (value.length === 5) {
			value = value + 'ff';
		}
		let invertColor = (hex) => {
			if (hex.indexOf('#') === 0) {
				hex = hex.slice(1);
			}
			if (hex.length === 3) {
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			}
			if (hex.length !== 6) {
				throw new Error('Invalid HEX color.');
			}
			var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
				g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
				b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
			return '#' + padZero(r) + padZero(g) + padZero(b);
		}
		
		let padZero = (str, len) => {
			len = len || 2;
			var zeros = new Array(len).join('0');
			return (zeros + str).slice(-len);
		}

		let secondColor = invertColor(value);
		setPaleta([value, secondColor]);
		store.saveInClientProfile({paleta: [value, secondColor]});
	}
	
	return (
    <IonPage>
		<style>{(paleta[0] ? `
			.botonColumnas svg .a {
				stroke: ${paleta[0]};
			}

			.whatsappButtonGlobal {
				background: ${paleta[0]}
			}
		` : [])}
		</style>

		<div id="backdropModal">
			<div className="popup">
				<button><IonIcon slot="start" icon={closeOutline} /></button>
				<IonImg src="/img/popup.png" />
				<h2>¡Gracias por registrarte!</h2>
				<p>Revisa tu correo para visualizar las instrucciones de uso de tu catalogo y confirmar tu registro, recuerda verificar tu bandeja de Spam si no ves el correo en la bandeja principal</p>
			</div>
		</div>
		
		<IonLoading
			cssClass='homepageloader'
			isOpen={showLoading}
			message={mensajeCargando}
		/>
		
		
		<ColorExtractor
		  src={imagenportada || '/img/portada.png'}
		  getColors={colors => { if (!store.activeUser.hasOwnProperty('paleta')) { setPaleta(colors) } } }
		/>
		
		
		<AddItemModal
          showModal={showAddItemModal}
          onDidDismiss={(_v) => {
			// console.log(_v);
            if (_v) {
				handleCategorySubmit(_v.result);
              // console.log(_v.result);
              // store.addItem({ ..._v.result });
            }
            setShowAddItemModal(false);
			// window.location.hash = '';
          }
		  }
        />
		
		<AddProductModal
          showModal={showProductModal}
		  toEdit={productToEdit}
          onDidDismiss={(_v) => {
			// console.log(_v);
            if (_v) {
				handleProductSubmit(_v.result);
				// handleCategorySubmit(_v.result);
              // console.log(_v.result);
              // store.addItem({ ..._v.result });
            }
            setShowProductModal(false);
			// window.location.hash = '';
          }
		  }
        />
		
		<IonActionSheet
			isOpen={showActionSheet}
			onDidDismiss={() => setShowActionSheet(false)}
			cssClass='realActions'
			mode="ios"
			buttons={viendoHija ? [
			{
			  text: 'Editar',
			  icon: create,
			  handler: () => {
				// setShowAddItemModal(true);
				history.push('/tabs/home/category/' + currentCategory);
			  }
			}, {
			  text: 'Eliminar',
			  role: 'destructive',
			  icon: trash,
			  handler: () => {
				handleDelete();
				// console.log('Delete clicked');
			  }
			}, {
			  text: 'Cancelar',
			  role: 'cancel',
			  handler: () => {
				// console.log('Cancel clicked');
			  }
			}] : [
				{
				  text: 'Editar',
				  icon: create,
				  handler: () => {
					// setShowAddItemModal(true);
					history.push('/tabs/home/category/' + currentCategory);
				  }
				},
				{
					text: 'Ver categorías hijas',
					icon: eyeOutline,
					handler: () => {
					  // setShowAddItemModal(true);
					  handleHijas();
					}
				}, {
				  text: 'Eliminar',
				  role: 'destructive',
				  icon: trash,
				  handler: () => {
					handleDelete();
					// console.log('Delete clicked');
				  }
				}, {
				  text: 'Cancelar',
				  role: 'cancel',
				  handler: () => {
					// console.log('Cancel clicked');
				  }
				}]
			}
		  >
		</IonActionSheet>
		{/* {avisoGratis.mostrar ? (
			<div className="avisopagogratis">
				<IonButton onClick={() => { setAvisoGratis({mostrar: false, dias: 15, texto: '', accion: ''}) }}><IonIcon icon={closeOutline} /></IonButton>
				<p>{avisoGratis.dias > 0 ? avisoGratis.texto + ' finaliza en ' +avisoGratis.dias+' días, actualiza tu membresía ahora.' : avisoGratis.texto + ' ha finalizado. Actualiza tu membresía para continuar.'}</p>
				<IonButton onClick={() => { history.push('/tabs/facturacion'); }} className="botonactualizarmembresia">
					{avisoGratis.accion}
				</IonButton>
			</div>
		) : null} */}
      <IonContent>
		<div id="realUploaderBack">
			<input type="file" id="portadaUploader" onChange={handlePortada} accept="image/*" className="uploaderInput" />
			<IonButton onClick={handleClickUploader} mode="md" className="botonportada" style={paleta[0] ? {borderColor: paleta[0]} : {}} id="cuadroPortada">
				<span className="botonRedondo"><IonImg src={'/img/subirportada.jpg'} /></span>
				{(imagenportada !== '' ? 
					<IonImg id="portadaActual" src={imagenportada} className='portadaActual' /> :
					''
				)}
			</IonButton>
			<div className="cropperPutted">
				{
					(mostrarCropper) ?
					(
						<div className="containerCropFull">
							<div className="crop-container">
								<Cropper
									image={inputImg}
									crop={crop}
									zoom={zoom}
									maxZoom={4}
									aspect={2.75/1}
									onCropChange={setCrop}
									onCropComplete={onCropComplete}
									onZoomChange={setZoom}
								/>
							</div>
							<div className="controls">
								<IonRange min={1} max={4} value={zoom} onIonChange={e => { setZoom(e.detail.value) }} color="secondary">
									<IonIcon size="small" slot="start" icon={removeOutline} />
									<IonIcon slot="end" icon={addOutline} />
								</IonRange>
								<div className="botonesCrop">
									<IonButton
									  className="specialButtonAdd"
									  onClick={() => {
											handlePortada_back();
									  }}
									>
										Guardar imagen <IonIcon slot="end" icon={saveOutline} />
									  <IonRippleEffect type="unbounded"></IonRippleEffect>
									</IonButton>
									
									<IonButton
									  className="specialButtonAdd"
									  onClick={() => {
											setInputImg('');
											setMostrarCropper(false);
									  }}
									>
										Cancelar <IonIcon slot="end" icon={trashOutline} />
									  <IonRippleEffect type="unbounded"></IonRippleEffect>
									</IonButton>
								</div>
							</div>
						</div>
					) : ''
				}
			</div>
			
			<div id="segundaLinea">
				<div id="TiendaWhatsContainer">
					<div id="TiendaWhats" style={paleta[0] ? {backgroundColor: paleta[0]} : {}}>
						Tienda Whatsapp
					</div>
				</div>
				<div className="logoUploaderContainer">
					<input type="file" id="logoUploader" onChange={handleLogo} accept="image/*" className="uploaderInput" />
					<IonButton onClick={handleClickLogo} mode="md"className="logoUploader" style={paleta[0] ? {borderColor: paleta[0]} : {}}>
						<span className="botonRedondo sinTop"><IonImg src={'/img/uploadlogo.jpg'} /></span>
						{(logo ? 
							<IonImg src={logo} className='logoActual' /> :
							''
						)}
					</IonButton>
				</div>
				<div id="iconosDerecha">
					<a href="https://micatalogovirtual.com/" className="micatalogoButton" target="_blank" rel="noopener noreferrer"><MicatalogoIcon /></a>
					<div className="containerForColor">
						<InputColor
							initialValue={(paleta[0] ? paleta[0] : '#ff0000')}
							onChange={(color) => { handleColorTheme(color) } }
							placement="left"
							className="realColorInput"
						/>
						<span className="whatsappButtonGlobal"><IonIcon icon={colorPaletteOutline} /></span>
					</div>
				</div>
			</div>
		</div>
		<div id="userTiendaInfo">
			<div id="formtitulo" className={(!editTitulo ? 'hiddenforce' : '')}>
				<IonInput ref={fieldTitulo} type="text" className="tituloPrincipal" value={nombreempresa} onInput={handleChangeTitulo} />
				<IonButton onClick={handleSaveTitulo} mode="ios" className="botonGuardarTitulo">
					Guardar <IonIcon slot="end" icon={addCircleOutline} />
				</IonButton>
			</div>
			
			<h1 className={'tituloPrincipal ' + (editTitulo ? 'hiddenforce' : '')}>{nombreempresa} <IonButton className="inlineButton" onClick={ toggleEditTitulo }><IonIcon slot="icon-only" icon={createOutline} /></IonButton></h1>
		</div>
		<div id="InfoCategories">
			<div className="totales" style={{minWidth:'17%'}}>
				<h3>Productos</h3>
				<p>{store.itemProducts.length}</p>
			</div>
			<div className="totales buscador">
				<IonButton onClick={() => { handleSendNotifications() }} className="botonNotifs"><IonIcon icon={notifications} slot="start" /> Enviar notificación</IonButton>
			</div>
			<div className="totales">
				<a href={'https://' + (store.tienda.hasOwnProperty('tienda') && store.tienda.tienda ? store.tienda.tienda + '.' : '') + 'micatalogovirtual.com/'} className="botonExternalLink" target="_blank" rel="noreferrer noopener"><IonIcon icon={arrowRedo} /></a>
			</div>
		</div>
		<div className="filtroColumnas">
			<div className="columnaField">
				<IonButton onClick={() => { setColumnas('una'); }} className="botonColumnas" dataColumnas="onecolumn">
					<ColumnIcon />
				</IonButton>
			</div>
			<div className="columnaField">
				<IonButton onClick={() => { setColumnas('dos'); }} className="botonColumnas activo" dataColumnas="twocolumns">
					<ColumnIcon /><ColumnIcon />
				</IonButton>
			</div>
			<div className="columnaField">
				<IonButton onClick={() => { setColumnas('tres'); }} className="botonColumnas" dataColumnas="threecolumns">
					<ColumnIcon /><ColumnIcon /><ColumnIcon />
				</IonButton>
			</div>
		</div>
		<div className="categoriesContPrincipal">
			<div className="categoriesContainer">
				{_renderList()}
			</div>
		</div>
		<div className="separadorViewAll">
			<h3>Productos</h3>
			<IonButton onClick={() => { history.push('/tabs/productos'); }} className="botonsombreado" color="light" size="small">
				Ver todos <IonIcon icon={chevronForwardOutline} slot="end" />
			</IonButton>
		</div>
		<div className={columnas + ' productosPrincipal admin'}>
			{_renderProductList()}
		</div>
      </IonContent>
    </IonPage>
	);
};

export default observer(TabOnePage);
