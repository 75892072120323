import React, { useState, useEffect } from "react";
import {
  IonItem,
  IonContent,
  IonPage,
  IonLabel,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonAvatar,
  IonIcon,
  IonBackButton,
  IonItemOption,
  IonLoading,
  IonAlert,
  IonRippleEffect,
  IonFooter,
  IonToast
} from "@ionic/react";
// MOBX
import { MobXProviderContext } from "mobx-react";
import { logoWhatsapp, createOutline, trashOutline, arrowRedoOutline, addCircleOutline } from 'ionicons/icons';
import './../styles/lineas.css';
import LineasModal from "./LineasModal";

const Lineas = () => {
	const { store } = React.useContext(MobXProviderContext);
	const [lineas, setLineas] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [edititem, setEdititem] = useState(null);
	const [deleteitem, setDeleteitem] = useState(null);
	const [showAlert, setShowAlert] = useState(false);
	const [showToast2, setShowToast2] = useState(false);
	const [hideAddMore, setHideAddMore] = useState(false);


	let user = store.activeUser;
	
	useEffect(() => {
		if (user && user.hasOwnProperty('lineas')) {
			const Lineas = user.lineas;
			if (Lineas !== '') {
				const lineasObj1 = JSON.parse(Lineas);
				const lineasObj = lineasObj1.lineas;
				setLineas(lineasObj);

				// validar si ya se paso de las lineas permitidas
				let infoPlan = {...store.plan};
				var totalLineas = Object.keys(lineasObj).length;
				if (infoPlan.lineas <= totalLineas) {
					setHideAddMore(true)
				}
			}
		}
	}, [user.lineas]); // eslint-disable-line react-hooks/exhaustive-deps
  
	const _renderLineas = () => {
		let ret = [];
		
		for (let item in lineas) {
			ret.push(
				<div  key={item}>
					<IonItem className="ionItemSinPaddingCasi">
						<IonAvatar slot="start">
							<IonIcon size="large" color="success" icon={logoWhatsapp} />
						</IonAvatar>
						<IonLabel>
							<h2>{lineas[item][0]}</h2>
							<h3>{lineas[item][1]}</h3>
						</IonLabel>
						<IonItemOption className="botonLinea" onClick={() => { copyOne(lineas[item]) }}><IonIcon size="large" icon={arrowRedoOutline} color="primary" /></IonItemOption>
						<IonItemOption className="botonLinea" onClick={() => { editOne(lineas[item], item) }}><IonIcon size="large" icon={createOutline} color="warning" /></IonItemOption>
						<IonItemOption className="botonLinea" onClick={() => { deleteOne(item) }}><IonIcon size="large" icon={trashOutline} color="danger" /></IonItemOption>
					</IonItem>
				</div>
			)
		}
		return ret;
	}
	
	const copyOne = (item) => {
		const url = 'https://'+((store.tienda.hasOwnProperty('tienda') && store.tienda.tienda) ? store.tienda.tienda : 'www')+'.micatalogovirtual.com/' + item[1];
		navigator.clipboard.writeText(url);
		setShowToast2(true);
	}
	
	const editOne = (item, id) => {
		setEdititem({item, id});
		setShowModal(true);
	}
	
	const deleteOne = (id) => {
		setShowAlert(true);
		setDeleteitem(id);
	}
	
	const handleDeleteOne = async () => {
		setShowAlert(false); 
		setShowLoading(true);
		
		const actualLineas = {...lineas};
		delete actualLineas[deleteitem];
		const toSend = {lineas: actualLineas};
		setHideAddMore(false);
		await store.saveInClientProfile({lineas: JSON.stringify(toSend)});
		setShowLoading(false);
	}
	
	const handleSaveData = async (data) => {
		setShowLoading(true);
		
		const actualLineas = {...lineas};
		let key = (data.hasOwnProperty('id') ? data.id : Date.now());
		actualLineas[key] = [data.name, data.number];
		setLineas(actualLineas);
		
		const toSend = {lineas: actualLineas};
		await store.saveInClientProfile({lineas: JSON.stringify(toSend)});
		setShowLoading(false);
	}
  
	return user ? (
    <IonPage>
	    <IonLoading
			cssClass='homepageloader'
			isOpen={showLoading}
			message="Actualizando información..."
		/>
		
		<IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'¿Está seguro?'}
          message={'Confirma que deseas eliminar esta línea'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
			  
            },
            {
              text: 'Eliminar',
              handler: () => {
                handleDeleteOne()
              }
            }
          ]}
        />
		
		<IonToast
			mode="ios"
			color="dark"
			duration={3000}
			isOpen={showToast2}
			onDidDismiss={() => setShowToast2(false)}
			message="URL copiada al portateles"
			position="top"
		/>
		
		<LineasModal
			showModal={showModal}
			toEdit={edititem}
			onDidDismiss={(_v) => {
					if (_v && _v.hasOwnProperty('result')) {
						handleSaveData(_v.result);
					}
					setShowModal(false);
				}
			}
        />
		<IonHeader>
			<IonToolbar mode="ios">
				<IonButtons slot="start">
					<IonBackButton mode="ios" text="Volver" defaultHref="/tabs" />
				</IonButtons>
				<IonTitle mode="ios">Mis líneas</IonTitle>
				<IonButtons slot="end">
					{!hideAddMore ? <IonButton onClick={() => { setShowModal(true) } }>
						<IonIcon icon={createOutline} />
					</IonButton> : null}
				</IonButtons>
			</IonToolbar>
		</IonHeader>
		<IonContent className="ion-padding">
			{_renderLineas()}
		</IonContent>
		{!hideAddMore ?
		<IonFooter>
			<IonToolbar mode="ios">
				<IonButton
				  className="specialButtonAdd"
				  onClick={() => {
						setShowModal(true)
				  }}
				>
					Agregar Línea <IonIcon slot="end" icon={addCircleOutline} />
				  <IonRippleEffect type="unbounded"></IonRippleEffect>
				</IonButton>
			</IonToolbar>
		</IonFooter> : null}
    </IonPage>
	) : null;
};

export default Lineas;
