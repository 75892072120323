import React, { useState } from "react";
import {
  IonButton,
  IonLoading,
  IonPage,
  IonText,
  IonContent,
  IonToast
} from "@ionic/react";

import { MobXProviderContext, observer } from "mobx-react";
import { useHistory } from "react-router";

import Dptos from './../dptos';

import './../styles/login.css';

const RegistrationPage = () => {
  const { store } = React.useContext(MobXProviderContext);
  const history = useHistory();
  const [errorInfo, setErrorInfo] = useState({});
  const [email, setEmail] = useState({});
  const [password, setPassword] = useState({});
  const [firstName, setFirstName] = useState({});
  const [lastName, setLastName] = useState({});
  const [referido, setReferido] = useState({});
  const [celular, setCelular] = useState('');

  const [nombredelatienda, setNombredelatienda] = useState('');

  const [validando, setValidando] = useState(false);

  const string_to_slug = (str) => {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();
    
      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to   = "aaaaeeeeiiiioooouuuunc------";
      for (var i=0, l=from.length ; i<l ; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes

      return str;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValidando(true);
    
    //Validar tienda
    let slug = string_to_slug(nombredelatienda);
    const existingStore = await store.checkIfStoreExists(slug);
    console.log(slug, existingStore)
    if (existingStore !== null && Object.keys(existingStore).length) {
        setErrorInfo({ showErrorToast: true, errMsg: 'La tienda especificada ya se encuentra en uso'});
    } else {
      try {
        let r = await store.doCreateUser({
          email,
          password,
          firstname: firstName,
          lastname: lastName,
          celular,
          activa: true,
          id_plan: 'S0ADUUANXLsxAt7QnjA8',
          moneda: 'cop',
          nombredelatienda,
          slug,
          referido
        });
  
        if (!r.success) {
          throw r;
        } else {
          var datoscliente = r.datos_cliente;

        try {
            let r = await store.doLogin(datoscliente.email, datoscliente.password);
            if (r.code) {
              throw r;
            }
          setErrorInfo({});
          setValidando(false);
          window.location.href = '/tabs/home?from=registration';
            // return history.push("/tabs/home");
        } catch (e) {
            setErrorInfo({ showErrorToast: true, errMsg: 'El usuario o contraseña ingresados son inválidos' });
            return false;
        }

          // history.replace("/tabs/home");
        }
      } catch (e) {
        console.log(e);
        setErrorInfo({ showErrorToast: true, errMsg: e.mensaje });
      }
    }

    setValidando(false);
  }

  return (
    <IonPage>
      <IonContent>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={validando}
        onDidDismiss={() => setValidando(false)}
        message={'Validando información...'}
      />
      <IonToast
          color="danger"
          isOpen={errorInfo.showErrorToast}
          onDidDismiss={() => setErrorInfo({ showErrorToast: false })}
          message={errorInfo.errMsg}
          duration={5000}
        />

		<div className="nuevoContenedor">
			<div className="contenidoF Ancho">
				<div className="cuadrosLogin">
					<IonText className="tituloLogin" style={{marginBottom:35}}>
						Registrarse
						<small>¡Regístraste y transforma tus conversaciones en ventas!</small>
					</IonText>

          <form className="formularioRegistro" method="POST" onSubmit={(e) => handleSubmit(e)}>
            <div className="formGroup">
              <label htmlFor="correo">Correo electrónico</label>
              <input placeholder="Ej: correo@correo.com" required type="email" name="correo" id="correo" onInput={(e) => { setEmail(e.target.value) }} />
            </div>

            <div className="formGroup">
              <label htmlFor="password">Contraseña</label>
              <input placeholder="Ej: ClaveSegura*" required type="password" name="password" id="password" onInput={(e) => { setPassword(e.target.value) }} />
            </div>
            
            <div className="formGroupTwo">
              <div className="formGroup">
                <label htmlFor="nombre">Nombre</label>
                <input placeholder="Ej: Mario" type="text" required name="nombre" id="nombre" onInput={(e) => { setFirstName(e.target.value) }} />
              </div>

              <div className="formGroup">
                <label htmlFor="apellido">Apellido</label>
                <input placeholder="Ej: Contreras" type="text" required name="apellido" id="apellido" onInput={(e) => { setLastName(e.target.value) }} />
              </div>
            </div>
            

            {/* <div className="formGroup">
              <label htmlFor="doc">Nro. de documento</label>
              <div className="formSubGroup">
                <select required onInput={(e) => { setTipodoc(e.target.value) }}>
                  <option value="" selected disabled>--Tipo documento--</option>
                  <option value="CC">Cédula de Ciudadanía</option>
                  <option value="CE">Cédula de extranjería</option>
                  <option value="Pasaporte">Pasaporte</option>
                </select>
                <input placeholder="Ej: 1222022" required type="number" name="doc" id="doc" onInput={(e) => { setNrodocumento(e.target.value) }} />
              </div>
            </div> */}
            {/* <div className="formGroupTwo">
              <div className="formGroup">
                <label htmlFor="departamento">Departamento</label>
                <select id="departamento" name="departamento" required onInput={(e) => { handleChangeCities(e.target.value); setDepartamento(e.target.value) }}>
                  <option value="" selected disabled>--Elegir departamento--</option>
                  {
                    Dptos.map((value, key) => {
                      return (
                        <option key={'departamento_' + key} value={value.departamento}>{value.departamento}</option>
                      );
                    })
                  }
                </select>
              </div>

              <div className="formGroup">
                <label htmlFor="ciudad">Ciudad</label>
                <select id="ciudad" name="ciudad" required onInput={(e) => { setCiudad(e.target.value) }}>
                <option value="" selected disabled>--Elegir ciudad--</option>
                  {
                    ciudades.map((value, key) => {
                      return (
                        <option key={'ciudad_' + key} value={value}>{value}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div> */}

            <div className="formGroup">
              <label htmlFor="celular">Celular</label>
              <input placeholder="Ej: 3003003030" type="text" required name="celular" id="celular" onInput={(e) => { 
                setCelular(e.target.value) 
              }} />
            </div>
            <div className="formGroup">
              <label htmlFor="tienda">Nombre de la tienda</label>
              <input placeholder="Ej: Tu moda latina" type="text" required name="tienda" id="tienda" onInput={(e) => { 
                setNombredelatienda(e.target.value) 
              }} />
            </div>
            <div className="formGroup">
              <label htmlFor="tienda">Código de referido (Opcional)</label>
              <input placeholder="Inscribe el código de tu referido" type="text" name="referido" id="referido" onInput={(e) => { 
                setReferido(e.target.value) 
              }} />
            </div>
            <button type="submit" disabled={firstName === '' || lastName === '' || email === '' || password === '' || nombredelatienda === ''} className="botonSubmit">Crear cuenta en micatalogovirtual</button>
          </form>


						<div className="linksBajos">
						<IonButton
								className="especialLink"
                expand="full"
                style={{textAlign: 'center'}}
								fill="clear"
								size="small"
								mode="md"
								onClick={(e) => {
									e.preventDefault();
									history.push("/login");
								}}
							>
								¿Ya tienes cuenta? Inicia sesión
							</IonButton>
						</div>
        </div>
				<div className="cuadrosLogin dos">
				</div>
			</div>
		</div>
      </IonContent>
    </IonPage>
  );
};

export default observer(RegistrationPage);
