import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
  IonItem,
  IonContent,
  IonPage,
  IonLabel,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonBackButton,
  IonLoading,
  IonAlert,
  IonInput,
  IonText,
  IonCheckbox,
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  IonRippleEffect,
  IonTextarea,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
// MOBX
import { MobXProviderContext } from "mobx-react";
import { linkOutline, addCircleOutline, logoWhatsapp, logOutOutline, arrowRedo, eyeOff, eye } from 'ionicons/icons';
import './../styles/lineas.css';

const Configuracion = () => {
	const history = useHistory();
	
	const { store } = React.useContext(MobXProviderContext);
	const [showLoading, setShowLoading] = useState(false);
	const [url, setUrl] = useState('');
	const [errorUrl, setErrorUrl] = useState('');
	
	const [vis, setVis] = useState([]);
	
	const [mostrarEnvio, setMostrarEnvio] = useState(false);
	const [mostrarEntrega, setMostrarEntrega] = useState(false);
	const [envioText, setEnvioText] = useState('');
	const [entregaText, setEntregaText] = useState('');
	const [politicasText, setPoliticasText] = useState('');
	const [moneda, setMoneda] = useState('');
	const [botondeorden, setBotondeorden] = useState('Comprar por WhatsApp');
	const [google, setGoogle] = useState('');
	const [facebook, setFacebook] = useState('');

	const [newPassword1, setNewPassword1] = useState('');
	const [newPassword2, setNewPassword2] = useState('');
	const [showAlert1, setShowAlert1] = useState(false);

	const [showPassword1, setShowPassword1] = useState(false);
	const [showPassword2, setShowPassword2] = useState(false);
	
	
	const [classAbierta, setClassAbierta] = useState('null');
	
	const [fields, setFields] = useState([
		{val: 'titulo', title: 'Titulo', check: false},
		{val: 'precio', title: 'Precio', check: false},
		{val: 'preciodescuento', title: 'Precio con descuento', check: false},
		{val: 'descripcion', title: 'Descripción', check: false},
		{val: 'addtocart', title: 'Botón "agregar"', check: false}
	]);
	
	useEffect(() => {
		// console.log('Hook fired');
		let user = store.activeUser;
		
		if (store.tienda.hasOwnProperty('tienda')) {
			setUrl(store.tienda.tienda);
		}
		if (user && user.hasOwnProperty('visibility')) {
			user.visibility.map((v, k) => {
				let realFields = [...fields];
				realFields.map((vv, kk) => {
					if (vv.val === v) {
						// console.log('true', vv.val, v)
						realFields[kk].check = true;
					}
					return true;
				})
				setFields(realFields);
				return true;
			})
			setVis([...user.visibility]);
		}
		if (user && user.hasOwnProperty('mostrarEntrega')) {
			setMostrarEntrega(user.mostrarEntrega);
		}
		if (user && user.hasOwnProperty('mostrarEnvio')) {
			setMostrarEnvio(user.mostrarEnvio);
		}
		if (user && user.hasOwnProperty('entregaText')) {
			setEntregaText(user.entregaText);
		}
		if (user && user.hasOwnProperty('envioText')) {
			setEnvioText(user.envioText);
		}
		if (user && user.hasOwnProperty('politicasText')) {
			setPoliticasText(user.politicasText);
		}
		if (user && user.hasOwnProperty('moneda')) {
			setMoneda(user.moneda);
		}
		if (user && user.hasOwnProperty('botondeorden')) {
			setBotondeorden(user.botondeorden);
		}
		if (user && user.hasOwnProperty('google')) {
			setGoogle(user.google);
		}
		if (user && user.hasOwnProperty('facebook')) {
			setFacebook(user.facebook);
		}
		
		
	}, [store.tienda]); // eslint-disable-line react-hooks/exhaustive-deps
	
	const handleChangeVis = async (index, value) => {
		const Vis = [...vis];
		if (Vis.includes(value)) {
			var indexOf = Vis.indexOf(value);
			Vis.splice(indexOf, 1);
		} else {
			Vis.push(value);
		}
		await setVis(Vis);
		
		let realFields = [...fields];
		realFields.map((vv, kk) => {
			if (Vis.includes(vv.val)) {
				realFields[kk].check = true;
			} else {
				realFields[kk].check = false;
			}
			return true;
		})
		setFields(realFields);
		// console.log(Vis);
	}
  
	const _renderOptions = () => {
		
		// console.log(vis);
		return fields.map((value, index) => {
			return (
				<IonItem key={index}>
				  <IonLabel>{value.title}</IonLabel>
				  <IonCheckbox onClick={(e) => { handleChangeVis(index, value.val) }} mode="ios" slot="end" value={value.val} checked={value.check} />
				</IonItem>
			)
		});
	}
	
	const handleSubmit = async () => {
		setShowLoading(true);
		let errores = false;
		if (url === '') {
			setErrorUrl('Debe especificar una URL para que su tienda sea visible');
			errores = true;
		}
		
		if (!errores) {
			const finalUrl = url.trim();
			if ((!store.tienda.hasOwnProperty('tienda')) || (store.tienda.hasOwnProperty('tienda') && finalUrl !== store.tienda.tienda)) {
				const existingStore = await store.checkIfStoreExists(finalUrl);
				if (existingStore === null || !Object.keys(existingStore).length) {
					let result = await store.saveNewStore(finalUrl);
					console.log(result);
				} else {
					setErrorUrl('La url especificada ya se encuentra en uso');
				}
			}
			// console.log(vis);
			var dataToSend = {
				visibility: vis,
				politicasText: politicasText,
				mostrarEntrega: mostrarEntrega,
				mostrarEnvio: mostrarEnvio,
				envioText: envioText,
				entregaText: entregaText,
				moneda: moneda,
				botondeorden: botondeorden,
				google: google,
				facebook: facebook
			};
			// console.log(dataToSend);
			await store.saveInClientProfile(dataToSend);
			if (newPassword1 !== '') {
				await store.changePassword(newPassword1);
				setNewPassword1('');
				setNewPassword2('');
				setShowAlert1(true);
			}
		}
		setShowLoading(false);
	}
	
	return store.activeUser ? (
    <IonPage>
		<style>{`
			.${classAbierta} .contenidoAccordion {
				max-height: 1000px;
				transition: all 1s;
				padding-bottom:20px;
			}
		`}</style>
		
	    <IonLoading
			cssClass='homepageloader'
			isOpen={showLoading}
			message="Actualizando información..."
		/>
		<IonAlert
		  mode="ios"
          isOpen={showAlert1}
          onDidDismiss={() => setShowAlert1(false)}
          header={'Aviso'}
          message={'Se ha cambiado correctamente su contraseña'}
          buttons={['OK']}
        />
		<IonAlert
          isOpen={false}
          onDidDismiss={() => console.log('aca')}
          header={'¿Está seguro?'}
          message={'Confirma que deseas eliminar esta línea'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
			  
            },
            {
              text: 'Eliminar',
              handler: () => {
                
              }
            }
          ]}
        />
		<IonHeader>
			<IonToolbar mode="ios">
				<IonButtons slot="start">
					<IonBackButton mode="ios" text="Volver" defaultHref="/tabs" />
				</IonButtons>
				<IonTitle mode="ios">Mi catálogo</IonTitle>
				<IonButtons slot="end">
					<IonButton onClick={() => { store.doLogout(); history.push('/'); }}>
						<IonIcon style={{marginLeft: '5px'}} size="small" slot="end" icon={logOutOutline} />
						<span>Salir</span>
					</IonButton>
				</IonButtons>
			</IonToolbar>
		</IonHeader>
		<IonContent className="ion-padding">
			<IonItem mode="ios" className="itemPadding">
				<IonLabel mode="ios" position="floating">URL del Catálogo</IonLabel>
				{(url !== '') ? (<a className="externalLink" href={'https://'+ (url ? url + '.' : '') +'micatalogovirtual.com/'} target="_blank" rel="noopener noreferrer"><IonIcon icon={arrowRedo} /></a>) : ''}
				<IonInput
					mode="ios"
					type="text"
					onIonInput={(e) => { setUrl(e.target.value.toLowerCase().replace(/\s/g,'')); }}
					name="url"
					required={true}
					value={url}
				/>
				<IonIcon icon={linkOutline} slot="start" className="ion-align-self-end" />
			</IonItem>
			<IonText className="textExample">
				<small>No debe contener espacios en blanco, ni caracteres especiales.</small>
			</IonText>
			<IonText color="danger" className="ion-padding-start">
				<small>{errorUrl}</small>
			</IonText>
			
		  	<IonItem style={{marginBottom: 35}} mode="ios" className="itemPadding">
		  		<IonLabel mode="ios" position="floating">API KEY</IonLabel>
				<IonInput onIonFocus={(elem) => { elem.target.getInputElement().then((html) => { html.select() }) }} value={window.btoa(store.activeUser.id)} mode="ios" type="text" name="api_key" readonly={true} />
			</IonItem>

			<div className="accordion visible">
				<h6 className="mt-5 text-center" onClick={() => { setClassAbierta('visible') }} >Información publicada del producto</h6>
				<div className="contenidoAccordion">
					{_renderOptions()}
				</div>
			</div>
			
			<div className="accordion moneda">
				<h6 className="mt-5 text-center" onClick={() => { setClassAbierta('moneda') }} >Moneda</h6>
				<div className="contenidoAccordion">
					<IonItem className="itemPadding" mode="ios">
						<IonLabel mode="ios" position="floating">Moneda utilizada en tu tienda</IonLabel>
						<IonSelect value={moneda} okText={'Aceptar'} cancelText={'Cancelar'} onIonChange={e => setMoneda(e.detail.value)}>
							<IonSelectOption value="cop">Pesos Colombianos - COP</IonSelectOption>
							<IonSelectOption value="usd">Dólar Estadounidense - USD</IonSelectOption>
						</IonSelect>
					</IonItem>
				</div>
			</div>
			
			<div className="accordion botondeorden">
				<h6 className="mt-5 text-center" onClick={() => { setClassAbierta('botondeorden') }} >Configurar botón de orden</h6>
				<div className="contenidoAccordion">
					<IonItem className="itemPadding" mode="ios">
						<IonLabel mode="ios" position="floating">Seleccionar botón</IonLabel>
						<IonSelect value={botondeorden} okText={'Ok'} cancelText={'Cancelar'} onIonChange={e => setBotondeorden(e.detail.value)}>
							<IonSelectOption value="Ordenar por WhatsApp">Ordenar por WhatsApp</IonSelectOption>
							<IonSelectOption value="Comprar por WhatsApp">Comprar por WhatsApp</IonSelectOption>
							<IonSelectOption value="Cotizar por WhatsApp">Cotizar por WhatsApp</IonSelectOption>
						</IonSelect>
					</IonItem>
					<div style={{padding:'15px'}}>
						<small>Vista previa:</small>
						<div className="botonFakeWhatsapp">
							<IonIcon icon={logoWhatsapp} />
							{botondeorden}
						</div>
					</div>
				</div>
			</div>
			
			<div className="accordion camposInfo envio">
				<h6 className="mt-5 text-center" onClick={() => { setClassAbierta('envio') }}>Información de envíos</h6>
				<div className="contenidoAccordion">
					<div className="infoEnvios">
						<IonItem className="itemPadding" mode="ios">
						  <IonLabel mode="ios">Mostrar costos de envío</IonLabel>
						  <IonCheckbox checked={mostrarEnvio} onIonChange={(e) => { setMostrarEnvio(e.detail.checked) }} mode="ios" slot="start" />
						</IonItem>
						<IonItem className="itemPadding" mode="ios">
						  <IonLabel mode="ios" position="floating">Costo de envío</IonLabel>
						  <IonInput value={envioText} onIonInput={(e) => { setEnvioText(e.target.value) }} mode="ios" type="text" />
						</IonItem>
						<IonText className="textExample">
							<small>Ej: Gratis, +Costos de Envío, A convenir o un valor $ 10.000</small>
						</IonText>
					</div>
				</div>
			</div>
			
			<div className="camposInfo entrega accordion">
				<h6 className="mt-5 text-center" onClick={() => { setClassAbierta('entrega') }}>Información de entregas</h6>
				<div className="contenidoAccordion">
					<div className="infoEnvios">
						<IonItem className="itemPadding" mode="ios">
						  <IonLabel mode="ios">Mostrar tiempos de entrega</IonLabel>
						  <IonCheckbox checked={mostrarEntrega} onIonChange={(e) => { setMostrarEntrega(e.detail.checked) }} mode="ios" slot="start" />
						</IonItem>
						<IonItem className="itemPadding" mode="ios">
						  <IonLabel mode="ios" position="floating">Tiempos de entrega</IonLabel>
						  <IonInput value={entregaText} onIonInput={(e) => { setEntregaText(e.target.value) }} mode="ios" type="text" />
						</IonItem>
						<IonText className="textExample">
							<small>Ej: De 2 a 5 días hábiles</small>
						</IonText>
					</div>
				</div>
			</div>
			
			<div className="camposInfo analytics accordion">
				<h6 className="mt-5 text-center" onClick={() => { setClassAbierta('analytics') }}>Google Analytics</h6>
				<div className="contenidoAccordion">
					<div className="infoEnvios">
						<IonItem className="itemPadding" mode="ios">
						  <IonLabel mode="ios" position="floating">ID de seguimiento de Google Analytics</IonLabel>
						  <IonInput value={google} onIonInput={(e) => { setGoogle(e.target.value) }} mode="ios" type="text" />
						</IonItem>
						<IonText className="textExample">
							<small>Google Analytics te permite rastrear los visitantes de tu tienda y generar reportes que te ayudarán con el Marketing. <a href="https://support.google.com/analytics/answer/1008080?hl=es#GAID" rel="noopener noreferrer" target="_blank">Aprende cómo localizar la etiqueta de seguimiento</a>.</small>
						</IonText>
					</div>
				</div>
			</div>
			
			<div className="camposInfo pixel accordion">
				<h6 className="mt-5 text-center" onClick={() => { setClassAbierta('pixel') }}>Pixel de Facebook</h6>
				<div className="contenidoAccordion">
					<div className="infoEnvios">
						<IonItem className="itemPadding" mode="ios">
						  <IonLabel mode="ios" position="floating">ID del píxel de Facebook</IonLabel>
						  <IonInput value={facebook} onIonInput={(e) => { setFacebook(e.target.value) }} mode="ios" type="text" />
						</IonItem>
						<IonText className="textExample">
							<small>El píxel de Facebook te ayuda a crear campañas de anuncios para encontrar nuevos clientes que se parezcan más a sus compradores. <a href="https://www.facebook.com/business/help/952192354843755?id=1205376682832142" rel="noopener noreferrer" target="_blank">Aprende cómo localizar el ID del píxel de Facebook</a>.</small>
						</IonText>
					</div>
				</div>
			</div>
			
			<div className="camposInfo accordion politicas">
				<h6 className="mt-5 text-center" onClick={() => { setClassAbierta('politicas') }}>Políticas de Privacidad</h6>
				<div className="contenidoAccordion">
					<div className="infoEnvios">
						<IonItem className="itemPadding" mode="ios">
						  <IonLabel mode="ios" position="floating">Especifica tus políticas</IonLabel>
						  <IonTextarea value={politicasText} onIonChange={(e) => { setPoliticasText(e.detail.value); } } mode="ios" type="text" />
						</IonItem>
						<IonText className="textExample" style={{marginBottom: '15px'}}>
							<small>Una vez guardados tus cambios, tus clientes verán un enlace en la página “Cotizar por WhatsApp” de tu tienda en donde podrán visualizar las políticas de privacidad de tu comercio.</small>
						</IonText>
					</div>
				</div>
			</div>

			<div className="camposInfo accordion cambiarpassword">
				<h6 className="mt-5 text-center" onClick={() => { setClassAbierta('cambiarpassword') }}>Cambiar contraseña</h6>
				<div className="contenidoAccordion">
					<div className="infoEnvios">
						<IonItem className="itemPadding" mode="ios">
						  <IonLabel mode="ios" position="floating">Especifica tu nueva contraseña</IonLabel>
						  <IonInput type={showPassword1 ? 'text' : 'password'} value={newPassword1} onIonInput={(e) => { setNewPassword1(e.target.value) }} mode="ios"></IonInput>
						  <IonButton onClick={() => { setShowPassword1(!showPassword1) }} size="default" fill="clear" slot="end"  style={{marginTop: 30}}><IonIcon icon={showPassword1 ? eyeOff : eye}></IonIcon></IonButton>
						</IonItem>
						<IonItem className="itemPadding" mode="ios">
						  <IonLabel mode="ios" position="floating">Repite tu nueva contraseña</IonLabel>
						  <IonInput type={showPassword2 ? 'text' : 'password'} value={newPassword2} onIonInput={(e) => { setNewPassword2(e.target.value) }} mode="ios"></IonInput>
						  <IonButton onClick={() => { setShowPassword2(!showPassword2) }} size="default" fill="clear" slot="end"  style={{marginTop: 30}}><IonIcon icon={showPassword2 ? eyeOff : eye}></IonIcon></IonButton>
						</IonItem>
						<IonText className="textExample" style={{marginBottom: '15px', color: 'red'}}>
							{newPassword2 === newPassword1 ? '' : 'Ambas contraseñas deben coincidir'}
						</IonText>
					</div>
				</div>
			</div>
		</IonContent>
		<IonFooter>
			<IonToolbar>
			  <IonGrid>
				<IonRow>
				  <IonCol>
					<IonButton
					  className="specialButtonAdd"
					  onClick={() => {
							handleSubmit()
					  }}
					>
					Actualizar información <IonIcon slot="end" icon={addCircleOutline} />
					  <IonRippleEffect type="unbounded"></IonRippleEffect>
					</IonButton>
				  </IonCol>
				</IonRow>
			  </IonGrid>
			</IonToolbar>
		</IonFooter>
    </IonPage>
	) : null;
};

export default Configuracion;
