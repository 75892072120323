import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  IonContent,
  IonPage,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonBackButton
} from "@ionic/react";
// MOBX
import { MobXProviderContext } from "mobx-react";
import { eyeOutline, receiptOutline } from 'ionicons/icons';
import './../styles/lineas.css';
import PedidoModal from "./PedidoModal";

const Pedidos = () => {
	const history = useHistory();
	
	const { store } = React.useContext(MobXProviderContext);
	const [showModal, setShowModal] = useState(false);
	const [edititem, setEdititem] = useState(null);
	
	let user = store.activeUser;
	
	const _renderPedidos = () => {
		return store.itemPedidos.map(([key, value]) => {
		var today = new Date(value.created);
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0');
		var yyyy = today.getFullYear();
		return (
			<tr key={key} onClick={() => { editOne(value, key) }}>
				<td><p className="ellipsis">{value.id}</p></td>
				<td className="nombreTD">{value.nombre}</td>
				<td>{dd + '/' + mm + '/' + yyyy}</td>
				<td><IonButton className="redondeadoVer"><IonIcon icon={eyeOutline}/></IonButton></td>
			</tr>
		);
		});
	}
	
	const editOne = (item, id) => {
		setEdititem({item, id});
		setShowModal(true);
	}
  
	return user ? (
    <IonPage>
		<PedidoModal
			showModal={showModal}
			toEdit={edititem}
			onDidDismiss={() => {
					setShowModal(false);
				}
			}
        />
		<IonHeader>
			<IonToolbar mode="ios">
				<IonButtons slot="start">
					<IonBackButton mode="ios" text="Volver" defaultHref="/tabs" />
				</IonButtons>
				<IonTitle mode="ios">Mis pedidos</IonTitle>
				<IonButtons slot="end">
					<IonButton onClick={() => { history.push('/tabs/notas'); }}>
						<IonIcon style={{marginRight: '5px'}} size="small" icon={receiptOutline} />
						<span>Mis notas</span>
					</IonButton>
				</IonButtons>
			</IonToolbar>
		</IonHeader>
		<IonContent className="ion-padding">
			<div className="table-responsive">
				<table>
					<thead>
						<tr>
							<th># Pedido</th>
							<th>Cliente</th>
							<th>Fecha</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{_renderPedidos()}
					</tbody>
				</table>
			</div>
		</IonContent>
    </IonPage>
	) : null;
};

export default Pedidos;
