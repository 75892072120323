import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonText
} from "@ionic/react";
import './../styles/lineas.css';

const Facturacion = () => {
	const [urlParams, setUrlParams] = useState({});
	
	useEffect(() => {
		const parametros = parseParams(window.location.search);
		console.log(parametros);
		setUrlParams(parametros);
		// const getReference = parametros.referenceCode;
		// const id_cliente = getReference.split('-')[1];
		// const id_plan = getReference.split('-')[0];
		if (parametros.hasOwnProperty('lapTransactionState')) {
			crearFacturaPayu(parametros);
		}
	}, []);
	
	const crearFacturaPayu = async (parametros) => {
		// alert('Test')
		var url = 'https://us-central1-micatalogovirtual-app.cloudfunctions.net/registrarpagopayu';
		await fetch(url + window.location.search + '&dd=' + Date.now());
		window.history.pushState({}, document.title, '/');
	}
	
	const parseParams = (querystring) => {
		// parse query string
		const params = new URLSearchParams(querystring);

		const obj = {};

		// iterate over all keys
		for (const key of params.keys()) {
			if (params.getAll(key).length > 1) {
				obj[key] = params.getAll(key);
			} else {
				obj[key] = params.get(key);
			}
		}

		return obj;
	};
	
	
	return (
    <IonPage>		
		<IonContent className="ion-padding">
			<IonText style={{textAlign: 'center'}} color="success">
				<h1>¡Hemos registrado tu pago!</h1>
			</IonText>
			<div className="infoPago">
				<h4>Detalles del pedido</h4>
				<div className="detallesPago">
					<div className="labelDetalle">
						Descripción:
					</div>
					<div className="textoDetalle">
						{urlParams.description}
					</div>
				</div>
				<div className="detallesPago">
					<div className="labelDetalle">
						Total pagado:
					</div>
					<div className="textoDetalle">
						$ {urlParams.TX_VALUE} {urlParams.currency}
					</div>
				</div>
				<div className="detallesPago">
					<div className="labelDetalle">
						Fecha de pago:
					</div>
					<div className="textoDetalle">
						{urlParams.processingDate}
					</div>
				</div>
				<div className="detallesPago">
					<div className="labelDetalle">
						Estado:
					</div>
					<div className="textoDetalle">
						{urlParams.lapTransactionState}
					</div>
				</div>
				<div className="detallesPago">
					<div className="labelDetalle">
						Método de pago:
					</div>
					<div className="textoDetalle">
						{urlParams.lapPaymentMethod}
					</div>
				</div>
				<div className="detallesPago">
					<div className="labelDetalle">
						Tipo de pago:
					</div>
					<div className="textoDetalle">
						{urlParams.lapPaymentMethodType}
					</div>
				</div>
			</div>
			<a className="volveralaTienda" href={window.location.origin}>Volver a la tienda</a>
		</IonContent>
    </IonPage>
	);
};

export default Facturacion;
