import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonLoading,
  IonPage,
  IonText,
  IonContent,
  IonToast
} from "@ionic/react";

import { MobXProviderContext, observer } from "mobx-react";
import { useHistory } from "react-router";

import './../styles/login.css';

const RecoverPasswordPage = () => {
  const { store } = React.useContext(MobXProviderContext);

  const history = useHistory();
  const [errorInfo, setErrorInfo] = useState({});
  const [email, setEmail] = useState({});
  const [enviado, setEnviado] = useState(false);

  const [validando, setValidando] = useState(false);

  useEffect(() => {
    setEmail({});
    setEnviado(false);
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValidando(true);
    let data = await store.doRecover(email);
    if (data && data) {
      setErrorInfo({showErrorToast: true, errMsg: 'Este correo no se encuentra registrado'})
    } else {
      setErrorInfo({showErrorToast: true, errMsg: 'Hemos enviado un correo con instrucciones al ' + email});
      setEnviado(true);
    }
    setValidando(false);
  }

  return (
    <IonPage>
      <IonContent>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={validando}
        onDidDismiss={() => setValidando(false)}
        message={'Enviando información...'}
      />
      <IonToast
          isOpen={errorInfo.showErrorToast}
          onDidDismiss={() => setErrorInfo({ showErrorToast: false })}
          message={errorInfo.errMsg}
          duration={5000}
        />

		<div className="nuevoContenedor">
			<div className="contenidoF Ancho">
				<div className="cuadrosLogin">
					<IonText className="tituloLogin" style={{marginBottom:35}}>
						Recuperar contraseña
						<small>Ingresa el correo con el cual registraste tu cuenta y enviaremos una clave temporal para que puedas acceder</small>
					</IonText>

          <form className="formularioRegistro" method="POST" onSubmit={(e) => handleSubmit(e)}>
            <div className="formGroup">
              <label htmlFor="correo">Correo electrónico</label>
              <input disabled={enviado} placeholder="Ej: correo@correo.com" required type="email" name="correo" id="correo" onInput={(e) => { setEmail(e.target.value) }} />
            </div>

            <button type="submit" disabled={email === '' || enviado} className="botonSubmit">Enviar</button>
          </form>


						<div className="linksBajos">
						<IonButton
								className="especialLink"
                expand="full"
                style={{textAlign: 'center'}}
								fill="clear"
								size="small"
								mode="md"
								onClick={(e) => {
									e.preventDefault();
									history.push("/login");
								}}
							>
								¿Ya tienes acceso? Inicia sesión
							</IonButton>
						</div>
        </div>
				<div className="cuadrosLogin dos">
				</div>
			</div>
		</div>
      </IonContent>
    </IonPage>
  );
};

export default observer(RecoverPasswordPage);
