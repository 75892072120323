import React, { useState } from "react";
import {
  IonItem,
  IonContent,
  IonPage,
  IonLabel,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonBackButton,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonLoading,
  IonAlert
} from "@ionic/react";
// MOBX
import { MobXProviderContext } from "mobx-react";
import { createOutline, trashOutline } from 'ionicons/icons';
import './../styles/lineas.css';
import NotasModal from "./NotasModal";

const Notas = () => {
	const { store } = React.useContext(MobXProviderContext);
	const [showModal, setShowModal] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [edititem, setEdititem] = useState(null);
	const [deleteitem, setDeleteitem] = useState(null);
	const [showAlert, setShowAlert] = useState(false);
	
	let user = store.activeUser;
  
	const _renderNotas = () => {
		return store.itemNotas.map(([key, value]) => {
		var today = new Date(value.created);
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		return (
			<IonItemSliding layout="icon-only" className="sliderTelefono" key={key}>
				<IonItem onClick={ () => { editOne(value, key) } }>
					<IonLabel>
						<h2>{value.name}</h2>
						<h3>{dd + '/' + mm + '/' + yyyy}</h3>
						<p>{value.message}</p>
					</IonLabel>
				</IonItem>
				<IonItemOptions side="end">
					<IonItemOption className="botonLinea" onClick={() => { editOne(value, key) }}><IonIcon size="large" icon={createOutline} color="warning" /></IonItemOption>
					<IonItemOption className="botonLinea" onClick={() => { deleteOne(key) }}><IonIcon size="large" icon={trashOutline} color="danger" /></IonItemOption>
				</IonItemOptions>
			</IonItemSliding>
		);
		});
	}
	
	const editOne = (item, id) => {
		console.log(item, id);
		setEdititem({item, id});
		setShowModal(true);
	}
	
	const deleteOne = (id) => {
		setShowAlert(true);
		setDeleteitem(id);
	}
	
	const handleDeleteOne = async () => {
		setShowAlert(false);
		setShowLoading(true);
		await store.deleteNota(deleteitem);
		
		setShowLoading(false);
	}
	
	const handleSaveData = async (data) => {
		setShowLoading(true);
		
		let toSend = data;
		if (!toSend.hasOwnProperty('id')) {
			await store.addNota({ ...toSend });
		} else {
			let idf = toSend.id;
			delete toSend['id'];
			await store.updateNota(idf, { ...toSend });
		}
			
		setShowLoading(false);
	}
  
	return user ? (
    <IonPage>
	    <IonLoading
			cssClass='homepageloader'
			isOpen={showLoading}
			message="Actualizando información..."
		/>
		
		<IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'¿Está seguro?'}
          message={'Confirma que deseas eliminar esta nota'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
			  
            },
            {
              text: 'Eliminar',
              handler: () => {
                handleDeleteOne()
              }
            }
          ]}
        />
		
		<NotasModal
			showModal={showModal}
			toEdit={edititem}
			onDidDismiss={(_v) => {
					if (_v && _v.hasOwnProperty('result')) {
						handleSaveData(_v.result);
					}
					setShowModal(false);
				}
			}
        />
		<IonHeader>
			<IonToolbar mode="ios">
				<IonButtons slot="start">
					<IonBackButton mode="ios" text="Volver" defaultHref="/tabs" />
				</IonButtons>
				<IonTitle mode="ios">Mis notas</IonTitle>
				<IonButtons slot="end">
					<IonButton onClick={() => { setShowModal(true) } }>
						<IonIcon icon={createOutline} />
					</IonButton>
				</IonButtons>
			</IonToolbar>
		</IonHeader>
		<IonContent className="ion-padding">
			{_renderNotas()}
		</IonContent>
    </IonPage>
	) : null;
};

export default Notas;
