import React, { useState, useEffect } from "react";
import {
  IonItem,
  IonContent,
  IonPage,
  IonLabel,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonAvatar,
  IonIcon,
  IonBackButton,
  IonLoading,
  IonAlert,
  IonBadge,
  IonCheckbox,
  IonFooter,
  IonTabButton,
  IonImg,
  IonInput,
  IonReorderGroup,
  IonReorder
} from "@ionic/react";
// MOBX
import { MobXProviderContext, observer } from "mobx-react";
import { createOutline, trashOutline, toggleOutline, toggle, searchOutline, funnelOutline, saveOutline } from 'ionicons/icons';
import './../styles/lineas.css';
// import NotasModal from "./NotasModal";
import RetrieveImage from './../retrieveImg';
import useDynamicRefs from 'use-dynamic-refs';
import AddProductModal from "./AddItemModal";
import Resizer from 'react-image-file-resizer';

import VirtualElements from './VirtualElements';

const Productos = () => {
	const { store } = React.useContext(MobXProviderContext);
	// const [lineas, setLineas] = useState({});
	const [showLoading, setShowLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	
	const [showProductModal, setShowProductModal] = useState(false);
	const [productosCheck, setProductosCheck] = useState([]);
	const [productToEdit, setProductToEdit] = useState(null);
	const [mensajeCargando, setMensajeCargando] = useState('Actualizando información...');
	const [totalproductos, setTotalproductos] = useState(0);

	const [productList, SetProductList] = useState([]);
	
	const [getRef, setRef] =  useDynamicRefs();
	
	const [buscar, setBuscar] = useState('');

	const [desactivarReorder, setDesactivarReorder] = useState(true);
	
	let user = store.activeUser;
	
	useEffect(() => {
		let products = [...store.itemProducts];
		SetProductList(products);
	}, [store.itemProducts]); // eslint-disable-line react-hooks/exhaustive-deps
  
	const _renderProductos = () => {
		let products = productList;
		if (buscar !== '') {
			let newProducts = products.filter((k, v) => {
				let tproduct = {...k[1]};
				for (let i in tproduct) {
					if (i === 'categories') {
						tproduct[i].categories = tproduct[i].join();
					}
				}
				var values = Object.values(tproduct);
				var texto = values.join().toLowerCase();
				return (texto.includes(buscar));
			});
			products = newProducts;
		}
		
		return products.map(([key, value]) => {
			// console.log(value);
			return (
				<VirtualElements height={92} key={key}>
					{!desactivarReorder ? (
			
						<IonItem id={key} >
							<IonAvatar slot="start">
							{(value.cover && typeof value.cover === 'string') ? (<IonImg src={RetrieveImage(value.cover)} alt="Cover" />) : ((value.cover && typeof value.cover === 'object' && value.cover.length) ? (<IonImg src={RetrieveImage(value.cover[0])} alt="Cover" />) : '')}
							</IonAvatar>
							<IonLabel>
								<h3><strong>Ref:</strong> {value.reference}</h3>
								<p><strong>Precio final:</strong> $ {new Intl.NumberFormat('es-ES').format(value.price)}</p>
								<p><strong>Estado:</strong> <IonBadge color={(value.status && value.status === 1) ? 'success' : 'danger'}>{value.status === 1 ? 'Activo' : 'Inactivo'}</IonBadge></p>
							</IonLabel>
							<IonReorder slot="end" />
						</IonItem>
					) : (
						<IonItem id={key} >
							<IonCheckbox
								slot="end"
								value={value.id}
								onIonChange={(e) => { handleChangeCheck(e.detail) }}
								ref={setRef(value.id)}
							/>
							<IonAvatar slot="start">
							{(value.cover && typeof value.cover === 'string') ? (<IonImg src={RetrieveImage(value.cover)} alt="Cover" />) : ((value.cover && typeof value.cover === 'object' && value.cover.length) ? (<IonImg src={RetrieveImage(value.cover[0])} alt="Cover" />) : '')}
							</IonAvatar>
							<IonLabel>
								<h3><strong>Ref:</strong> {value.reference}</h3>
								<p><strong>Precio final:</strong> $ {new Intl.NumberFormat('es-ES').format(value.price)}</p>
								<p><strong>Estado:</strong> <IonBadge color={(value.status && value.status === 1) ? 'success' : 'danger'}>{value.status === 1 ? 'Activo' : 'Inactivo'}</IonBadge></p>
							</IonLabel>
						</IonItem>
					)}
				</VirtualElements>
			);
		});
	}
	
	const resizeFile = (file, sizes) => new Promise(resolve => {
		Resizer.imageFileResizer(file, sizes.width, sizes.height, 'JPEG', 100, 0,
		uri => {
		  resolve(uri);
		},
		'blob', sizes.width, sizes.height
		);
	});
	
	const handleChangeCheck = (item) => {
		// console.log('aca')
		const currentChecked = [...productosCheck];
		if (item.checked) {
			currentChecked.push(item.value);
		} else {
			let index = currentChecked.indexOf(item.value);
			currentChecked.splice(index, 1);
		}
		setProductosCheck(currentChecked);
		// console.log(currentChecked);
	}
	
	const handleActivar = async () => {
		setShowLoading(true);
		const fresult = productosCheck.map( async (idf, key) => {
			const result = await store.updateProduct(idf, {status: 1});
			let item = getRef(idf);
			item.current.checked = false;
			return result;
		});
		await Promise.all(fresult);
		
		setProductosCheck([]);
		setShowLoading(false);
	}
	
	const handleDesactivar = async () => {
		setShowLoading(true);
		const fresult = productosCheck.map( async (idf, key) => {
			const result = await store.updateProduct(idf, {status: 0});
			let item = getRef(idf);
			item.current.checked = false;
			return result;
		});
		await Promise.all(fresult)
		
		setProductosCheck([]);
		setShowLoading(false);
	}
	
	const handleEliminarPrev = () => {
		setShowAlert(true);
	}
	
	const handleEliminar = async () => {
		setShowAlert(false);
		setShowLoading(true);
		const fresult = productosCheck.map( async (idf, key) => {
			const result = await store.deleteProduct(idf);
			
			return result;
		});
		await Promise.all(fresult)
		await setTotalproductos((store.activeUser.total_products - productosCheck.length));
		await store.saveInClientProfile({total_products: (store.activeUser.total_products - productosCheck.length)});
		
		setProductosCheck([]);
		setShowLoading(false);
	}
	
	const handleEditar = () => {
		const id = productosCheck[0];
		const product = store.productByKey(id);
		setProductToEdit({id: id, item: {...product}});
		setShowProductModal(true);
	}
	
	
	const handleProductSubmit = async (data) => {
		setProductToEdit(null);
		// console.log(data);
		setShowLoading(true);
		setMensajeCargando('Procesando datos...');
		
		let newTotalProducts = (parseInt(totalproductos) +1);
		let currentImage = data.productImage;
		delete data.productImage;
		
		let toSend = data;
		let result = {};
		if (toSend.hasOwnProperty('id')) {
			let idf = toSend.id;
			delete toSend['id'];
			result = await store.updateProduct(idf, { ...toSend});
		} else {
			toSend.status = 1;
			result = await store.addProduct({ ...toSend });
		}
		if (result.hasOwnProperty('id')) {
			if (currentImage !== null) {
				setMensajeCargando('Procesando imagen...');
				
				let urlImagenProduct = `products/${store.activeUser.id}/${result.id}/cover.jpg`;
				const finalFile = await resizeFile(currentImage, {width: 600, height: 600});
				console.log('STEP 0', finalFile)
				const r = await store.uploadFile(urlImagenProduct, finalFile);
				console.log('STEP 1', r)
				// console.log(result.id);
				await store.updateProduct(result.id, { ...{cover: urlImagenProduct} })
				console.log('STEP 3')
			}
		} else {
			setShowLoading(false);
		}
		setMensajeCargando('Contabilizando productos...');
		await store.saveInClientProfile({total_products: newTotalProducts});
		await setTotalproductos(newTotalProducts);
		setShowLoading(false);
	}
	
	const _handleBusqueda = (event) => {
		setBuscar(event.target.value);
	}

	const reorderProducts = async (event) => {
		// Before complete is called with the items they will remain in the
		// order before the drag
		const newList = await event.detail.complete(productList);
		// productList.forEach((objeto, index) => {
		// 	objeto[1].order = (index + 1);
		// })

		SetProductList(newList)
	}

	const handleSavePositions = async () => {
		try {
			setMensajeCargando('Guardando posiciones (Este proceso puede tomar varios minutos)');
			setShowLoading(true);
			const save = productList.map(async (objeto, index) => {
				var datos = {...objeto[1]};
				var position = (index + 1);
				var datosToReturn = {
					id: datos.id,
					order: position
				};
				return datosToReturn;
			});
			// console.log(store.activeUser.id);
			const result = await Promise.all(save);
			await fetch('https://recibirposiciones-splaq4s7fa-uc.a.run.app', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				cache: 'no-cache',
				body: JSON.stringify({id_client: store.activeUser.id, products: result})
			})
			store.loadProducts();

			setMensajeCargando('Actualizando información...');
			setShowLoading(false);
		} catch {
			setMensajeCargando('Actualizando información...');
			setShowLoading(false);
		}
		// var id = 'cxPSBFihCi4rpxucSonM';
		// const result = await store.updateProduct(id, {position: 0});
		// console.log(result)
	}
	
  
	return user ? (
    <IonPage>
	    <IonLoading
			cssClass='homepageloader'
			isOpen={showLoading}
			message={mensajeCargando}
		/>
		
		<AddProductModal
          showModal={showProductModal}
		  toEdit={productToEdit}
          onDidDismiss={(_v) => {
			// console.log(_v);
            if (_v) {
				handleProductSubmit(_v.result);
				// handleCategorySubmit(_v.result);
              // console.log(_v.result);
              // store.addItem({ ..._v.result });
            }
            setShowProductModal(false);
			// window.location.hash = '';
          }
		  }
        />
		
		<IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'¿Está seguro?'}
          message={'Confirma que deseas eliminar los productos seleccionados'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
			  
            },
            {
              text: 'Eliminar',
              handler: () => {
                handleEliminar()
              }
            }
          ]}
        />
		
		<IonHeader>
			<IonToolbar mode="ios">
				<IonButtons slot="start">
					<IonBackButton mode="ios" text="Volver" defaultHref="/tabs" />
				</IonButtons>
				<IonTitle mode="ios">Mis productos</IonTitle>
				<IonButtons slot="end">
					<IonButton onClick={() => { setProductToEdit(null); setShowProductModal(true) } }>
						<IonIcon icon={createOutline} />
					</IonButton>
				</IonButtons>
			</IonToolbar>
		</IonHeader>
		<IonContent>
				<div className="buscadorInput">
					<IonIcon icon={searchOutline} />
					<IonInput placeholder="Buscar producto" onIonInput={(e) => { _handleBusqueda(e) } } />
				</div>
				<div className="OrdenarInput" style={{marginBottom: '15px'}}>
					<IonButton mode="ios" color="medium" expand="full" fill="clear" onClick={() => { setProductosCheck([]); setDesactivarReorder(!desactivarReorder) }}>
						<IonIcon icon={funnelOutline} slot="start" />
						{desactivarReorder ? 'Ordenar productos' : 'Dejar de ordenar'}
					</IonButton>
				</div>
			<IonReorderGroup disabled={desactivarReorder} onIonItemReorder={reorderProducts}>
				{_renderProductos()}
			</IonReorderGroup>
		</IonContent>
		{(productosCheck.length) ? (<IonFooter>
			<IonToolbar mode="ios">
				<IonButtons>
					{productosCheck.length === 1 ? (<IonTabButton onClick={() => { handleEditar() }} style={{color: '#222428'}} fill="clear" className="textoAbajo">
						<IonIcon className="rotated" icon={createOutline} /> Editar
					</IonTabButton>) : ''}
					<IonTabButton onClick={() => { handleActivar() }} style={{color: '#2dd36f'}} fill="clear" className="textoAbajo">
						<IonIcon className="rotated" icon={toggle} /> Activar
					</IonTabButton>
					<IonTabButton onClick={() => { handleDesactivar() }} style={{color: '#ffc409'}} fill="clear" className="textoAbajo">
						<IonIcon icon={toggleOutline} /> Desactivar
					</IonTabButton>
					<IonTabButton onClick={() => { handleEliminarPrev() }} style={{color: '#eb445a'}} fill="clear" className="textoAbajo">
						<IonIcon icon={trashOutline} /> Eliminar
					</IonTabButton>
					
				</IonButtons>
			</IonToolbar>
		</IonFooter>) : ''}
		{!desactivarReorder ? (
			<IonFooter>
				<IonToolbar mode="ios">
					<IonButtons style={{justifyContent: 'center'}}>
						<IonTabButton onClick={() => { handleSavePositions() }} fill="clear" className="textoAbajo">
							<IonIcon icon={saveOutline} />
							Guardar posiciones
						</IonTabButton>
					</IonButtons>
				</IonToolbar>
			</IonFooter>
		) : null}
    </IonPage>
	) : null;
};

export default observer(Productos);