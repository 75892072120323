import * as firebase from "firebase"; // 4.3.0
require("firebase/firestore");

const firebaseConfig = {
  apiKey: "AIzaSyDpy1LQ0Dj-g09kW42C5ypPFCCGpbIr3_U",
  authDomain: "micatalogovirtual-7bfd1.firebaseapp.com",
  projectId: "micatalogovirtual-7bfd1",
  storageBucket: "micatalogovirtual-7bfd1.appspot.com",
  messagingSenderId: "232830642721",
  appId: "1:232830642721:web:69e6788956c4a59f3fd160"
};

// Ensure that you do not login twice.
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// const firestore = firebase.firestore();
// const settings = { timestampsInSnapshots: true };
// firestore.settings(settings);

/**
 * so this function is called when the authentication state changes
 * in the application, a side effect of that is that we need to get
 * the rest of the user data from the user collection, that is
 * done with the _handleAuthedUser callback
 */
export const authCheck = async (_handleAuthedUser) => {
  return new Promise((resolve) => {
    // Listen for authentication state to change.
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user != null) {
        // console.log("We are authenticated now!");

        return resolve(await _handleAuthedUser(user));
      } else {
        // console.log("We did not authenticate.");
        _handleAuthedUser(null);
        return resolve(null);
      }
    });
  });
};

export const quickChanges = async () => {
  console.log('TEEEEEEEEEST')
  // firebase.firestore().collection('clients').get().then(datas => {
  //   datas.forEach(doc => {
  //     // firebase.firestore().collection('clients').doc(doc.id).update({dia_corte: 30});
  //     // console.log(doc.data())
  //     firebase.firestore().collection('clients').doc(doc.id).collection('facturas').get().then(datas2 => {
  //       if (!datas2.empty) {
  //         datas2.forEach(doc2 => {
  //           var dat = doc2.data();
  //           if (dat.estado === 'pendiente') {
  //             firebase.firestore().collection('clients').doc(doc.id).collection('facturas').doc(doc2.id).update({
  //               dia_pago: 30,
  //               mes_pago: 4,
  //               year_pago: 2021
  //             })
  //             // console.log(doc2.data())
  //           }
  //         })
  //       }
  //     })
  //   })
  // })
  // firebase.firestore().collection('stores').get().then(datas => {
  //   datas.forEach(doc => {
  //     var curData = {...doc.data()};
  //     if (curData.hasOwnProperty('activo'))
  //     delete curData.activo;

  //     firebase.firestore().collection('stores').doc(doc.id).set({
  //       ...curData,
  //       active: true
  //     })
  //   })
  // })

  // firebase.firestore().collection('clients').get().then(datas => {
  //   datas.forEach(doc => {
  //     // console.log(doc.id, doc.data())
  //     firebase.firestore().collection('clients').doc(doc.id).collection('facturas').get().then(datas2 => {
  //       if (!datas2.empty) {
  //         datas2.forEach(doc2 => {
  //           var factura = doc2.data();
  //           if (factura.estado === 'pendiente' || factura.estado === 'aprobado') {
  //             firebase.firestore().collection('planes').doc(factura.id_plan).get().then(plan => {
  //               if (plan.exists) {
  //                 var planInfo = plan.data();

  //                 var firstDate = new Date(factura.mes_pago + '/' + factura.dia_pago + '/' + factura.year_pago);
  //                 var secondDate = new Date(Date.now());

  //                 var Difference_In_Time = secondDate.getTime() - firstDate.getTime();
  //                 var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
  //                 // console.log(store.plan)
  //                 var diasrestantes = (planInfo.duracionendias - Difference_In_Days)
  //                 console.log(factura, ' => ', diasrestantes)
  //                 if (diasrestantes < 1) {
  //                   // console.log('Desactivar tienda')
  //                   // console.log(planInfo)
  //                   // console.log(doc.data())
  //                   // firebase.firestore().collection('stores').doc(doc.id).update({active: false});
  //                   firebase.firestore().collection('stores').doc(doc.id).get().then(store => {
  //                     if (store.exists) {
  //                       firebase.firestore().collection('stores').doc(doc.id).update({active: true})
  //                     }
  //                   })
  //                 }
  //               }
  //             })
  //           }
  //         })
  //       }
  //     })
  //   })
  // })

  // firebase.firestore().collection('stores').get().then(datas => {
  //   datas.forEach(doc => {
  //     firebase.firestore().collection('clients').doc(doc.id).get().then(datas2 => {
  //       if (!datas2.empty) {
  //         firebase.firestore().collection('clients').doc(doc.id).set({
  //           ...datas2.data(),
  //           activa: doc.data().active
  //         })
  //       }
  //     })
  //   })
  // })

}

/**
 *
 * @param {*} email
 * @param {*} password
 */
export const loginWithEmail = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const getCurrentUser = () => {
  return firebase.auth().currentUser;
};
/**
 *
 */
export const logOut = () => {
  return firebase.auth().signOut();
};

export const recoverPassword = (email) => {
  return firebase.auth().sendPasswordResetEmail(email, {
    url: 'https://back.micatalogovirtual.com/?email=' + email
  })
    .then((resp) => {
      console.log(resp)
    });
}

export const changePassword = (password) => {
  return firebase.auth().currentUser.updatePassword(password).then((resp) => {
      console.log(resp)
    });
}


/**
 *
 * @param {*} userInfo.lastName
 * @param {*} userInfo.firstName
 * @param {*} userInfo.email
 * @param {*} userInfo.password
 */
export const registerUser = (userInfo) => {
  // console.log("in registerUser");
  return fetch('https://crearusuariofromapp-splaq4s7fa-uc.a.run.app', {
    method: 'POST',
    body: JSON.stringify(userInfo), // data can be `string` or {object}!
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    return response.json().then(data => {
      return {...data};
    })
  })

  // return firebase
  //   .auth()
  //   .createUserWithEmailAndPassword(userInfo.email, userInfo.password)
  //   .then((newUser) => {
  //     let { email, firstName, lastName } = userInfo;

  //     return firebase
  //       .firestore()
  //       .collection("users")
  //       .doc(newUser.user.uid)
  //       .set({
  //         email,
  //         firstName,
  //         lastName,
  //       })
  //       .then(() => {
  //         return { ...newUser.user, firstName, lastName };
  //       });
  //   });
};

/**
 *
 */
export const getUserProfile = () => {
  let user = firebase.auth().currentUser;
  // console.log(user);

  var userRef = firebase.firestore().collection("clients").doc(user.uid);
  return userRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        // console.log("Document data:", doc.data());
		return {
		  ...doc.data(),
		  id: user.uid,
		};
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!", user.uid);
        return null;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const getTiendaInfo = () => {
  let user = firebase.auth().currentUser;
  // console.log(user);

  var userRef = firebase.firestore().collection("stores").doc(user.uid);
  return userRef
	// .where('id_client', '==', user.uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        // console.log("Document data:", doc.data());
		return {
		  ...doc.data(),
		  id: user.uid,
		};
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!", user.uid);
        return null;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

/**
 *
 * @param {*} param0
 */
export const queryObjectCollection = ({ collection }) => {
  let currentUserId = firebase.auth().currentUser.uid;
  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection);

  let results = [];

  return (
    collectionRef
	  .orderBy('created', 'desc')
      //.where('owner', '==', currentUserId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          results.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return results;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return error;
      })
  );
};

export const queryObjectCollectionByPosition = ({ collection }) => {
  let currentUserId = firebase.auth().currentUser.uid;
  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection);

  let results = [];

  return (
    collectionRef
	  .orderBy('position', 'asc')
      // .orderBy('created', 'desc')
	  //.where('owner', '==', currentUserId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          results.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return results;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return error;
      })
  );
};

export const queryPlan = (userInfo) => {
	// console.log(userInfo);
	if (userInfo.hasOwnProperty('id_plan')) {
		let planRef = firebase.firestore().collection('planes').doc(userInfo.id_plan);
		return (
			planRef.get().then((doc) => {
				if (doc.exists) {
					let priceRef = planRef.collection('precios').doc(userInfo.moneda);
					return priceRef.get().then((doc2) => {
						if (doc2.exists) {
							let dataToRetrieve = {
								...doc.data(),
								...doc2.data(),
								...{moneda: userInfo.moneda, id_plan: doc.id}
							};
							// console.log(dataToRetrieve);
							return dataToRetrieve;
						} else {
							let dataToRetrieve = {
								...doc.data(),
								...{moneda: userInfo.moneda, id_plan: doc.id}
							}
							return dataToRetrieve;
							// console.log('El plan no tiene asignado un precio para la moneda del cliente');
						}
					})
					.catch((error2) => {
						console.log('Error ', error2);
					})
				} else {
					console.log('Plan no existe');
				}
			}).catch((err) => {
				console.log('Error ', err);
			})
		);
	} else {
		return new Promise((resolve, reject) => {
			resolve(
				{}
			);
		});
	}
};

export const queryPlanes = (userInfo) => {
	// console.log(userInfo);
	var moneda = 'cop';
	if (userInfo.hasOwnProperty('moneda')) {
		moneda = userInfo.moneda;
	}
	let results = [];
	let planRef = firebase.firestore().collection('planes').where('mostrar', '==', true);
	return (
		planRef.get().then((snap) => {
			snap.forEach(async (doc) => {
				results.push({
					id: doc.id,
					...doc.data()
				});
			})
			
			const promises = results.map(async (value, key) => {
				var precioRef = firebase.firestore().collection('planes').doc(value.id).collection('precios').doc(moneda);
				return (
					precioRef.get().then((snap2) => {
						if (snap2.exists) {
							return {
								...value,
								...snap2.data(),
								...{moneda: moneda}
							}
						} else {
							return {
								...value,
								...{moneda: moneda}
							}
						}
					})
				);
				// const precio = await firebase.firestore().collection('planes').doc(value.id).collection('precios').doc(userInfo.moneda);
				
			});
			
			return Promise.all(promises);
			// if (docs.exists) {
				// docs.forEach(async (doc) => {
					// const precio = await doc.doc(userInfo.moneda).get();
					// if (precio.exists) {
						// results.push({
							// id: doc.id,
							// ...doc.data(),
							// ...precio.data(),
							// ...{moneda: userInfo.moneda}
						// });
					// }
				// });
				// return results;
			// }
				
		}).catch((err) => {
			console.log('Error ', err);
		})
	);
};

/**
 *
 * @param {*} _collection - name of collection to add object to
 * @param {*} _objectData - data to add to the collection
 */
export const addObjectToCollection = ({ collection, objectData }) => {
  let currentUserId = firebase.auth().currentUser.uid;
  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection);
	
	let tempObj = {
		created: new Date().getTime(),
		updated: new Date().getTime(),
	};
	
  return collectionRef
    .add({
		...objectData,
		...tempObj
    })
    .then(
      async (doc) => {
        // console.log(`addObjectToCollection ${collection} ${doc}`);

        let docData = await getByRef(doc);
        return docData;
      },
      (error) => {
        console.log(`ERROR: addObjectToCollection ${collection} ${error}`);
        return error;
      }
    )
    .catch((e) => {
      console.log(`ERROR: addObjectToCollection ${collection} ${e}`);
      return e;
    });
};

/**
 *
 * @param {*} _collection - name of collection to update object to
 * @param {*} _objectData - data to set to the collection
 */
export const setObjectToCollection = ({ collection, id, objectData }) => {
  let currentUserId = firebase.auth().currentUser.uid;
  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection).doc(id);
  // console.log(id, objectData);
	// let tempObj = {
		// updated: new Date().getTime(),
	// };
	
  return collectionRef
    .update(objectData)
    .then(
      async () => {
        let docData = await getByRef(collectionRef);
        return docData;
      },
      (error) => {
        console.log(`ERROR: setObjectToCollection ${collection} ${error}`);
        return error;
      }
    )
    .catch((e) => {
      console.log(`ERROR: setObjectToCollection ${collection} ${e}`);
      return e;
    });
};

export const setObjectToCollectionSpecial = ({ collection, id, objectData }) => {
  let currentUserId = firebase.auth().currentUser.uid;
  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection).doc(id);
  // console.log(id, objectData);
	// let tempObj = {
		// updated: new Date().getTime(),
	// };
	
  return collectionRef
    .set(objectData, {merge:true})
    .then(
      async () => {
        let docData = await getByRef(collectionRef);
        return docData;
      },
      (error) => {
        console.log(`ERROR: setObjectToCollection ${collection} ${error}`);
        return error;
      }
    )
    .catch((e) => {
      console.log(`ERROR: setObjectToCollection ${collection} ${e}`);
      return e;
    });
};

export const saveNewStore = (store) => {
	let currentUserId = firebase.auth().currentUser.uid;
	let ref = firebase.firestore().collection('stores').doc(currentUserId);
	
	let tempObj = {
    active: true,
		updated: new Date().getTime(),
		tienda: store
	};
	
	return ref
    .set({
		...tempObj
    })
    .then(
      async (doc) => {
			let docData = await getByRef(ref);
			return docData;
      },
      (error) => {
        console.log(`ERROR: saveNewStore ${store} ${error}`);
        return error;
      }
    )
    .catch((e) => {
      console.log(`ERROR: saveNewStore ${store} ${e}`);
      return e;
    });
}



/**
 *
 * @param {*} collection - name of collection
 * @param {*} objectId - id of data to remove from the collection
 */
export const removeObjectFromCollection = ({ collection, objectId }) => {
  let currentUserId = firebase.auth().currentUser.uid;
  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection);

  return collectionRef
    .doc(objectId)
    .delete()
    .then(
      async (doc) => {
        console.log(`removeObjectFromCollection ${collection} ${objectId}`);
        return true;
      },
      (error) => {
        console.log(`ERROR: removeObjectFromCollection ${collection} ${error}`);
        return error;
      }
    )
    .catch((e) => {
      console.log(`ERROR: removeObjectFromCollection ${collection} ${e}`);
      return e;
    });
};

export const checkIfStoreExists = (_store) => {
	let ref = firebase.firestore().collection('stores');
	let results = [];
	
	return ref
		.where('tienda', '==', _store)
		.get()
		.then((docs) => {
			docs.forEach((doc) => {
			  // doc.data() is never undefined for query doc snapshots
			  results.push({
				id: doc.id,
				...doc.data(),
			  });
			});
			// console.log(results);
			return { ...results[0] };
		})
		.catch((error) => {
			return error;
		})
}

export const getByRef = (_documentRef) => {
  return _documentRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { ...doc.data(), id: _documentRef.id };
      } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!");
        return null;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return error;
    });
};

/**
 *
 * @param {*} blob
 */
export const uploadImage = (blob) => {
  return new Promise((resolve, reject) => {
    let currentUserId = firebase.auth().currentUser.uid;
    const ref = firebase
      .storage()
      .ref(currentUserId)
      .child(new Date().getTime() + "-" + currentUserId + ".jpeg");

    const task = ref.put(blob);

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>
        console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        console.log("error", error);
        return reject(error);
      },
      (result) => {
        return resolve({
          url: task.snapshot.downloadURL,
          contentType: task.snapshot.metadata.contentType,
          name: task.snapshot.metadata.name,
          size: task.snapshot.metadata.size,
        });
      }
    );
  });
};


export const uploadFile = (path, blob) => {
  return new Promise((resolve, reject) => {
    // let currentUserId = firebase.auth().currentUser.uid;
	
	let storage = firebase.storage().ref(path);
	const task = storage.put(blob);

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>
        console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        console.log("error", error);
        return reject(error);
      },
      (result) => {
        return resolve({
          url: task.snapshot.downloadURL,
          contentType: task.snapshot.metadata.contentType,
          name: task.snapshot.metadata.name,
          size: task.snapshot.metadata.size,
        });
      }
    );
  });
};


export const saveInClientProfile = (registros) => {
	let user = firebase.auth().currentUser;

	var userRef = firebase.firestore().collection("clients").doc(user.uid);
	return userRef
		.update(registros)
		.then((response) => {
			// console.log(response);
		})
		.catch((error) => {
		  console.log("Error getting document:", error);
		});
}


