import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonTitle,
  IonModal,
  IonButton,
  IonToolbar,
  IonContent,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonImg,
  IonIcon,
  IonText,
  IonBackButton,
  IonButtons,
  IonSkeletonText
} from "@ionic/react";
import { logoWhatsapp, cashOutline } from 'ionicons/icons';
import RetrieveImage from './../retrieveImg';
import './../styles/lineas.css';
// import Countries from '../countries.json';

const NotasModal = ({ showModal, onDidDismiss, toEdit }) => {
	const [pedido, setPedido] = useState({
		created: 0,
		id: '',
		nombre: '',
		telefono: '',
		totalpagado: 0,
		products: {},
	});
	
	const [showModalInfo, setShowModalInfo] = useState(false);
	
	const returnPrice = (price) => {
		return new Intl.NumberFormat('es-ES').format(price);
	}
	
	useEffect(() => {
		if (toEdit !== null) {
			const newProduct = {...toEdit.item};
			newProduct.id = toEdit.id;
			
			var today = new Date(newProduct.created);
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();
			
			newProduct.created = dd + '/' + mm + '/' + yyyy;
			console.log(newProduct);
			
			setPedido(newProduct);
		}
	}, [toEdit]);
	
	const _renderProducts = () => {
		const productos = {...pedido.products};
		return Object.keys(productos).map((k) => {
			const producto = {...productos[k]};
			return (
				<div className="productInfoRow" key={k}>
					<div>{(producto.cover && typeof producto.cover === 'string') ? (<IonImg src={RetrieveImage(producto.cover)} className="imageRedonda" alt="Cover" />) : ((producto.cover && typeof producto.cover === 'object') ? (<IonImg className="imageRedonda" src={RetrieveImage(producto.cover[0])} alt="Cover" />) : <IonSkeletonText className="skeletonRedondo" />)}</div>
					<div className="productoCortado">{producto.name}</div>
					<div>{producto.attributes}</div>
					<div>{producto.quantity}</div>
					<div className="precioRow">$ {returnPrice(producto.total)}</div>
				</div>
			);
		});
	}
	
	return (
    <IonModal mode="ios" isOpen={showModal} onDidDismiss={() => onDidDismiss()}>
	
		<IonModal mode="ios" isOpen={showModalInfo} onDidDismiss={() => { setShowModalInfo(false);  }} >
			<IonToolbar mode="ios">
				<IonButtons slot="end">
					<IonButton mode="ios" onClick={() => { setShowModalInfo(false) }}>
						Cerrar
					</IonButton>
				</IonButtons>
			</IonToolbar>
			<div className="modalInfoPagos">
				<p className="infoMetodosPago">
					Solicita tu integración con una de las plataformas de pago
				</p>
				<IonImg src="/img/logos.png" />
				<a className="botonLinkWhatsapp" rel="noopener noreferrer" href={'//wa.me/573502812684?text=Quiero más información para integrar una plataforma de pago a mi cuenta'} target="_blank">Solicitar</a>
			</div>
		</IonModal>
		<IonHeader>
			<IonToolbar mode="ios">
				<IonButtons slot="start">
					<IonBackButton mode="ios" onClick={ () => {
						onDidDismiss();
					} } defaultHref="/tabs/pedidos" text="Volver"/>
				</IonButtons>
				<IonTitle mode="ios">Detalle del pedido</IonTitle>
			</IonToolbar>
		</IonHeader>
      <IonContent className="conPadding">
		<IonItem lines="none">
			<div style={{width:'100%'}}>
				<IonGrid>
					<IonRow>
						<IonCol size={12}>
							<IonText className="ion-padding-start">
								<h1 className="tituloPedido">Pedido <small>{pedido.id.toUpperCase()}</small></h1>
							</IonText>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size={6}>
							Cliente
						</IonCol>
						<IonCol size={6}>
							{pedido.nombre}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size={6}>
							Fecha
						</IonCol>
						<IonCol size={6}>
							{pedido.created}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size={6}>
							Valor del pedido
						</IonCol>
						<IonCol size={6}>
							$ {returnPrice(pedido.totalpagado)}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size={6}>
							Cantidad de productos
						</IonCol>
						<IonCol size={6}>
							{Object.keys(pedido.products).length}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size={12}>
							<IonText className="ion-padding-start">
								<h2 style={{marginTop:'15px'}}>Productos</h2>
								{_renderProducts()}
							</IonText>
						</IonCol>
					</IonRow>
				</IonGrid>
			</div>
		</IonItem>
	  </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <a className="botonWhatsappContacto" rel="noopener noreferrer" href={"//wa.me/" + pedido.telefono.replace('+', '')} target="_blank">
					Contactar cliente <IonIcon icon={logoWhatsapp} />
				</a>
              </IonCol>
            </IonRow>
			<IonRow>
              <IonCol>
                <IonButton onClick={() => { setShowModalInfo(true); }} mode="ios" className="botonWhatsappContacto BOTON">
					Generar link de pago <IonIcon icon={cashOutline} />
				</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </IonModal>
	);
};

export default NotasModal;
