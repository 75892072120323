import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonTitle,
  IonModal,
  IonButton,
  IonToolbar,
  IonContent,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonLabel,
  IonRippleEffect,
  IonIcon,
  IonBackButton,
  IonButtons,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import { mailOutline, locateOutline, cardOutline, personOutline, addCircleOutline } from 'ionicons/icons';
import Dptos from './../dptos';

const FacturaModal = ({ showModal, onDidDismiss, toEdit }) => {
	const [nombre, setNombre] = useState('');
	const [apellido, setApellido] = useState('');
	const [tipodoc, setTipodoc] = useState('');
	const [numerodoc, setNumerodoc] = useState('');
	const [departamento, setDepartamento] = useState('');
	const [ciudad, setCiudad] = useState('');
	const [correo, setCorreo] = useState('');
	const [realDpto, setRealDpto] = useState('');
	
	const [ciudades, setCiudades] = useState([]);
	
	useEffect(() => {
		if (toEdit !== null) {
			const factura = toEdit.facturacion
			if (typeof factura !== 'undefined') {
				if (isJson(factura)) {
					const facturacion = {...JSON.parse(toEdit.facturacion)};
					if (Object.keys(facturacion).length) {
						setNombre(facturacion.nombre);
						setApellido(facturacion.apellido);
						setCorreo(facturacion.correo);
						
						if (facturacion.tipodoc) {
							setTipodoc(facturacion.tipodoc);
						}

						if (facturacion.departamento) {
							setDepartamento(facturacion.departamento);
							setCiudades(Dptos[facturacion.departamento].ciudades);
						}

						if (facturacion.realDpto) {
							setRealDpto(facturacion.realDpto);
						}
						if (facturacion.ciudad) {
							setCiudad(facturacion.ciudad);
						}

						if (facturacion.numerodoc) {
							setNumerodoc(facturacion.numerodoc);
						}
					}
				}
			}
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	
	const isJson = (str) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
	
	const handleSubmit = () => {
		let ret = {
			nombre, apellido, tipodoc, realDpto, ciudad, correo, numerodoc, departamento
		};
		onDidDismiss({ result: ret });
	}
	
	const _renderDptos = () => {
		return Dptos.map((value, key) => {
			// console.log(key);
			return (
				<IonSelectOption key={'departamento_' + key} value={key}>{value.departamento}</IonSelectOption>
			);
		});
	}
	
	const handleDepartamento = (index) => {
		const dep = Dptos[index];
		if (typeof dep !== 'undefined') {
			setDepartamento(index);
			setRealDpto(dep.departamento);
			setCiudades(dep.ciudades);
		}
		// setDepartamento(dep.departamento);
		// setCiudades(dep.ciudades);
	}
	
	const _renderCiudades = () => {
		return ciudades.map((value, key) => {
			return (
				<IonSelectOption key={'ciudad_'+key} value={value}>{value}</IonSelectOption>
			);
		})
	}   
	
	return (
    <IonModal mode="ios" isOpen={showModal} onDidDismiss={() => onDidDismiss()}>
		<IonHeader>
			<IonToolbar mode="ios">
				<IonButtons slot="start">
					<IonBackButton mode="ios" onClick={ () => {
						setNombre('');
						setApellido('');
						setTipodoc('');
						setNumerodoc('');
						setDepartamento('');
						setRealDpto('');
						setCiudad('');
						setCiudades([]);
						setCorreo('');
						onDidDismiss();
					} } defaultHref="/tabs/lineas" text="Cancelar"/>
				</IonButtons>
				<IonTitle mode="ios">Detalle de facturación</IonTitle>
			</IonToolbar>
		</IonHeader>
      <IonContent>
        <IonItem className="itemPadding">
          <IonLabel position="floating">Nombre</IonLabel>
		  <IonInput
            type="text"
            onIonInput={(e) => setNombre(e.target.value)}
            name="nombre"
			required={true}
			value={nombre}
          />
		  <IonIcon icon={personOutline} slot="start" className="ion-align-self-end" />
        </IonItem>
		
		<IonItem className="itemPadding">
          <IonLabel position="floating">Apellido</IonLabel>
		  <IonInput
            type="text"
            onIonInput={(e) => setApellido(e.target.value)}
            name="apellido"
			required={true}
			value={apellido}
          />
		  <IonIcon icon={personOutline} slot="start" className="ion-align-self-end" />
        </IonItem>
		
		<IonItem className="itemPadding">
          <IonLabel position="floating">Tipo de documento</IonLabel>
		  <IonSelect value={tipodoc} cancelText="Cancelar" okText="Ok" onIonChange={e => setTipodoc(e.detail.value)}>
              <IonSelectOption value="CC">Cédula de Ciudadanía</IonSelectOption>
              <IonSelectOption value="CE">Cédula de extranjería</IonSelectOption>
			  <IonSelectOption value="Pasaporte">Pasaporte</IonSelectOption>
		  </IonSelect>
		  <IonIcon icon={cardOutline} slot="start" className="ion-align-self-end" />
        </IonItem>
		
		<IonItem className="itemPadding">
          <IonLabel position="floating">Número de documento</IonLabel>
		  <IonInput
            type="number"
            onIonInput={(e) => setNumerodoc(e.target.value)}
            name="numerodoc"
			required={true}
			value={numerodoc}
          />
		  <IonIcon icon={cardOutline} slot="start" className="ion-align-self-end" />
        </IonItem>
		
		<IonItem className="itemPadding">
          <IonLabel position="floating">Departamento</IonLabel>
		  <IonSelect value={departamento} cancelText="Cancelar" okText="Ok" onIonChange={e => {handleDepartamento(e.detail.value)}}>
              {_renderDptos()}
		  </IonSelect>
		  <IonIcon icon={locateOutline} slot="start" className="ion-align-self-end" />
        </IonItem>
		
		<IonItem className="itemPadding">
          <IonLabel position="floating">Ciudad</IonLabel>
		  <IonSelect value={ciudad} cancelText="Cancelar" okText="Ok" onIonChange={e => setCiudad(e.detail.value)}>
              {_renderCiudades()}
		  </IonSelect>
		  <IonIcon icon={locateOutline} slot="start" className="ion-align-self-end" />
        </IonItem>
		
		<IonItem className="itemPadding">
          <IonLabel position="floating">Email</IonLabel>
		  <IonInput
            type="text"
            onIonInput={(e) => setCorreo(e.target.value)}
            name="correo"
			required={true}
			value={correo}
          />
		  <IonIcon icon={mailOutline} slot="start" className="ion-align-self-end" />
        </IonItem>
		
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
                  expand="full"
				  className="botonLineal"
                  onClick={() => {
						handleSubmit()
                  }}
                >
				{toEdit !== null ? 'Modificar' : 'Agregar'} <IonIcon slot="end" icon={addCircleOutline} />
				  <IonRippleEffect type="unbounded"></IonRippleEffect>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </IonModal>
	);
};

export default FacturaModal;
