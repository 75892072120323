import React, { useState, useEffect, useRef } from "react";
import {
  IonContent,
  IonPage,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonBackButton,
  IonLoading,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent
} from "@ionic/react";
// MOBX
import { MobXProviderContext } from "mobx-react";
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import './../styles/lineas.css';

import md5 from 'md5'
import { PieChart } from 'react-minimal-pie-chart';
import FacturaModal from "./FacturacionModal";

const Facturacion = () => {
	const planesref = useRef(null)
	const facturasref = useRef(null)
	
	const executeScroll = () => {
		planesref.current.scrollIntoView({ behavior: 'smooth', block: 'start'})
	}

	const executeScroll2 = () => {
		facturasref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}
	
	const { store } = React.useContext(MobXProviderContext);
	const [plan, setPlan] = useState({
		categorias: -1,
		cuenta_verificada: false,
		informes_profesionales: false,
		lineas: 5,
		moneda: 'cop',
		nombre: 'Plan Basic T',
		panel_admin: true,
		precio: 0,
		productos: -1,
		soporte_web: true,
		ordenes: 250,
	});
	const [usadasLineas, setUsadasLineas] = useState(0);
	const [usadasCategorias, setUsadasCategorias] = useState(0);
	const [usadasProductos, setUsadasProductos] = useState(0);

	const [yaPagoImplementacion, setYaPagoImplementacion] = useState(true);
	
	
	const [userData, setUserData] = useState({
		nombre: '',
		apellido: '',
		tipodoc: '',
		numerodoc: '',
		realDpto: '',
		ciudad: '',
		correo: ''
	});
	
	const [showModal, setShowModal] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	
	let user = store.activeUser;
	let plann = store.plan;
	
	useEffect(() => {
		if (user && plann !== null && plann.hasOwnProperty('nombre')) {
			setPlan(plann);
		}
		let lineas = JSON.parse(user.lineas);
		let facturacionData = JSON.parse(user.facturacion);
		
		setUserData({...facturacionData});
		setUsadasLineas(Object.entries(lineas.lineas).length);
		setUsadasProductos(parseInt(user.total_products));
		setUsadasCategorias(parseInt(user.total_categories));

		var yapagoimplementacion = true;
		store.itemFacturas.map(([key, value]) => {
			var obj = {...value};
			if (obj.id_plan === 'xNQXia2XK6T7vqgHBF2N') {
				yapagoimplementacion = false;
			}
			return true;
		})
		setYaPagoImplementacion(yapagoimplementacion);		
	}, [user.facturacion]); // eslint-disable-line react-hooks/exhaustive-deps
	
	const calcularLineas = () => {
		let porcentajeUsado = ((usadasLineas * 100) / plan.lineas);
		let porcentajeRestante = (100 - porcentajeUsado);
		
		return [
			{ title: 'Usado', value: porcentajeUsado, color: '#E38627' },
			{ title: 'Restante', value: porcentajeRestante, color: '#c9ced6' }
		]
	}
	
	const calcularProductos = () => {
		let porcentajeUsado = ((usadasProductos * 100) / (plan.productos < 0 ? 250 : plan.productos));
		let porcentajeRestante = (100 - porcentajeUsado);
		let color = ((user && user.hasOwnProperty('paleta')) ? user.paleta[0] : '#48ac98');
		
		return [
			{ title: 'Usado', value: porcentajeUsado, color: color },
			{ title: 'Restante', value: porcentajeRestante, color: '#c9ced6' }
		]
	}
	
	const calcularCategorias = () => {
		let porcentajeUsado = ((usadasCategorias * 100) / (plan.categorias < 0 ? 20 : plan.categorias));
		let porcentajeRestante = (100 - porcentajeUsado);
		let color = ((user && user.hasOwnProperty('paleta')) ? user.paleta[1] : '#25D366');
		
		return [
			{ title: 'Usado', value: porcentajeUsado, color: color },
			{ title: 'Restante', value: porcentajeRestante, color: '#c9ced6' }
		]
	}
	
	const returnPrice = (price) => {
		return new Intl.NumberFormat('es-ES').format(price);
	}
	
	const renderCheck = (numero, valor='') => {
		// console.log(numero, valor);
		var ifNumero = parseInt(numero);
		// console.log(ifNumero);
		if (ifNumero || ifNumero < 0 || numero === true) {
			return (
				<IonIcon icon={checkmarkCircleOutline} className="lotiene" />
			);
		} else {
			return (
				<IonIcon icon={closeCircleOutline} className="nolotiene" />
			);
		}
	}
	
	const _renderPlanes = () => {
		return store.itemPlanes.filter((item) => {
			let datosF = {...item[1]};
			return datosF.precio > 0 && datosF.mostrarenUI;
		}).sort((a, b) => {
			let datosSA = {...a[1]};
			let datosSB = {...b[1]};

			return datosSA.precio > datosSB.precio;
		}).map(([key, value]) => {
			let md5Text = md5('SZrIdcjOQ1PIbZ8l2TTv1IOHtk~814086~'+(value.id+'-'+store.activeUser.id)+'~'+value.precio+'~COP');
			return (value.id !== store.activeUser.id_plan) ? (
				<div className="infoPlan" key={key}>
					<h4>Plan {value.nombre}</h4>
					<h6>{(value.hasOwnProperty('description')) ? value.description : 'El siguiente paso para reinventar tu negocio o tienda'}</h6>
					<h1>$ {returnPrice(value.precio)} / Mes</h1>
					<h2>Costo de implementation $ 49.800 Pago Único</h2>
					{value.hasOwnProperty('hotmart')
						
						? <>
							{yaPagoImplementacion ? <IonButton href={value.hotmart} target="_blank" className="BotonNuevoPlan" mode="ios">Adquirir este plan</IonButton> : <IonButton onClick={() => { executeScroll2() }} type="button" className="BotonNuevoPlan" mode="ios">Adquirir este plan</IonButton>}
						</> 

						: <form name="payu_latam_form" method="post" target="_blank" action="https://checkout.payulatam.com/ppp-web-gateway-payu/">
							<input type="hidden" name="merchantId" value="814086" />
							<input type="hidden" name="referenceCode" value={(value.id+'-'+store.activeUser.id)} />
							<input type="hidden" name="description" value={'Pago por plan ' + value.nombre} />
							<input type="hidden" name="amount" value={value.precio} />
							<input type="hidden" name="tax" value="0" />
							<input type="hidden" name="taxReturnBase" value="0" />
							<input type="hidden" name="signature" value={md5Text} />
							<input type="hidden" name="accountId" value="821214" />
							<input type="hidden" name="currency" value='COP' />
							<input type="hidden" name="buyerEmail" value={userData.correo || ''} />
							<input type="hidden" name="test" value="0" />
							<input type="hidden" name="extra1" value="Mi catálogo virtual 1.0" />
							<input type="hidden" name="responseUrl" value={window.location.origin + '/receivePayment'} />
							<input type="hidden" name="confirmationUrl" value="https://us-central1-micatalogovirtual-app.cloudfunctions.net/registrarpagopayu" />
							{/*<input type="hidden" name="confirmationUrl" id="confirmationUrl" value="http://tumodalatina.com/modules/payulatam/pages/confirmation.php" />*/}
							<input type="hidden" name="payerFullName" value={userData.nombre + ' ' + userData.apellido} />
							<input type="hidden" name="billingAddress" value={userData.ciudad + ', ' + userData.realDpto} />
							<input type="hidden" name="shippingAddress" value={userData.ciudad + ', ' + userData.realDpto} />
							<input type="hidden" name="billingCity" value={userData.ciudad || ''} />
							<input type="hidden" name="shippingCity" value={userData.ciudad || ''} />
							<input type="hidden" name="billingCountry" value="CO" />
							<input type="hidden" name="shippingCountry" value="CO" />
							{yaPagoImplementacion ? <IonButton type="submit" className="BotonNuevoPlan" mode="ios">Adquirir este plan</IonButton> : <IonButton onClick={() => { executeScroll2() }} type="button" className="BotonNuevoPlan" mode="ios">Adquirir este plan</IonButton>}
						</form>
					}
					
					<div className="listaFake">
						{value.publicidadpaga ? (<div className="itemListaFake">
							{renderCheck(value.publicidadpaga, 'lineas')}
							Publicidad paga en FaceBookADS por {value.publicidadpaga} días
						</div>) : null }
						<div className="itemListaFake">
							{renderCheck(value.lineas, 'lineas')}
							{value.lineas} líneas de WhatsApp
						</div>
						<div className="itemListaFake">
							{renderCheck(value.productos, 'productos')}
							{(value.productos < 0) ? 'Productos ilimitados' : ('Hasta ' + value.productos + ' productos')}
						</div>
						<div className="itemListaFake">
							{renderCheck(value.categorias, 'categorias')}
							{(value.categorias < 0) ? 'Categorías ilimitadas' : ('Hasta ' + value.productos + ' categorías')}
						</div>
						<div className="itemListaFake">
							{renderCheck(value.panel_admin, 'cuentaadmin')}
							Panel de administración
						</div>
						<div className="itemListaFake">
							{renderCheck(value.soporte_web, 'soporte web')}
							Servicio al cliente y soporte
						</div>
						<div className="itemListaFake">
							{renderCheck(true, '')}
							Historial de pedidos
						</div>
						<div className="itemListaFake">
							{renderCheck(true, '')}
							Certificado SSL
						</div>
						<div className="itemListaFake">
							{renderCheck(true, '')}
							Notificaciones PUSH
						</div>
						<div className="itemListaFake">
							{renderCheck(true, '')}
							Manejo de Inventarios
						</div>
						<div className="itemListaFake">
							{renderCheck(true, '')}
							Generación links de pago
						</div>
					</div>
				</div>
			) : null;
		})
	}
	
	const _renderFacturasPendientes = () => {
		var facturas = [];
		
		store.itemFacturas.map(([key, value]) => {
			var date = new Date(value.year_pago+'-'+value.mes_pago+'-'+value.dia_pago);
			date.setDate(date.getDate() + 5);
			var referenceCodeFinal = (value.id_plan+'-'+value.id_cliente+'-'+value.id); 
			let md5Text = md5('SZrIdcjOQ1PIbZ8l2TTv1IOHtk~814086~'+referenceCodeFinal+'~'+parseFloat(value.pagado)+'~COP');

			if (value.estado === 'pendiente') {
				if (value.pagado > 0) {
				facturas.push(
					<div className="facturaX" key={'factura_'+key}>
						<div className="rowFactura">
							<div className="valueFactura">
								{value.description}
							</div>
							<div className="titleFactura">
								Razón de pago
							</div>
						</div>
						<div className="rowFactura">
							<div className="valueFactura">
								{value.dia_pago}/{value.mes_pago}/{value.year_pago}
							</div>
							<div className="titleFactura">
								Fecha de corte
							</div>
						</div>
						<div className="rowFactura">
							<div className="valueFactura">
								$ {returnPrice(parseFloat(value.pagado))}
							</div>
							<div className="titleFactura">
								Total a pagar
							</div>
						</div>
						<div className="rowFactura">
							<div className="valueFactura">
								{date.getUTCDate()}/{(date.getUTCMonth() + 1)}/{date.getUTCFullYear()}
							</div>
							<div className="titleFactura">
								Fecha límite de pago
							</div>
						</div>
						<div className="rowFactura">
							<div className="valueFactura">
								<span className="ucfirst">{value.estado}</span>
							</div>
							<div className="titleFactura">
								Estado
							</div>
						</div>
						<div className="rowFactura">
							<form name="payu_latam_form" method="post" target="_blank" action="https://checkout.payulatam.com/ppp-web-gateway-payu/">
								<input type="hidden" name="merchantId" id="merchantId" value="814086" />
								<input type="hidden" name="referenceCode" id="referenceCode" value={referenceCodeFinal} />
								<input type="hidden" name="description" id="description" value={value.description} />
								<input type="hidden" name="amount" id="amount" value={parseFloat(value.pagado)} />
								<input type="hidden" name="tax" id="tax" value="0" />
								<input type="hidden" name="taxReturnBase" id="taxReturnBase" value="0" />
								<input type="hidden" name="signature" id="signature" value={md5Text} />
								<input type="hidden" name="accountId" id="accountId" value="821214" />
								<input type="hidden" name="currency" id="currency" value="COP" />
								<input type="hidden" name="buyerEmail" id="buyerEmail" value={userData.correo || ''} />
								<input type="hidden" name="test" id="test" value="0" />
								<input type="hidden" name="extra1" id="extra1" value="Mi catálogo virtual 1.0" />
								<input type="hidden" name="responseUrl" id="responseUrl"  value={window.location.origin + '/receivePayment'} />
								<input type="hidden" name="confirmationUrl" value="https://us-central1-micatalogovirtual-app.cloudfunctions.net/registrarpagopayu" />
								<input type="hidden" name="payerFullName" id="payerFullName" value={userData.nombre + ' ' + userData.apellido} />
								<input type="hidden" name="billingAddress" id="billingAddress" value={userData.ciudad + ', ' + userData.realDpto} />
								<input type="hidden" name="shippingAddress" id="shippingAddress" value={userData.ciudad + ', ' + userData.realDpto} />
								<input type="hidden" name="billingCity" id="billingCity" value={userData.ciudad || ''} />
								<input type="hidden" name="shippingCity" id="shippingCity" value={userData.ciudad || ''} />
								<input type="hidden" name="billingCountry" id="billingCountry" value="CO" />
								<input type="hidden" name="shippingCountry" id="shippingCountry" value="CO" />
								<IonButton type="submit" className="botonPagarFactura">Pagar factura</IonButton>
							</form>
						</div>
					</div>
				);
				}
			}
			return true;
		});
		return (facturas.length) ? facturas : (<div className="alert-success">
					Todas sus facturas están al día
				</div>);
	}
	
	const handleSaveData = async (result) => {
		// console.log(result);
		let toSave = JSON.stringify(result);
		setShowLoading(true);
		await store.saveInClientProfile({facturacion: toSave});
		setShowLoading(false);
	}
	
	return store.activeUser ? (
    <IonPage>
		<IonLoading
			cssClass='homepageloader'
			isOpen={showLoading}
			message="Actualizando información..."
		/>
		
		<FacturaModal
			showModal={showModal}
			toEdit={user}
			onDidDismiss={(_v) => {
					if (_v && _v.hasOwnProperty('result')) {
						handleSaveData(_v.result);
					}
					setShowModal(false);
				}
			}
        />
		
		<IonHeader>
			<IonToolbar mode="ios">
				<IonButtons slot="start">
					<IonBackButton mode="ios" text="Volver" defaultHref="/tabs" />
				</IonButtons>
				<IonTitle mode="ios">Facturación</IonTitle>
			</IonToolbar>
		</IonHeader>
		<IonContent className="ion-padding">
			<IonCard mode="ios">
				<IonCardHeader>
					<IonCardTitle className="tituloFacturacion">Plan y uso</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<div className="contenedorInfo">
						<div className="circuloPlan">
							{plan.nombre.substring(0, 1).toUpperCase()}
						</div>
						<div className="textoPlan">{plan.nombre}</div>
					</div>
					<div className="contenedorInfo">
						<div className="circuloChartContainer">
							<div className="circuloChart">
								<div className="CirculoInterior"></div>
							</div>
							<PieChart
								data={calcularLineas()}
								startAngle={270}
							/>
						</div>
						<div className="textoPlan">
							<div className="subtitlePlan">
								Líneas
							</div>
							<div className="contenidoPlan">
								{usadasLineas} de {plan.lineas < 0 ? '∞' : plan.lineas}
							</div>
						</div>
					</div>
					<div className="contenedorInfo">
						<div className="circuloChartContainer">
							<div className="circuloChart">
								<div className="CirculoInterior"></div>
							</div>
							<PieChart
								data={calcularProductos()}
								startAngle={270}
							/>
						</div>
						<div className="textoPlan">
							<div className="subtitlePlan">
								Productos
							</div>
							<div className="contenidoPlan">
								{usadasProductos} de {plan.productos < 0 ? '∞' : plan.productos}
							</div>
						</div>
					</div>
					<div className="contenedorInfo">
						<div className="circuloChartContainer">
							<div className="circuloChart">
								<div className="CirculoInterior"></div>
							</div>
							<PieChart
								data={calcularCategorias()}
								startAngle={270}
							/>
						</div>
						<div className="textoPlan">
							<div className="subtitlePlan">
								Categorías
							</div>
							<div className="contenidoPlan">
								{usadasCategorias < 1 ? 0 : usadasCategorias} de {plan.categorias < 0 ? '∞' : plan.categorias}
							</div>
						</div>
					</div>
					{/*<div className="contenedorInfo">
						<div className="circuloChartContainer">
							<div className="circuloChart">
								<div className="CirculoInterior"></div>
							</div>
							<PieChart
								data={calcularPedidos()}
								startAngle={270}
							/>
						</div>
						<div className="textoPlan">
							<div className="subtitlePlan">
								Pedidos
							</div>
							<div className="contenidoPlan">
								{usadasOrdenes} de {plan.ordenes}
							</div>
						</div>
					</div>*/}
					<div className="contenedorInfo">
						<div className="textoPlan sinMargin" style={{marginLeft:'0'}}>
							<div className="contenidoPlan" style={{fontSize:'12px', textAlign:'center'}}>
								¿Tienes preguntas sobre nuestros planes?
								<IonButton mode="ios" onClick={() => { executeScroll() }}>Conoce más de ellos aquí</IonButton>
							</div>
						</div>
					</div>
					
				</IonCardContent>
			</IonCard>
			<IonCard mode="ios" ref={facturasref}>
				<IonCardHeader>
					<IonCardTitle className="tituloFacturacion">Facturas pendientes de pago</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					{_renderFacturasPendientes()}
				</IonCardContent>
			</IonCard>
			<IonCard mode="ios">
				<IonCardHeader>
					<IonCardTitle className="tituloFacturacion">Detalles de facturación</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<div className="contenedorInfo Detalles">
						<div className="textoPlan sinMargin">
							Nombre: <strong>{userData.nombre}</strong>
						</div>
						<div className="textoPlan sinMargin">
							Apellido: <strong>{userData.apellido}</strong>
						</div>
						<div className="textoPlan sinMargin">
							Tipo de documento: <strong>{userData.tipodoc}</strong>
						</div>
						<div className="textoPlan sinMargin">
							Número de documento: <strong>{userData.numerodoc}</strong>
						</div>
						<div className="textoPlan sinMargin">
							Departamento: <strong>{userData.realDpto}</strong>
						</div>
						<div className="textoPlan sinMargin">
							Ciudad: <strong>{userData.ciudad}</strong>
						</div>
						<div className="textoPlan sinMargin">
							Correo Electrónico: <strong>{userData.correo}</strong>
						</div>
						<div className="textoPlan sinMargin" style={{marginTop:'10px', width: '100%'}}>
							<IonButton mode="ios" className="botonActualizarFact" onClick={() => { setShowModal(true); } }>
								Actualizar datos de facturación
							</IonButton>
						</div>
					</div>
				</IonCardContent>
			</IonCard>
			<IonCard mode="ios" ref={planesref}>
				<IonCardHeader>
					<IonCardTitle className="tituloFacturacion">Elige o actualiza tu plan</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					{_renderPlanes()}
					<div className="infoPlan">
							<h4>Plan Corporativo</h4>
							<h6>Brindamos soluciones digitales con herramientas como; catálogos virtuales, integraciones, Bigdata, y desarrollos a medida para grandes empresas. </h6>
							<h1>A convenir</h1>
							<IonButton target="_blank" href="https://api.whatsapp.com/send?phone=+573042703920&text=Quiero%20comprar%20el%20plan%20BASIC" className="BotonNuevoPlan" mode="ios">Comenzar ahora</IonButton>
					</div>
				</IonCardContent>
			</IonCard>
			<IonCard mode="ios">
				<IonCardHeader>
					<IonCardTitle className="tituloFacturacion">¿Necesitas ayuda?</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					Nuestro soporte al cliente se pondrá en contacto contigo lo más rápido posible.
					<a href="//wa.me/573502812684?text=Hola, tengo algunas dudas con mi facturación" className="contactanosBoton">Contáctanos</a>
				</IonCardContent>
			</IonCard>
		</IonContent>
    </IonPage>
	) : null;
};

export default Facturacion;
