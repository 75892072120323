import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import { IonApp, IonRouterOutlet, IonLoading } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RecibirPago from "./pages/RecibirPago";
import RegistrationPage from './pages/RegistrationPage';
import RecoverPasswordPage from './pages/RecoverPasswordPage';

import { observer, MobXProviderContext } from "mobx-react";

const PrivateRoutes = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        {/****** AUTH CREATE ACCOUNT */}
        <Route path="/recover" component={RecoverPasswordPage} exact={true} />
        <Route path="/signup" component={RegistrationPage} exact={true} />
        <Route path="/login" component={LoginPage} exact={true} />
        <Route path="/" render={() => <Redirect to="/login" />} exact={true} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};
const PublicRoutes = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet className="containerApp">
        <Route path="/tabs" component={HomePage} />
        <Route path="/login" render={() => <Redirect to="/tabs/home" />} exact={true} />
        <Route path="/signup" render={() => <Redirect to="/tabs/home" />} exact={true} />
        <Route path="/" render={() => <Redirect to="/tabs/home" />} exact={true} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

const OnlyOneRoute = () => {
	return (
		<IonReactRouter>
			<Route path="/receivePayment" component={RecibirPago} />
		</IonReactRouter>
	);
}

const App = () => {
  const { store } = React.useContext(MobXProviderContext);

  useEffect(() => {
    
  }, [])

  return !store.authCheckComplete ? (
    <IonApp>
      <IonLoading message="Starting App..." />
    </IonApp>
  ) : (
    <IonApp>{window.location.href.includes('receivePayment') ? <OnlyOneRoute /> : (store.authenticatedUser ? <div className="containerApp"><PublicRoutes /></div> : <PrivateRoutes />)}</IonApp>
  );
};

export default observer(App);
