import React, { useState } from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonToast,
  IonText,
  IonPage,
  IonContent,
  IonImg,
  IonIcon
} from "@ionic/react";
import { useHistory } from "react-router";
import './../styles/login.css';
import { eyeOff, eye } from 'ionicons/icons';

import { observer, MobXProviderContext } from "mobx-react";

const LoginPage = () => {
  const { store } = React.useContext(MobXProviderContext);
  let { isAuth, initializationError } = store;
  const history = useHistory();
  const [email, setEmail] = useState("test@tes2t.com");
  const [password, setPassword] = useState("");
  const [errorInfo, setErrorInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  /**
   *
   */
  const _doLogin = async () => {
    try {
      let r = await store.doLogin(email, password);
      if (r.code) {
        throw r;
      }
	  setErrorInfo({});
	  window.location.href = '/tabs/home';
      // return history.push("/tabs/home");
    } catch (e) {
      setErrorInfo({ showErrorToast: true, errMsg: 'El usuario o contraseña ingresados son inválidos' });
      return false;
    }
  };

  return (
    <IonPage>
      <IonContent>
		<div className="nuevoContenedor">
			<div className="contenidoF">
				<div className="cuadrosLogin">
					<IonText color="danger" padding style={{ fontWeight: "500" }}>
						{initializationError && initializationError.message}
					</IonText>
					<div className="mostrarEnMovil">
						<IonImg className="imgLogo" src={'//micatalogovirtual.com/landing/wp-content/uploads/2020/08/mi-tienda-virtual-logo.png'} />
					</div>
					<IonText className="tituloLogin">
						Iniciar sesión
						<small><strong>¡Bienvenid@ a micatalogovirtual.com!</strong> Inicia sesión para comenzar</small>
					</IonText>

					<IonItem className="ionIt">
						<IonLabel position="floating">Email / Usuario</IonLabel>
						<IonInput
							type="email"
							onIonChange={(e) => {
							setEmail(e.detail.value);
							}}
							name="email"
						/>
					</IonItem>
					<IonItem className="ionIt">
						<IonLabel position="floating">Contraseña</IonLabel>
						<IonInput
							type={showPassword ? 'text' : 'password'}
							onIonChange={(e) => {
							setPassword(e.detail.value);
							}}
							name="password"
						/>
						<IonButton onClick={() => { setShowPassword(!showPassword) }} size="default" fill="clear" slot="end" ><IonIcon icon={showPassword ? eyeOff : eye}></IonIcon></IonButton>
					</IonItem>
					<div style={{ padding: 10, paddingTop: 20 }}>
						<IonButton
							expand="block"
							style={{ margin: 14 }}
							className="roundedLogin"
							onClick={(e) => {
							if (!e.currentTarget) {
								return;
							}
							e.preventDefault();
							_doLogin(history);
							}}
						>
							{isAuth ? "Conectado" : "Iniciar sesión"}
						</IonButton>
						<div className="linksBajos">
						<IonButton
								className="especialLink"
								fill="clear"
								size="small"
								mode="md"
								onClick={(e) => {
									e.preventDefault();
									history.push("/signup");
								}}
							>
								Registrarse ahora
							</IonButton>
							<IonButton
								fill="clear"
								size="small"
								mode="md"
								onClick={(e) => {
									e.preventDefault();
									history.push("/recover");
								}}
							>
								Recuperar contraseña
							</IonButton>
						</div>
					</div>
					<IonToast
						color="danger"
						isOpen={errorInfo.showErrorToast}
						onDidDismiss={() => setErrorInfo({ showErrorToast: false })}
						message={errorInfo.errMsg}
						duration={2000}
					/>
				</div>
				<div className="cuadrosLogin dos">
				</div>
			</div>
		</div>
      </IonContent>
    </IonPage>
  );
};

export default observer(LoginPage);
