import React from 'react';
import {useInView} from "react-intersection-observer";

const VirtualElements = (props) => {
    const [ref, inView] = useInView();
    const style = {
        minHeight: `${props.height}px`
    };
    return (
        <div className={props.className ? props.className : ''} style={style} ref={ref}>
            {inView ? props.children : null}
        </div>
    );
}

export default VirtualElements;