import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonTitle,
  IonModal,
  IonButton,
  IonToolbar,
  IonContent,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonLabel,
  IonRippleEffect,
  IonIcon,
  IonText,
  IonBackButton,
  IonButtons,
  IonTextarea
} from "@ionic/react";
// import { MobXProviderContext } from "mobx-react";
import { flagOutline, documentTextOutline, addCircleOutline } from 'ionicons/icons';
// import Countries from '../countries.json';

const NotasModal = ({ showModal, onDidDismiss, toEdit }) => {
	const [name, setName] = useState('');
	const [message, setMessage] =  useState('');
	const [errorName, setErrorName] = useState('');
	const [errorPhone, setErrorPhone] = useState('');
	
	useEffect(() => {
		console.log('fires', toEdit);
		if (toEdit !== null) {
			setName(toEdit.item.name);
			setMessage(toEdit.item.message);
		}
	}, [toEdit]);
	
	const handleSubmit = () => {
		let errores = false;
		if (name === '') {
			setErrorName('Este campo es obligatorio');
			errores = true;
		} else {
			setErrorName('');
		}
		if (message === '') {
			setErrorPhone('Este campo es obligatorio');
			errores = true;
		} else {
			setErrorPhone('');
		}
		
		if (!errores) {
			setName('');
			setMessage('');
			
			let ret = {
				name, message
			};
			if (toEdit !== null) {
				ret.id = toEdit.id;
			}
			onDidDismiss({ result: ret });
		}	
	}
	
	return (
    <IonModal mode="ios" isOpen={showModal} onDidDismiss={() => onDidDismiss()}>
		<IonHeader>
			<IonToolbar mode="ios">
				<IonButtons slot="start">
					<IonBackButton mode="ios" onClick={ () => {
						setName(''); setMessage(''); setErrorName(''); setErrorPhone(''); onDidDismiss();
					} } defaultHref="/tabs/notas" text="Volver"/>
				</IonButtons>
				<IonTitle mode="ios">{toEdit !== null ? 'Editar nota' : 'Nueva nota'}</IonTitle>
			</IonToolbar>
		</IonHeader>
      <IonContent>
        <IonItem className="itemPadding">
          <IonLabel position="floating">Asunto</IonLabel>
		  <IonInput
            type="text"
            onIonInput={(e) => setName(e.target.value)}
            name="nombrelinea"
			required={true}
			value={name}
          />
		  <IonIcon icon={flagOutline} slot="start" className="ion-align-self-end" />
        </IonItem>
		<IonText color="danger" className="ion-padding-start">
			<small>{errorName}</small>
        </IonText>
		
		<IonItem className="itemPadding">
			<IonLabel position="floating">Mensaje</IonLabel>
			<IonTextarea
				className="textBig"
				required={true}
				onIonInput={(e) => setMessage(e.target.value)}
				value={message}
			/>
			<IonIcon icon={documentTextOutline} slot="start" className="ion-align-self-start" />
        </IonItem>
		<IonText color="danger" className="ion-padding-start">
			<small>{errorPhone}</small>
        </IonText>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
                  expand="full"
				  className="botonLineal"
                  onClick={() => {
						handleSubmit()
                  }}
                >
				{toEdit !== null ? 'Modificar' : 'Agregar'} <IonIcon slot="end" icon={addCircleOutline} />
				  <IonRippleEffect type="unbounded"></IonRippleEffect>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </IonModal>
	);
};

export default NotasModal;
