import React, { useState, useRef, useEffect } from "react";
import {
  IonPage,
  IonButton,
  IonToolbar,
  IonContent,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonImg,
  IonLabel,
  IonRippleEffect,
  IonLoading,
  IonSelectOption,
  IonSelect
} from "@ionic/react";
import RetrieveImage from './../retrieveImg';
import { MobXProviderContext } from "mobx-react";
import { useParams, useHistory } from "react-router";
import Resizer from 'react-image-file-resizer';

const EditCategory = () => {
	const { store } = React.useContext(MobXProviderContext);
	const params = useParams();
	const history = useHistory();
	
	// let existingObj = null;
	const existingObj = store.categoryByKey(params.id);
	
	const [name, setName] = useState('');
	const [error, setError] = useState("");
	const [mensajeCargando, setMensajeCargando] = useState('');
	const [categoryimage, setCategoryimage] = useState(null);
	const [showLoading, setShowLoading] = useState(false);
	const [baseimage, setBaseimage] = useState('/img/uploadlogo.jpg');
	const fileUploader = useRef(null);
	const [categories, setCategories] = useState([]);
  const [parent, setParent] = useState('');

	// func1({name: existingObj.name});
	useEffect(() => {
		setName(existingObj.name);
		if (existingObj.hasOwnProperty('parent') && existingObj.parent !== '') {
			setParent(existingObj.parent);
		} else {
			setParent('');
		}
		setBaseimage((existingObj.hasOwnProperty('imagen_def') ? RetrieveImage(existingObj.imagen_def) : '/img/uploadlogo.jpg'));
	}, [params.id]); // eslint-disable-line react-hooks/exhaustive-deps
	// updateState({name: existingObj.name});
	
	const handleCategoryClick = () => {
		let element: HTMLElement = document.getElementById('categoryUploaderEdit');
		element.click();
	}
	
	const handleChangeFile = (e) => {
		let file = e.target.files[0];
		setCategoryimage(file);
		setBaseimage(URL.createObjectURL(file));
	}
	
	const resizeFile = (file, sizes) => new Promise(resolve => {
		Resizer.imageFileResizer(file, sizes.width, sizes.height, 'PNG', 100, 0,
		uri => {
		  resolve(uri);
		},
		'blob', sizes.width, sizes.height
		);
	});
	
	const handleEdit = async () => {
		setShowLoading(true);
		setMensajeCargando('Procesando datos...');
		let toSend = {name: name};
		if (parent !== '') {
			toSend.parent = parent;
		}
		if (categoryimage !== null) {
			setMensajeCargando('Procesando imagen...');
			// setLoadingText('Procesando imagen...');
			// return false;
			let urlImagenCategory = `categories/${store.activeUser.id}/${params.id}/category${Math.ceil(Math.random() * 100000000)}.png`;
			// console.log(urlImagenCategory);
			const finalFile = await resizeFile(categoryimage, {width: 120, height: 120});
			await store.uploadFile(urlImagenCategory, finalFile);
			toSend.imagen_def = urlImagenCategory;
		}
		
		setMensajeCargando('Guardando cambios...');
		await store.updateCategory(params.id, { ...toSend })
		setShowLoading(false);
		history.push('/tabs/home');
	}

	React.useEffect(() => {
		if (store.hasOwnProperty('itemCategories') && store.itemCategories) {
		  var cates = [];
		  Object.keys(store.itemCategories).forEach(cate => {
			var categoria = {...store.itemCategories[cate][1]};
			cates.push(categoria);
		  })
		  setCategories(cates);
		}
	  }, [store.itemCategories])
	
	return (
	<IonPage>
		<IonLoading
			cssClass='categoryLoader'
			isOpen={showLoading}
			message={mensajeCargando}
		/>
		
      <IonContent>
		<input type="file" id="categoryUploaderEdit" accept="image/*" onChange={handleChangeFile} className="uploaderInput" />
		<IonButton onClick={handleCategoryClick} mode="md"className="logoUploader notabsolute">
			<IonImg ref={fileUploader} src={baseimage} />
		</IonButton>
		<h3 className="titleSubseccion">Editar categoría</h3>
        <IonItem className="itemPadding">
          <IonLabel position="floating">Nombre categoría</IonLabel>
		  <IonInput
            type="text"
            onIonChange={(e) => { setName(e.detail.value); } }
            name="nombrecategoria"
			required={true}
			value={name}
          />
        </IonItem>
		{categories.length ? 
        <IonItem className="itemPadding">
          <IonLabel position="floating">Categoría padre</IonLabel>
		  <IonSelect mode="ios" value={parent} onIonChange={e => setParent(e.detail.value)}>
        {categories.filter(item => {
          return !item.hasOwnProperty('parent');
        }).map(item => 
          <IonSelectOption value={item.id}>{item.name}</IonSelectOption>
        )}
      </IonSelect>
        </IonItem>
        : null}
		<span className="errorMsg">{error}</span>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
				<IonCol>
                <IonButton
				  className="botonCerrar"
                  expand="full"
                  onClick={() => {
                    setCategoryimage(null);
                    setError("");
					setBaseimage('/img/uploadlogo.jpg');
					history.push('/tabs/home');
                  }}
                >
                  Cancelar
				  <IonRippleEffect type="unbounded"></IonRippleEffect>
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  expand="full"
				  className="botonLineal"
                  onClick={() => {
					if (name === '') {
						setError('Debe llenar/modificar el campo nombre');
						return false;
					} else {
						setError('');
					}
                    handleEdit();
                  }}
                >
				Guardar <IonImg src="/img/addmas.jpg" />
				  <IonRippleEffect type="unbounded"></IonRippleEffect>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
	</IonPage>
	);
};

export default EditCategory;
