import { observable, computed, action, decorate, runInAction } from "mobx";
import { get, set, entries, remove } from "mobx";
import * as firebaseService from "./firebaseService";

export class Store {
  constructor() {
    this.activeUser = null;
    this.loading = false;
    this.authCheckComplete = false;
    this.items = new Map();
	this.categories = new Map();
	this.products = new Map();
	this.pedidos = new Map();
	this.notas = new Map();
	this.facturas = new Map();
    this.initializationError = null;
	this.tienda = null;
	this.plan = null;
	this.planes = new Map();

	firebaseService.quickChanges();

    this.initializeStore().then((u) => {
      this.activeUser = u;
      this.authCheckComplete = true;
    });
  }

  /**
   * if we have an authenticated user then get all of the profile
   * information from the database and associate it with the active
   * user
   * @param {*} _authUser
   */
  handleAuthedUser = async (_authUser) => {
    if (_authUser) {
      let userAcctInfo = await firebaseService.getUserProfile();
	  let tiendaInfo = await firebaseService.getTiendaInfo();
	  
      this.activeUser = { ..._authUser, ...userAcctInfo };
	  this.tienda = { ...tiendaInfo };
      await this.loadCategories();
	  this.loadProducts();
	  this.loadPedidos();
	  this.loadNotas();
	  await this.loadFacturas();
	  await this.loadPlanInfo(userAcctInfo);
	  this.loadPlanes(userAcctInfo);
	  // await this.loadTienda();
    } else {
      this.activeUser = _authUser;
    }
    return this.activeUser;
  };

  /**
   * check to see if we have a user before starting up
   */
  async initializeStore() {
    return firebaseService
      .authCheck(this.handleAuthedUser)
      .then((_user) => {
        return _user;
      })
      .catch((e) => {
        return runInAction(() => {
          this.initializationError = e;
        });
      });
  }

  get doCheckAuth() {
    if (firebaseService.getCurrentUser()) {
      return this.activeUser;
    } else {
      return null;
    }
  }
  /**
   * here we check to see if ionic saved a user for us
   */
  get authenticatedUser() {
    return this.activeUser || null;
  }
  
  get infoTienda() {
	  return this.tienda || null;
  }
  
  get infoPlan() {
	  return this.plan || null;
  }

  /**
   * gets all of the items as an array from the map
   */
  get itemEntries() {
    return entries(this.items);
  }

  /**
   * get a specific item based on its key
   * @param {*} _key
   */
  itemByKey(_key) {
    return get(this.items, _key);
  }
  
  get itemCategories() {
	  // console.log(this.categories);
	  return entries(this.categories);
  }
  
  categoryByKey(_key) {
	  // console.log(this.categories);
	  return get(this.categories, _key);
  }
  
  get itemProducts() {
	  const productos = entries(this.products);
	  return productos.sort((a,b) => {
		  if (b[1].hasOwnProperty('order') && a[1].hasOwnProperty('order')) {
			  return a[1].order - b[1].order;
		  } else {
			return  b[1].created - a[1].created
		  }
	  });
  }
  
  productByKey(_key) {
	  return get(this.products, _key);
  }
  
  get itemPedidos() {
	  return entries(this.pedidos);
  }
  
  get itemPlanes() {
	  return entries(this.planes);
  }
  
  PedidoByKey(_key) {
	  return get(this.pedidos, _key);
  }
  
  get itemNotas() {
	  return entries(this.notas);
  }
  
  get itemFacturas () {
	  return entries(this.facturas);
  }
  
  notasByKey(_key) {
	  return get(this.notas, _key);
  }
  
  facturasByKey(_key) {
	  return get(this.facturas, _key);
  }

  /**
   * login using a username and password
   */
  doLogin(_username, _password) {
    if (_username.length) {
      return firebaseService
        .loginWithEmail(_username, _password)
        .then( async (_result) => {
            return _result;
          },
          (err) => {
            console.log(err);
            return err;
          }
        )
        .catch((e) => {
          console.log(e);
          return e;
        });
    }
  }

  async doRecover(email) {
	  try {
		let recoverAction = await firebaseService.recoverPassword(email);
		return recoverAction;
	  } catch (err) {
		  debugger;
		  console.log(err);
		  return err;
	  }
  }

  async changePassword(password) {
	try {
		let passwordAction = await firebaseService.changePassword(password);
		return passwordAction;
	} catch (err) {
		  debugger;
		  console.log(err);
		  return err;
	}
  }

  /**
   * create the user with the information and set the user object
   */
  async doCreateUser(_params) {
    try {
      let newUser = await firebaseService.registerUser(_params);
      return newUser;
    } catch (err) {
      debugger;
      console.log(err);
      return err;
      // for (let e of err.details) {
      //   if (e === "conflict_email") {
      //     alert("Email already exists.");
      //   } else {
      //     // handle other errors
      //   }
      // }
    }
  }

  /**
   * logout and remove the user...
   */
  doLogout() {
    this.activeUser = null;
    return firebaseService.logOut();
  }

  // DATA CRUD
  loadCategories() {
    return firebaseService
      .queryObjectCollectionByPosition({ collection: "categories" })
      .then(
        (_result) => {
          // create the user object based on the data retrieved...
          return runInAction(() => {
			  // console.log(_result);
            let resultMap = _result.reduce((map, obj) => {
			  // console.log(map, obj);
              map[obj.id] = obj;
              return map;
            }, {});
            this.categories = resultMap;
			// console.log(this.categories, _result, resultMap);
            return resultMap;
          });
        },
        (err) => {
          console.log(err);
          return err;
        }
      )
      .catch((e) => {
        console.log(e);
        return e;
      });
  }
  loadProducts() {
	return firebaseService
	.queryObjectCollection({ collection: "products" })
	.then(
        (_result) => {
          // create the user object based on the data retrieved...
          return runInAction(() => {
            let resultMap = _result.reduce((map, obj) => {
			  // console.log(map, obj);
              map[obj.id] = obj;
              return map;
            }, {});
			// console.log(resultMap);
            this.products = resultMap;
			// console.log(this.categories, _result, resultMap);
            return resultMap;
          });
        },
        (err) => {
          console.log(err);
          return err;
        }
	)
	.catch((e) => {
        console.log(e);
        return e;
	});
  }
  
  loadPedidos() {
	return firebaseService
	.queryObjectCollection({ collection: "orders" })
	.then(
        (_result) => {
          // create the user object based on the data retrieved...
          return runInAction(() => {
            let resultMap = _result.reduce((map, obj) => {
			  // console.log(map, obj);
              map[obj.id] = obj;
              return map;
            }, {});
			// console.log(resultMap);
            this.pedidos = resultMap;
			// console.log(this.categories, _result, resultMap);
            return resultMap;
          });
        },
        (err) => {
          console.log(err);
          return err;
        }
	)
	.catch((e) => {
        console.log(e);
        return e;
	});
  }
  
  loadNotas() {
	return firebaseService
	.queryObjectCollection({ collection: "notas" })
	.then(
        (_result) => {
          // create the user object based on the data retrieved...
          return runInAction(() => {
            let resultMap = _result.reduce((map, obj) => {
			  // console.log(map, obj);
              map[obj.id] = obj;
              return map;
            }, {});
			// console.log(resultMap);
            this.notas = resultMap;
			// console.log(this.categories, _result, resultMap);
            return resultMap;
          });
        },
        (err) => {
          console.log(err);
          return err;
        }
	)
	.catch((e) => {
        console.log(e);
        return e;
	});
  }
  
  loadFacturas() {
	return firebaseService
	.queryObjectCollection({ collection: "facturas" })
	.then(
        (_result) => {
          // create the user object based on the data retrieved...
          return runInAction(() => {
            let resultMap = _result.reduce((map, obj) => {
			  // console.log(map, obj);
              map[obj.id] = obj;
              return map;
            }, {});
			// console.log(resultMap);
            this.facturas = resultMap;
			// console.log(this.categories, _result, resultMap);
            return resultMap;
          });
        },
        (err) => {
          console.log(err);
          return err;
        }
	)
	.catch((e) => {
        console.log(e);
        return e;
	});
  }
  
  loadPlanInfo(userAcctInfo) {
	  const userInfo = {...userAcctInfo};
	  // console.log(userInfo);
	  return firebaseService
	  .queryPlan(userInfo)
	  .then(
		(_result) => {
			// const map = new Map(Object.entries(_result));
			this.plan = _result;
			return _result;
		},
		(err) => {
			console.log(err);
			return err;
		}
	  )
	  .catch((e) => {
		console.log(e);
		return e;
	  })
  }
  
  loadPlanes(userAcctInfo) {
	  const userInfo = {...userAcctInfo};
	  // console.log(userInfo);
	  return firebaseService
	  .queryPlanes(userInfo)
	  .then(
		(_result) => {
			return runInAction(() => {
				let resultMap = _result.reduce((map, obj) => {
				  // console.log(map, obj);
				  map[obj.id] = obj;
				  return map;
				}, {});
				// console.log(resultMap);
				this.planes = resultMap;
				// console.log(this.categories, _result, resultMap);
				return resultMap;
			});
		},
		(err) => {
			console.log(err);
			return err;
		}
	  )
	  .catch((e) => {
		console.log(e);
		return e;
	  })
  }
  
	checkIfStoreExists(store) {
		return firebaseService
			.checkIfStoreExists(store)
			.then(
				(_result) => {
					return runInAction(() => {
						return _result;
					});
				},
				(err) => {
					return err;
				}
			)
			.catch((e) => {
				return e;
			});
	}
	
	saveNewStore(store) {
		return firebaseService
			.saveNewStore(store)
			.then(
				(_result) => {
					return runInAction(() => {
						// console.log(this.tienda, _result);
						set(this.tienda, 'tienda', _result.tienda);
						return _result;
					});
				},
				(err) => {
					return err;
				}
			)
			.catch((e) => {
				return e;
			});
	}
  
  addItem(_data) {
    return firebaseService
      .addObjectToCollection({ collection: "items", objectData: _data })
      .then(
        (_result) => {
          // create the user object based on the data retrieved...
          return runInAction(() => {
            set(this.items, _result.id, _result);
            return _result;
          });
        },
        (err) => {
          console.log(err);
          return err;
        }
      )
      .catch((e) => {
        console.log(e);
        return e;
      });
  }
	addCategory(_data) {
		return firebaseService
		.addObjectToCollection({ collection: "categories", objectData: _data })
		.then(
			(_result) => {
			  // create the user object based on the data retrieved...
			  return runInAction(() => {
				set(this.categories, _result.id, _result);
				return _result;
			  });
			},
			(err) => {
			  console.log(err);
			  return err;
			}
		)
		.catch((e) => {
			console.log(e);
			return e;
		});
	}
	
	addProduct(_data) {
		return firebaseService
		.addObjectToCollection({ collection: "products", objectData: _data })
		.then(
			(_result) => {
			  // create the user object based on the data retrieved...
			  return runInAction(() => {
				set(this.products, _result.id, _result);
				return _result;
			  });
			},
			(err) => {
			  console.log(err);
			  return err;
			}
		)
		.catch((e) => {
			console.log(e);
			return e;
		});
	}
	
	addNota(_data) {
		return firebaseService
		.addObjectToCollection({ collection: "notas", objectData: _data })
		.then(
			(_result) => {
			  // create the user object based on the data retrieved...
			  return runInAction(() => {
				set(this.notas, _result.id, _result);
				return _result;
			  });
			},
			(err) => {
			  console.log(err);
			  return err;
			}
		)
		.catch((e) => {
			console.log(e);
			return e;
		});
	}
	
	updateCategory(idf, _data) {
		return firebaseService
		.setObjectToCollection({ collection: "categories", id: idf, objectData: _data })
		.then(
			(_result) => {
			  // create the user object based on the data retrieved...
			  return runInAction(() => {
				set(this.categories, _result.id, _result);
				return _result;
			  });
			},
			(err) => {
			  console.log(err);
			  return err;
			}
		)
		.catch((e) => {
			console.log(e);
			return e;
		});
	}
	
	updateProduct(idf, _data) {
		console.log(_data);
		return firebaseService
		.setObjectToCollection({ collection: "products", id: idf, objectData: _data })
		.then(
			(_result) => {
			  // create the user object based on the data retrieved...
			  return runInAction(() => {
				set(this.products, _result.id, _result);
				return _result;
			  });
			},
			(err) => {
			  console.log(err);
			  return err;
			}
		)
		.catch((e) => {
			console.log(e);
			return e;
		});
	}
	
	updateNota(idf, _data) {
		return firebaseService
		.setObjectToCollection({ collection: "notas", id: idf, objectData: _data })
		.then(
			(_result) => {
			  // create the user object based on the data retrieved...
			  return runInAction(() => {
				set(this.notas, _result.id, _result);
				return _result;
			  });
			},
			(err) => {
			  console.log(err);
			  return err;
			}
		)
		.catch((e) => {
			console.log(e);
			return e;
		});
	}

	deleteCategory(_id) {
		return firebaseService
		.removeObjectFromCollection({ collection: "categories", objectId: _id })
		.then(
			(_result) => {
			  // create the user object based on the data retrieved...
			  return runInAction(() => {
				remove(this.categories, _id);
				return true;
			  });
			},
			(err) => {
			  console.log(err);
			  return err;
			}
		)
		.catch((e) => {
			console.log(e);
			return e;
		});
	}
	
	deleteNota(_id) {
		return firebaseService
		.removeObjectFromCollection({ collection: "notas", objectId: _id })
		.then(
			(_result) => {
			  // create the user object based on the data retrieved...
			  return runInAction(() => {
				remove(this.notas, _id);
				return true;
			  });
			},
			(err) => {
			  console.log(err);
			  return err;
			}
		)
		.catch((e) => {
			console.log(e);
			return e;
		});
	}
	
	deleteProduct(_id) {
		return firebaseService
		.removeObjectFromCollection({ collection: "products", objectId: _id })
		.then(
			(_result) => {
			  // create the user object based on the data retrieved...
			  return runInAction(() => {
				remove(this.products, _id);
				return true;
			  });
			},
			(err) => {
			  console.log(err);
			  return err;
			}
		)
		.catch((e) => {
			console.log(e);
			return e;
		});
	}
  deleteItem(_data) {
    return firebaseService
      .removeObjectFromCollection({ collection: "items", objectId: _data.id })
      .then(
        (_result) => {
          // create the user object based on the data retrieved...
          return runInAction(() => {
            remove(this.items, _data.id);
            return true;
          });
        },
        (err) => {
          console.log(err);
          return err;
        }
      )
      .catch((e) => {
        console.log(e);
        return e;
      });
  }
  
  uploadFile(path, file) {
	return firebaseService
		.uploadFile(path, file)
		.then(
			(_result) => {
				return _result;
			},
			(err) => {
				console.log(err);
				return err;
			}
		).catch((e) => {
			console.log(e);
			return e;
		});
  }
  
  saveInClientProfile(register) {
	  return firebaseService
		.saveInClientProfile(register)
		.then(
			(_result) => {
				for (let k in register) {
					set(this.activeUser, k, register[k]);
				}
				return _result;
			},
			(err) => {
				console.log(err);
				return err;
			}
		).catch((e) => {
			console.log(e);
			return e;
		});
  }
}

decorate(Store, {
  // OBSERVABLES
  tienda: observable,
  plan: observable,
  planes: observable,
  activeUser: observable,
  loading: observable,
  authCheckComplete: observable,
  items: observable,
  categories: observable,
  products: observable,
  pedidos: observable,
  notas: observable,
  facturas: observable,
  initializationError: observable,

  // COMPUTED
  authenticatedUser: computed,
  doCheckAuth: computed,
  itemEntries: computed,
  itemProducts: computed,
  itemCategories: computed,
  itemNotas: computed,
  itemPlanes: computed,
  itemFacturas: computed,
  
  // ACTIONS
  doCreateUser: action,
  doLogin: action,
  doLogout: action,
  loadCategories: action,
  loadProducts: action,
  loadNotas: action,
  loadPlanes: action,
  loadFacturas: action,
  checkIfStoreExists: action,
  itemByKey: action,
  categoryByKey: action,
  productByKey: action,
  notasByKey: action,
  addItem: action,
  deleteItem: action,
});
