import React, { useState, useEffect, useRef } from "react";
import {
  IonModal,
  IonButton,
  IonToolbar,
  IonContent,
  IonItem,
  IonInput,
  IonTextarea,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonImg,
  IonLabel,
  IonSelectOption,
  IonSelect,
  IonIcon,
  IonRippleEffect,
  IonToast,
  IonAlert,
  IonLoading,
  IonHeader,
  IonList,
  IonItemDivider,
  IonCheckbox
} from "@ionic/react";
import { MobXProviderContext } from "mobx-react";
import { removeCircleOutline, addCircleOutline, closeOutline, flashOutline } from 'ionicons/icons';
import RetrieveImage from "../retrieveImg";
import { List, AutoSizer } from "react-virtualized";
import { v4 as uuidv4 } from 'uuid';


const ModalGrupoAdiciones = (props) => {
	const { abrir, onDidDismiss, onSuccess } = props;
	const [tituloGrupo, setTituloGrupo] = React.useState('');
	const [esObligatorio, setEsObligatorio] = React.useState(false);
	const [minimoPermitido, setMinimoPermitido] = React.useState(1);
	const [disableMinimoPermitido, setDisableMinimoPermitido] = React.useState(true);
	const [maximoPermitido, setMaximoPermitido] = React.useState(3);
	const [tipoGrupo, setTipoGrupo] = React.useState('');
	const [handleAlert, setHandleAlert] = React.useState({
		show: false,
		message: ''
	})

	const mostrarMensaje = (message) => {
		setHandleAlert({
			show: true,
			message
		})
	}

	const handleTipoGrupo = (e) => {
		setTipoGrupo(e.detail.value);
		setMinimoPermitido(e.detail.value === 'multiple' ? 2 : 1);
		setDisableMinimoPermitido(e.detail.value === 'singular' ? true : false);
		setMaximoPermitido(e.detail.value === 'singular' ? 1 : (e.detail.value === 'multiple' ? 2 : 3));
	}

	const handleMinimo = (e) => {
		var number = parseInt(e.target.value);
		if (number < 2 && tipoGrupo === 'multiple') {
			mostrarMensaje('El mínimo debe ser de 2 ya que ha elegido un grupo de opción múltiple');
			return false;
		}
		setMinimoPermitido(number);
		if (number > maximoPermitido) {
			setMaximoPermitido(number);
		}
	}

	const handleMaximo = (e) => {
		var number = parseInt(e.target.value);
		// console.log(number);
		if (number < minimoPermitido) {
			mostrarMensaje('El número máximo debe ser mayor o igual al mínimo permitido');
			setMaximoPermitido(minimoPermitido);
			return false;
		}

		setMaximoPermitido(number)
	}

	const resetAll = () => {
		setEsObligatorio(false);
		setMinimoPermitido(1);
		setDisableMinimoPermitido(true);
		setMaximoPermitido(3);
		setTipoGrupo('');
		setHandleAlert({
			show: false,
			message: ''
		})
	}

	const closeModal = () => {
		resetAll();
		onDidDismiss();
	}

	const submitModal = () => {
		if (tituloGrupo === '') {
			mostrarMensaje('Debe especificar un título para el producto');
			return false;
		}
		if (tipoGrupo === '') {
			mostrarMensaje('Debe especificar un tipo de grupo');
			return false;
		}

		if (!minimoPermitido) {
			mostrarMensaje('El mínimo especificado no es válido');
			return false;
		}

		if (!maximoPermitido) {
			mostrarMensaje('El máximo especificado no es válido');
			return false;
		}

		var data = {
			tituloGrupo,
			esObligatorio,
			minimoPermitido,
			maximoPermitido,
			tipoGrupo
		}
		resetAll();
		onSuccess(data);
	}

	return (
		<IonModal
		  mode="ios"
          isOpen={abrir}
          onDidDismiss={() => { onDidDismiss() }}
        >
			<IonHeader mode="ios" >
				<p className="ion-text-center">Agregar grupo de adiciones</p>
			</IonHeader>
			<IonContent>
				<IonAlert
					mode="ios"
					isOpen={handleAlert.show}
					header={'Error'}
					message={handleAlert.message}
					buttons={['OK']}
					onDidDismiss={() => { setHandleAlert({show: false, message: ''}) }}
				/>

				<IonList mode="ios">
					<IonItem lines="none" className="ion-padding-horizontal ion-no-border">
						<p><small>
							Un grupo será aquél que contenga las reglas de una adición, tal como la cantidad mínima y máxima a pedir, así como si es obligatorio o no.
						</small></p>
					</IonItem>
					<IonItem className="ion-padding-horizontal" mode="ios">
						<IonLabel position="floating">
							Título del grupo
						</IonLabel>
						<IonInput onIonInput={e => setTituloGrupo(e.target.value)} placeholder="Ej: Elige la base" />
					</IonItem>
					<IonItem className="ion-padding-horizontal" mode="ios">
						<IonLabel>
							¿Es obligatorio?
						</IonLabel>
						<IonCheckbox checked={esObligatorio} onIonChange={e => setEsObligatorio(e.detail.checked)} />
					</IonItem>
					<IonItem className="ion-padding-horizontal ion-margin-top" mode="ios">
						<IonLabel position="floating">
							Tipo de grupo
						</IonLabel>
						<IonSelect value={tipoGrupo} onIonChange={(e) => handleTipoGrupo(e)} placeholder="Debe elegir un tipo de grupo">
							<IonSelectOption value={'singular'}>Selección única</IonSelectOption>
							<IonSelectOption value={'multiple'}>Selección múltiple</IonSelectOption>
							<IonSelectOption value={'cantidad'}>Selección por cantidad</IonSelectOption>
						</IonSelect>
					</IonItem>
					<IonItem className="ion-padding-horizontal" mode="ios">
						<IonLabel position="floating">
							Cantidad mínima de opciones
						</IonLabel>
						<IonInput onIonInput={e => handleMinimo(e)} type="number" value={minimoPermitido} disabled={disableMinimoPermitido} />
					</IonItem>
					<IonItem className="ion-padding-horizontal" mode="ios">
						<IonLabel position="floating">
							Cantidad máxima de opciones
						</IonLabel>
						<IonInput onIonInput={e => handleMaximo(e)} type="number" min={minimoPermitido} value={maximoPermitido} disabled={disableMinimoPermitido} />
					</IonItem>
				</IonList>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonButton onClick={() => { closeModal() }} expand="full" className="botonCerrar">
									Cancelar
								</IonButton>
							</IonCol>
						  	<IonCol>
						    	<IonButton expand="full" className="botonLineal" onClick={() => { submitModal() }}>
									Guardar cambios
								</IonButton>
						  	</IonCol>
						</IonRow>
					</IonGrid>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	)
}

const ModalCrearAdiciones = (props) => {
	const { store } = React.useContext(MobXProviderContext);

	const { abrir, onDidDismiss, onSuccess } = props; 
	const [handleAlert, setHandleAlert] = React.useState({
		show: false,
		message: ''
	});
	const [nombreAdicion, setNombreAdicion] = React.useState('');
	const [grupoAdicion, setGrupoAdicion] = React.useState('');
	const [grupos, setGrupos] = React.useState({});


	React.useEffect(() => {
		if (abrir) {
			let gruposTemp = {};
			const user = {...store.activeUser};
			if (user.hasOwnProperty('gruposadiciones') && Object.keys(user.gruposadiciones).length) {
				console.log({...user.gruposadiciones});
				gruposTemp = {
					...user.gruposadiciones
				};
			}
			setGrupos({...gruposTemp});
		}
	}, [abrir])


	const mostrarMensaje = (message) => {
		setHandleAlert({
			show: true,
			message
		})
	}

	const resetAll = () => {
		setNombreAdicion('');
		setGrupoAdicion('');
		setGrupos({});
		setHandleAlert({
			show: false,
			message: ''
		})
	}

	const closeModal = () => {
		resetAll();
		onDidDismiss();
	}

	const submitModal = () => {
		if (nombreAdicion === '') {
			mostrarMensaje('Debe especificar al menos una adición');
			return false;
		}
		if (grupoAdicion === '') {
			mostrarMensaje('Debe especificar un grupo de adiciones');
			return false;
		}

		var finalAdiciones = [];
		var possibleAdiciones = nombreAdicion.split(',');
		possibleAdiciones.forEach(item => finalAdiciones.push(item.trim()));


		var data = {
			adiciones: finalAdiciones,
			grupoAdicion,
		}
		resetAll();
		onSuccess(data);
	}

	
	return (
		<IonModal
		  mode="ios"
          isOpen={abrir}
          onDidDismiss={() => { onDidDismiss() }}
        >
			<IonHeader mode="ios" >
				<p className="ion-text-center">Agregar una adición</p>
			</IonHeader>
			<IonContent>
				<IonAlert
					mode="ios"
					isOpen={handleAlert.show}
					header={'Error'}
					message={handleAlert.message}
					buttons={['OK']}
					onDidDismiss={() => { setHandleAlert({show: false, message: ''}) }}
				/>

				<IonList mode="ios">
					<IonItem lines="none" className="ion-padding-horizontal ion-no-border">
						<p><small>
							Una adición será aquél valor que el cliente podrá elegir dentro de un grupo. Puede ser desde "con servilletas" y "sin servilletas" hasta "Chocolate", "Fresa" y demás
						</small></p>
					</IonItem>
					<IonItem className="ion-padding-horizontal" mode="ios">
						<IonLabel position="floating">
							Grupo de adición
						</IonLabel>
						<IonSelect onIonChange={e => setGrupoAdicion(e.detail.value)} value={grupoAdicion} placeholder="Elegir grupo de adición">
							{Object.keys(grupos).length ? Object.values(grupos).map(item => (
									<IonSelectOption value={item._id} key={item._id}>{item.tituloGrupo}</IonSelectOption>
								) 
							) : null}
						</IonSelect>
					</IonItem>
					<IonItem className="ion-padding-horizontal" mode="ios">
						<IonLabel position="floating">
							Nombres de las adiciones
						</IonLabel>
						<IonInput onIonInput={e => setNombreAdicion(e.target.value)} type="text" value={nombreAdicion} />
					</IonItem>
					<IonItem lines="none" className="ion-padding-horizontal ion-no-border">
						<p><small>
							Para agregar varias adiciones al mismo grupo, puedes escribirlos separándolos por una coma (,)
						</small></p>
					</IonItem>
				</IonList>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonButton onClick={() => { closeModal() }} expand="full" className="botonCerrar">
									Cancelar
								</IonButton>
							</IonCol>
						  	<IonCol>
						    	<IonButton expand="full" className="botonLineal" onClick={() => { submitModal() }}>
									Guardar cambios
								</IonButton>
						  	</IonCol>
						</IonRow>
					</IonGrid>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	)
}

const ModalRemoverAdicionesYGrupos = (props) => {
	const { store } = React.useContext(MobXProviderContext);

	const { abrir, onDidDismiss, onSuccess } = props; 
	const [handleAlert, setHandleAlert] = React.useState({
		show: false,
		message: ''
	});
	const [grupos, setGrupos] = React.useState({});
	const [showAlertFinal, setShowAlertFinal] = React.useState(false);

	const [resultados, setResultados] = React.useState({});
	const [tiposSel, setTiposSel] = React.useState([]);

	React.useEffect(() => {
		if (abrir) {
			let gruposTemp = {};
			const user = {...store.activeUser};
			if (user.hasOwnProperty('gruposadiciones') && Object.keys(user.gruposadiciones).length) {
				console.log({...user.gruposadiciones});
				gruposTemp = {
					...user.gruposadiciones
				};
			}
			setGrupos({...gruposTemp});
		}
	}, [abrir])

	const handleEveryThing = (checked, grupo) => {
		const actualResultados = {...resultados};
		const actualTipos = [...tiposSel];
		if (checked) {
			actualResultados[grupo._id] = grupo.valores;
			if (!actualTipos.includes(grupo._id)) {
				actualTipos.push(grupo._id);
			}
		} else {
			if (actualResultados.hasOwnProperty(grupo._id)) {
				delete actualResultados[grupo._id];
			}
			if (actualTipos.includes(grupo._id)) {
				let indexof = actualTipos.indexOf(grupo._id);
				actualTipos.splice(indexof, 1);
			}
		}
		setTiposSel(actualTipos);
		setResultados(actualResultados);
	}

	const handleCheckbox = (checked, grupo, valor) => {
		const actualResultados = {...resultados};
		if (checked) {
			if (actualResultados.hasOwnProperty(grupo) && !actualResultados[grupo].includes(valor)) {
				actualResultados[grupo].push(valor);
				setResultados(actualResultados);
			} else if (!actualResultados.hasOwnProperty(grupo)) {
				actualResultados[grupo] = [valor];
				setResultados(actualResultados);
			}
		} else {
			if (actualResultados.hasOwnProperty(grupo)) {
				const filtro = actualResultados[grupo].filter(e => {
					return e.trim() !== valor.trim()
				});
				actualResultados[grupo] = filtro;
				setResultados(actualResultados);
			}
		}
	}

	const mostrarMensaje = (message) => {
		setHandleAlert({
			show: true,
			message
		})
	}

	const resetAll = () => {
		setGrupos({});
		setHandleAlert({
			show: false,
			message: ''
		})
	}

	const closeModal = () => {
		resetAll();
		onDidDismiss();
	}

	
	return (
		<IonModal
		  mode="ios"
          isOpen={abrir}
          onDidDismiss={() => { onDidDismiss() }}
        >
			<IonHeader mode="ios" >
				<p className="ion-text-center">Remover grupos o adiciones</p>
			</IonHeader>
			<IonContent>
				<IonAlert
					mode="ios"
					isOpen={handleAlert.show}
					header={'Error'}
					message={handleAlert.message}
					buttons={['OK']}
					onDidDismiss={() => { setHandleAlert({show: false, message: ''}) }}
				/>

				<IonAlert 
					mode="ios"
					isOpen={showAlertFinal}
					header={'Confirmación'}
					message={'¿Está seguro de eliminar los valores seleccionados?'} 
					buttons={[
						{
							text: 'Eliminar',
							handler: () => {
								const ret = {
									resultados: resultados,
									tiposSel: tiposSel
								}
								onSuccess(ret); setResultados({}); setTiposSel([]);
							}
						},
						{
							text: 'Cancelar',
							handler: () => {
								setResultados({}); setTiposSel([]); onDidDismiss();
							}
						}
					]}
					onDidDismiss={() => { setShowAlertFinal(false) }}
				/>

				<IonList mode="ios">
					<IonItem lines="none" className="ion-padding-horizontal ion-no-border">
						<p><small>
							Seleccione cuidadosamente si desea eliminar un grupo de adiciones o tan solo una adición en particular. Recuerde que una vez eliminado un registro, este no podrá recuperarse.
						</small></p>
					</IonItem>
				</IonList>
				{Object.keys(grupos).length ? Object.values(grupos).sort((a, b) => (a.tituloGrupo > b.tituloGrupo ? 1 : (a.tituloGrupo < b.tituloGrupo ? -1 : 0))).map(item => 
					<IonList key={'item_list_' + item._id}>
						<IonItemDivider style={{paddingLeft:'27px', paddingRight: '20px'}} class="capitalizeForTitlePurposes">
							{item.tituloGrupo}
							<IonCheckbox slot="end" onIonChange={e => { handleEveryThing(e.detail.checked, item) }} />
						</IonItemDivider>
						<IonGrid>
							<IonRow>
							{item.valores.map((kk) =>
								<IonCol size="6" key={'item_to_list_' + (item._id + kk.replace(''))}>
									<IonItem>
										<IonLabel>{kk}</IonLabel>
										<IonCheckbox slot="end"  onIonChange={e => { handleCheckbox(e.detail.checked, item._id, kk) }} checked={resultados.hasOwnProperty(item._id) && resultados[item._id].includes(kk)} color="primary" value={kk} />
									</IonItem>
								</IonCol>
							)}
							</IonRow>
						</IonGrid>
					</IonList>
				) : null}
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonButton onClick={() => { closeModal() }} expand="full" className="botonCerrar">
									Cancelar
								</IonButton>
							</IonCol>
						  	<IonCol>
						    	<IonButton expand="full" className="botonLineal" onClick={() => { setShowAlertFinal(true) }}>
									Remover
								</IonButton>
						  	</IonCol>
						</IonRow>
					</IonGrid>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	)
}

const ModalAdicionesProducto = (props) => {
	const { store } = React.useContext(MobXProviderContext);

	const { abrir, onDidDismiss, onSuccess } = props; 
	const [handleAlert, setHandleAlert] = React.useState({
		show: false,
		message: ''
	});
	const [grupos, setGrupos] = React.useState({});

	const [resultados, setResultados] = React.useState({});
	const [tiposSel, setTiposSel] = React.useState([]);

	React.useEffect(() => {
		if (abrir) {
			let gruposTemp = {};
			const user = {...store.activeUser};
			if (user.hasOwnProperty('gruposadiciones') && Object.keys(user.gruposadiciones).length) {
				gruposTemp = {
					...user.gruposadiciones
				};
			}
			setGrupos({...gruposTemp});
		}
	}, [abrir])

	const handleEveryThing = (checked, grupo) => {
		const actualResultados = {...resultados};
		const actualTipos = [...tiposSel];
		if (checked) {
			actualResultados[grupo._id] = grupo.valores;
			if (!actualTipos.includes(grupo._id)) {
				actualTipos.push(grupo._id);
			}
		} else {
			if (actualResultados.hasOwnProperty(grupo._id)) {
				delete actualResultados[grupo._id];
			}
			if (actualTipos.includes(grupo._id)) {
				let indexof = actualTipos.indexOf(grupo._id);
				actualTipos.splice(indexof, 1);
			}
		}
		setTiposSel(actualTipos);
		setResultados(actualResultados);
	}

	const handleCheckbox = (checked, grupo, valor) => {
		const actualResultados = {...resultados};
		if (checked) {
			if (actualResultados.hasOwnProperty(grupo) && !actualResultados[grupo].includes(valor)) {
				actualResultados[grupo].push(valor);
				setResultados(actualResultados);
			} else if (!actualResultados.hasOwnProperty(grupo)) {
				actualResultados[grupo] = [valor];
				setResultados(actualResultados);
			}
		} else {
			if (actualResultados.hasOwnProperty(grupo)) {
				const filtro = actualResultados[grupo].filter(e => {
					return e.trim() !== valor.trim()
				});
				actualResultados[grupo] = filtro;
				setResultados(actualResultados);
			}
		}
	}

	const mostrarMensaje = (message) => {
		setHandleAlert({
			show: true,
			message
		})
	}

	const resetAll = () => {
		setGrupos({});
		setHandleAlert({
			show: false,
			message: ''
		});
		setResultados({});
		setTiposSel([]);
	}

	const closeModal = () => {
		resetAll();
		onDidDismiss();
	}

	const submitModal = () => {
		if (!Object.keys(resultados).length) {
			setHandleAlert({
				show: true,
				message: 'Debe elegir al menos una adición'
			})
			return false;
		}

		const ret = {
			resultados: resultados,
			tiposSel: tiposSel
		}
		resetAll();
		onSuccess(ret);
	}

	
	return (
		<IonModal
		  mode="ios"
          isOpen={abrir}
          onDidDismiss={() => { onDidDismiss() }}
        >
			<IonHeader mode="ios" >
				<p className="ion-text-center">Agregar adiciones a tu producto</p>
			</IonHeader>
			<IonContent>
				<IonAlert
					mode="ios"
					isOpen={handleAlert.show}
					header={'Error'}
					message={handleAlert.message}
					buttons={['OK']}
					onDidDismiss={() => { setHandleAlert({show: false, message: ''}) }}
				/>

				<IonList mode="ios">
					<IonItem lines="none" className="ion-padding-horizontal ion-no-border">
						<p style={{width: '100%', textAlign: 'center'}}><small>
							Seleccione las adiciones que desea añadir a su producto.
						</small></p>
					</IonItem>
				</IonList>
				{Object.keys(grupos).length ? Object.values(grupos).sort((a, b) => (a.tituloGrupo > b.tituloGrupo ? 1 : (a.tituloGrupo < b.tituloGrupo ? -1 : 0))).map(item => 
					<IonList key={'item_list_' + item._id}>
						<IonItemDivider style={{paddingLeft:'27px', paddingRight: '20px'}} class="capitalizeForTitlePurposes">
							{item.tituloGrupo}
							<IonCheckbox slot="end" onIonChange={e => { handleEveryThing(e.detail.checked, item) }} />
						</IonItemDivider>
						<IonGrid>
							<IonRow>
							{item.valores.map((kk) =>
								<IonCol size="6" key={'item_to_list_' + (item._id + kk.replace(''))}>
									<IonItem>
										<IonLabel>{kk}</IonLabel>
										<IonCheckbox slot="end"  onIonChange={e => { handleCheckbox(e.detail.checked, item._id, kk) }} checked={resultados.hasOwnProperty(item._id) && resultados[item._id].includes(kk)} color="primary" value={kk} />
									</IonItem>
								</IonCol>
							)}
							</IonRow>
						</IonGrid>
					</IonList>
				) : null}
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonButton onClick={() => { closeModal() }} expand="full" className="botonCerrar">
									Cancelar
								</IonButton>
							</IonCol>
						  	<IonCol>
						    	<IonButton expand="full" className="botonLineal" onClick={() => { submitModal() }}>
									Guardar cambios
								</IonButton>
						  	</IonCol>
						</IonRow>
					</IonGrid>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	)
}

const AdicionesComponent = (props) => {
	const { store } = React.useContext(MobXProviderContext);
	const { adicionesActualizadas, prevAdiciones } = props;

	const [abrirModalCrearGrupo, setAbrirModalCrearGrupo] = React.useState(false);
	const [abrirModalCrearAdicion, setAbrirModalCrearAdicion] = React.useState(false);
	const [abrirModalBorrarAdicion, setAbrirModalBorrarAdicion] = React.useState(false);
	const [abrirModalAdicionesProducto, setAbrirModalAdicionesProducto] = React.useState(false);

	const [grupos, setGrupos] = React.useState({});
	const [gruposForProduct, setGruposForProduct] = React.useState([]);
	const [finalGrupos, setFinalGrupos] = React.useState([]);

	const [showLoading, setShowLoading] = React.useState({
		show: false,
		message: '',
	});
	const [showToast, setShowToast] = React.useState({
		show: false,
		message: ''
	});

	const [handleAlert, setHandleAlert] = React.useState({
		show: false,
		message: ''
	});

	React.useEffect(() => {
		let gruposTemp = {};
		const user = {...store.activeUser};
		if (user.hasOwnProperty('gruposadiciones') && Object.keys(user.gruposadiciones).length) {
			gruposTemp = {
				...user.gruposadiciones
			};
		}
		setGrupos({...gruposTemp});
	}, [])

	React.useEffect(() => {
		if (prevAdiciones.length) {
			setGruposForProduct(prevAdiciones);
			createFinalGrupo(prevAdiciones);
		}
	}, [prevAdiciones])

	const handleCrearGrupo = async (data) => {
		setAbrirModalCrearGrupo(false);
		setShowLoading({show: true, message: 'Registrando grupo de adiciones...'});

		const gruposActual = {...grupos};
		data.valores = [];
		data._id = uuidv4();
		gruposActual[data._id] = data;
		try {
			await store.saveInClientProfile({gruposadiciones: gruposActual});
			setGrupos(gruposActual);

			setShowLoading({
				show: false,
				message: ''
			});
		} catch (err) {
			console.log('ERR ', err);
			setShowLoading({
				show: false,
				message: ''
			});
			setHandleAlert({
				show: true,
				message: 'Ha ocurrido un error inesperado al intentar registrar el grupo de adiciones'
			})
		}
	}

	const handleCrearAdiciones = async (data) => {
		setAbrirModalCrearAdicion(false);
		setShowLoading({show: true, message: 'Registrando adiciones...'});

		const gruposActual = {...grupos};
		// console.log(gruposActual, data);
		if (gruposActual[data.grupoAdicion]) {
			gruposActual[data.grupoAdicion].valores = [
				...gruposActual[data.grupoAdicion].valores,
				...data.adiciones
			];
		}

		try {
			await store.saveInClientProfile({gruposadiciones: gruposActual});
			setGrupos(gruposActual);

			setShowLoading({
				show: false,
				message: ''
			});
		} catch (err) {
			console.log('ERR ', err);
			setShowLoading({
				show: false,
				message: ''
			});
			setHandleAlert({
				show: true,
				message: 'Ha ocurrido un error inesperado al intentar registrar las adiciones'
			})
		}
	}

	const handleRemover = async (result) => {
		setAbrirModalBorrarAdicion(false);
		setShowLoading({show: true, message: 'Removiendo adiciones...'});
		
		const currentAttributesAndValues = {...grupos}
		if (Object.keys(result.resultados).length) {
			for (let i in result.resultados) {
				const arrayOfValues = result.resultados[i];
				const currentArrayOfValues = [...currentAttributesAndValues[i].valores];
				// console.log(arrayOfValues, currentArrayOfValues);
				arrayOfValues.forEach(val => {
					const indexOf = currentArrayOfValues.indexOf(val);
					currentArrayOfValues.splice(indexOf, 1);
				})
				currentAttributesAndValues[i].valores = currentArrayOfValues;
			}
		}
		// console.log(result)
		if (result.tiposSel.length) {
			result.tiposSel.forEach(val => {
				if (currentAttributesAndValues.hasOwnProperty(val)) {
					delete currentAttributesAndValues[val];
				}
			})
		}
		setGrupos(currentAttributesAndValues);

		try {
			await store.saveInClientProfile({gruposadiciones: currentAttributesAndValues});

			setShowLoading({
				show: false,
				message: ''
			});
		} catch (err) {
			console.log('ERR ', err);
			setShowLoading({
				show: false,
				message: ''
			});
			setHandleAlert({
				show: true,
				message: 'Ha ocurrido un error inesperado al intentar registrar las adiciones'
			})
		}
	}

	const handleAgregarAdProducto = (data) => {
		setAbrirModalAdicionesProducto(false);
		setShowLoading({show: true, message: 'Agregando adiciones al producto...'});
		
		const currentGrupos = {...grupos};
		if (!Object.keys(data.resultados).length) {
			setHandleAlert({
				show: true,
				message: 'No ha especificado ninguna adición'
			})
			return false;
		}

		var gruposDeAdiciones = Object.keys(data.resultados).map(index => {
			var infoGrupo = {...currentGrupos[index]};
			var selectedAdiciones = data.resultados[index];
			
			var adicionesInfo = selectedAdiciones.map(item => {
				return {
					adicion: item,
					maxQuantity: 10000,
					extraPrice: 0
				}
			})

			delete infoGrupo.valores;
			infoGrupo.adiciones = adicionesInfo;
			return infoGrupo;
		})

		// console.log(gruposDeAdiciones)
		setGruposForProduct(gruposDeAdiciones);
		createFinalGrupo(gruposDeAdiciones);

		try {
			// await store.saveInClientProfile({gruposadiciones: currentAttributesAndValues});

			setShowLoading({
				show: false,
				message: ''
			});
		} catch (err) {
			console.log('ERR ', err);
			setShowLoading({
				show: false,
				message: ''
			});
			setHandleAlert({
				show: true,
				message: 'Ha ocurrido un error inesperado al intentar registrar las adiciones'
			})
		}
	}

	const createFinalGrupo = (gruposDeAdiciones) => {
		var tmpFinal = [];
		gruposDeAdiciones.forEach((item, index) => {
			item.adiciones.forEach((adi, ind) => {
				var obj = {
					indexPadre: index,
					indexPropio: ind,
					idPadre: item._id,
					tituloPadre: item.tituloGrupo,
					tituloPropio: adi.adicion,
					maxQuantity: adi.maxQuantity,
					extraPrice: adi.extraPrice
				}
				tmpFinal.push(obj);
			})
		})

		setFinalGrupos(tmpFinal);
		adicionesActualizadas(gruposDeAdiciones);
	}

	const handleCantidadAdicion = (value, index) => {
		const currentFinalGrupos = [...finalGrupos];
		const definedIndex = currentFinalGrupos[index];

		const currentGruposForProduct = [...gruposForProduct];
		var grupo = currentGruposForProduct[definedIndex.indexPadre];
		grupo.adiciones[definedIndex.indexPropio].maxQuantity = parseInt(value);
		
		currentGruposForProduct[definedIndex.indexPadre] = grupo;
		setGruposForProduct(currentGruposForProduct);
		createFinalGrupo(currentGruposForProduct);
	}

	const handlePrecioAdicion = (value, index) => {
		const currentFinalGrupos = [...finalGrupos];
		const definedIndex = currentFinalGrupos[index];

		const currentGruposForProduct = [...gruposForProduct];
		var grupo = currentGruposForProduct[definedIndex.indexPadre];
		grupo.adiciones[definedIndex.indexPropio].extraPrice = parseFloat(value);
		
		currentGruposForProduct[definedIndex.indexPadre] = grupo;
		setGruposForProduct(currentGruposForProduct);
		createFinalGrupo(currentGruposForProduct);
	}

	const eliminarAdicion = (index) => {
		const currentFinalGrupos = [...finalGrupos];
		const definedIndex = currentFinalGrupos[index];

		const currentGruposForProduct = [...gruposForProduct];
		var grupo = currentGruposForProduct[definedIndex.indexPadre];
		grupo.adiciones.splice(definedIndex.indexPropio, 1);
		
		currentGruposForProduct[definedIndex.indexPadre] = grupo;
		setGruposForProduct(currentGruposForProduct);
		createFinalGrupo(currentGruposForProduct);
	}

	const rowRenderer = ({
		key, // Unique key within array of rows
		index, // Index of row within collection
		isScrolling, // The List is currently being scrolled
		isVisible, // This row is visible within the List (eg it is not an overscanned row)
		style, // Style object to be applied to row (to position it)
	}) => {
		// return (
		//   <div key={key} style={style}>
		// 	{list[index]}
		//   </div>
		// );
		let value = finalGrupos[index];
		// console.log(key);
		var adicionesRegistradas = grupos[value.idPadre]?.valores;
		if (typeof adicionesRegistradas === 'undefined') {
			return (
				<div key={key} style={style} className="contenedorAtributos">
					<div className="itemTitleCombi" style={{display: 'block', textAlign:'left', maxWidth: 'none', marginRight: 15}}>
						{value.tituloPropio} <small style={{color: 'red', fontStyle: 'oblique'}}>Eliminado</small>
						<small style={{display:'block'}}>{value.tituloPadre}</small>
					</div>
				</div>
			)
		}
		var esta = adicionesRegistradas.find(it => {
			if (it === value.tituloPropio) {
				return it;
			}
		})
		if (typeof esta === 'undefined') {
			return (
			<div key={key} style={style} className="contenedorAtributos">
				<div className="itemTitleCombi" style={{display: 'block', textAlign:'left', maxWidth: 'none', marginRight: 15}}>
					{value.tituloPropio} <small style={{color: 'red', fontStyle: 'oblique'}}>Eliminado</small>
					<small style={{display:'block'}}>{value.tituloPadre}</small>
				</div>
			</div>
			)
		}
		return (
			<div key={key} style={style} className="contenedorAtributos">
				<div className="itemTitleCombi" style={{display: 'block', textAlign:'left', maxWidth: 'none', marginRight: 15}}>
					{value.tituloPropio}
					<small style={{display:'block'}}>{value.tituloPadre}</small>
				</div>
				<div className="inputQuantity">
					<IonInput
						className="inputSpeN"
						type="number"
						onIonInput={(e) => { handlePrecioAdicion(e.target.value, index) }}
						placeholder={'Extra $'}
						value={parseFloat(value.extraPrice)}
					/>
					<IonInput style={{width: '75px'}} type="number" name="quantity" value={value.maxQuantity} onIonInput={(e) => {handleCantidadAdicion(e.target.value, index)}} />
					<IonItem lines="none" className="menudito">
						<IonButton onClick={() => { eliminarAdicion(index); }}><IonIcon icon={closeOutline} /></IonButton>
					</IonItem>
				</div>
			</div>
		);


	}

	const renderQuantityFields = () => {
		let render = null;
		if (gruposForProduct.length) {
			render = (
				<div className="list joserivas">
					<AutoSizer disableHeight>
					{({ width }) => (
						<List
							width={width}
							height={370}
							rowCount={finalGrupos.length}
							rowHeight={59}
							rowRenderer={rowRenderer}
						/>
					)}
					</AutoSizer>
				</div>
			)
		}
		
		return render;
	}


	return (<>
		<IonToast
			isOpen={showToast.show}
			duration={3000}
			color="dark"
			onDidDismiss={() => setShowToast({show: false, message: ''})}
			message={showToast.message}
			position="bottom"
		/>
		<IonLoading
			cssClass='homepageloader'
			isOpen={showLoading.show}
			message={showLoading.message}
		/>
		<IonAlert
			mode="ios"
			isOpen={handleAlert.show}
			header={'Error'}
			message={handleAlert.message}
			buttons={['OK']}
			onDidDismiss={() => { setHandleAlert({show: false, message: ''}) }}
		/>

		<ModalGrupoAdiciones abrir={abrirModalCrearGrupo} onDidDismiss={() => { setAbrirModalCrearGrupo(false) }} onSuccess={(data) => { handleCrearGrupo(data) }} />
		<ModalCrearAdiciones abrir={abrirModalCrearAdicion} onDidDismiss={() => { setAbrirModalCrearAdicion(false) }} onSuccess={(data) => { handleCrearAdiciones(data) }} />
		<ModalRemoverAdicionesYGrupos abrir={abrirModalBorrarAdicion} onDidDismiss={() => { setAbrirModalBorrarAdicion(false) }} onSuccess={(data) => { handleRemover(data) }} />
		<ModalAdicionesProducto abrir={abrirModalAdicionesProducto} onDidDismiss={() => { setAbrirModalAdicionesProducto(false) }} onSuccess={(data) => { handleAgregarAdProducto(data) }} />
		

		<h3 className="titleSubseccion mt-5">Adiciones</h3>
		<p><small className="subinfo">Ahora podrás añadir adiciones para tus productos así como especificar reglas para tus productos a la hora de pedirlos.</small></p>
		<IonItem className="sinBorde itemPadding">
			<IonButton mode="md" className="inlineButton rect" onClick={() => setAbrirModalCrearGrupo(true) }>
				<IonIcon slot="icon-only" icon={addCircleOutline} /> 
				Crear grupos de adiciones
			</IonButton>
		</IonItem>
		<IonItem className="sinBorde itemPadding">
			<IonButton mode="md" className="inlineButton rect" onClick={() => setAbrirModalCrearAdicion(true) }>
				<IonIcon slot="icon-only" icon={addCircleOutline} /> 
				Registrar adiciones para un grupo
			</IonButton>
		</IonItem>
		<IonItem className="sinBorde itemPadding">
			<IonButton mode="md" className="inlineButton rect" onClick={() => setAbrirModalBorrarAdicion(true) }>
				<IonIcon slot="icon-only" icon={removeCircleOutline} /> 
				Eliminar adiciones para un grupo
			</IonButton>
		</IonItem>
		<IonItem className="sinBorde itemPadding">
			<IonButton mode="md" className="inlineButton rect" onClick={() => setAbrirModalAdicionesProducto(true) }>
				<IonIcon slot="icon-only" icon={flashOutline} />
				Agregar adiciones a este producto
			</IonButton>
		</IonItem>
		<IonItem className="sinBorde">
			<div id="realQtyCont2" style={{width: '100%', marginTop: 25}}>
				{renderQuantityFields()}
			</div>
		</IonItem>
	</>);
}

const ModalTipoAtributo = ({ abrir, onDidDismiss, onSuccess }) => {
	return (
		<IonAlert
		  mode="ios"
          isOpen={abrir}
          onDidDismiss={() => onDidDismiss()}
          header={'Agregar tipo de atributo'}
          inputs={[
            {
              name: 'tipoatributo',
              type: 'text',
			  placeholder: 'Escribe el tipo de atributo'
            }
          ]}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
				onDidDismiss();
              }
            },
            {
              text: 'Agregar',
              handler: (alertData) => {
				onSuccess(alertData );
              }
            }
          ]}
        />
	)
}

const ModalValorAtributo = ({ abrir, tipos, onDidDismiss, onSuccess }) => {
	const [tipoatributo, setTipoatributo] = useState('');
	const [valoratributo, setValoratributo] = useState('');
	const [showAlert, setShowAlert] = useState(false);

	return (
		<IonModal
		  mode="ios"
          isOpen={abrir}
          onDidDismiss={() => { setValoratributo(''); setTipoatributo(''); onDidDismiss() }}
        >
			<IonHeader mode="ios" >
				<p className="ion-text-center">Agregar valor a un tipo de atributo</p>
			</IonHeader>
			<IonContent>
				<IonAlert
					mode="ios"
					isOpen={showAlert}
					header={'Error'}
					message={'Debes llenar todos los campos del formulario para continuar'}
					buttons={['OK']}
					onDidDismiss={() => { setShowAlert(false) }}
				/>

				<IonList mode="ios">
					<IonItem className="ion-padding-horizontal" mode="ios">
						<IonLabel position="floating">
							Elegir el tipo de atributo
						</IonLabel>
						<IonSelect value={tipoatributo} onIonChange={e => { setTipoatributo(e.detail.value); }} placeholder="Debe elegir un tipo de atributo">
							{Object.keys(tipos).map((k, v) => {
								return (
									<IonSelectOption value={k} key={'attribute_val_k_' + k}>{k}</IonSelectOption>
								)
							})}
						</IonSelect>
					</IonItem>
					<IonItem className="ion-padding-horizontal ion-margin-top" mode="ios">
						<IonLabel position="floating">
							Agregar uno o varios valores
						</IonLabel>
						<IonInput onIonChange={e => { setValoratributo(e.detail.value) }} placeholder="Escribe aquí el valor/los valores" />
					</IonItem>
					<IonItem lines="none" className="ion-padding-horizontal ion-no-border">
						<p><small>
							Para agregar varios valores al mismo tipo de atributo, puedes escribirlos separándolos por una coma (,)
						</small></p>
					</IonItem>
				</IonList>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonButton onClick={() => { setValoratributo(''); setTipoatributo(''); onDidDismiss() }} expand="full" className="botonCerrar">
									Cancelar
								</IonButton>
							</IonCol>
						  	<IonCol>
						    	<IonButton expand="full" className="botonLineal" onClick={() => { if (valoratributo !== '' && tipoatributo !== '') { onSuccess({tipoatributo: tipoatributo, valoratributo: valoratributo}); setValoratributo(''); setTipoatributo(''); } else { setShowAlert(true); } }}>
									Guardar cambios
								</IonButton>
						  	</IonCol>
						</IonRow>
					</IonGrid>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	)
}

const ModalGeneradorDeCombinaciones = ({ abrir, tipos, onDidDismiss, onSuccess }) => {
	const [resultados, setResultados] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [showAlertFinal, setShowAlertFinal] = useState(false);
	
	const handleEveryThing = (checked, grupo, valores) => {
		const actualResultados = {...resultados};
		if (checked) {
			actualResultados[grupo] = valores;
		} else {
			if (actualResultados.hasOwnProperty(grupo)) {
				delete actualResultados[grupo];
			}
		}
		setResultados(actualResultados);
	}

	const handleCheckbox = (checked, grupo, valor) => {
		const actualResultados = {...resultados};
		if (checked) {
			if (actualResultados.hasOwnProperty(grupo) && !actualResultados[grupo].includes(valor)) {
				actualResultados[grupo].push(valor);
				setResultados(actualResultados);
			} else if (!actualResultados.hasOwnProperty(grupo)) {
				actualResultados[grupo] = [valor];
				setResultados(actualResultados);
			}
		} else {
			if (actualResultados.hasOwnProperty(grupo)) {
				const filtro = actualResultados[grupo].filter(e => {
					return e.trim() !== valor.trim()
				});
				actualResultados[grupo] = filtro;
				setResultados(actualResultados);
			}
		}
	}

	return (
		<IonModal
		  mode="ios"
          isOpen={abrir}
          onDidDismiss={() => { setResultados({}); onDidDismiss() }}
        >
			<IonHeader mode="ios" >
				<p className="ion-text-center">Generador de combinaciones</p>
			</IonHeader>
			<IonContent>
				<IonAlert
					mode="ios"
					isOpen={showAlert}
					header={'Error'}
					message={'Debes elegir al menos un atributo para generar combinaciones'}
					buttons={['OK']}
					onDidDismiss={() => { setShowAlert(false) }}
				/>

				<IonAlert 
					mode="ios"
					isOpen={showAlertFinal}
					header={'Stock minimo'}
					message={'Especifica si tus combinaciones cuentan con una cantidad mínima en stock. De no tenerlo, deja el campo en cero.'}
					buttons={[
						{
							text: 'Generar combinaciones',
							handler: (data) => {
								const ret = {
									resultados: resultados,
									quantity: data.quantity
								}
								onSuccess(ret); setResultados({})
							}
						}
					]}
					inputs={[
						{
							name: 'quantity',
							type: 'number',
							placeholder: 'Cantidad mínima',
							value: '0'
						}
					]}
					onDidDismiss={() => { setShowAlertFinal(false) }}
				/>

				{Object.keys(tipos).map((k) => {
					const valoresTipos = [...tipos[k]];
					return (
						<IonList key={'item_list_' + k}>
							<IonItemDivider style={{paddingLeft:'27px', paddingRight: '20px'}} class="capitalizeForTitlePurposes">
								{k}
								<IonCheckbox slot="end" onIonChange={e => { handleEveryThing(e.detail.checked, k, valoresTipos) }} />
							</IonItemDivider>
							<IonGrid>
								<IonRow>
								{valoresTipos.map((kk) => {
									return (
										<IonCol size="6" key={'item_to_list_' + kk}>
											<IonItem>
												<IonLabel>{kk}</IonLabel>
												<IonCheckbox slot="end" onIonChange={e => { handleCheckbox(e.detail.checked, k, kk) }} checked={resultados.hasOwnProperty(k) && resultados[k].includes(kk)} color="primary" value={kk} />
											</IonItem>
										</IonCol>
									);
								})}
								</IonRow>
							</IonGrid>
						</IonList>
					)
				})}
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonButton onClick={() => { setResultados({}); onDidDismiss() }} expand="full" className="botonCerrar">
									Cancelar
								</IonButton>
							</IonCol>
						  	<IonCol>
						    	<IonButton expand="full" className="botonLineal" onClick={() => { if (!Object.keys(resultados).length) { setShowAlert(true) } else { setShowAlertFinal(true) } }}>
									Generar
								</IonButton>
						  	</IonCol>
						</IonRow>
					</IonGrid>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	)
}


const ModalRemovedorDeCombinaciones = ({ abrir, tipos, onDidDismiss, onSuccess }) => {
	const [resultados, setResultados] = useState({});
	const [tiposSel, setTiposSel] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const [showAlertFinal, setShowAlertFinal] = useState(false);
	
	const handleEveryThing = (checked, grupo, valores) => {
		const actualResultados = {...resultados};
		const actualTipos = [...tiposSel];
		if (checked) {
			actualResultados[grupo] = valores;
			if (!actualTipos.includes(grupo)) {
				actualTipos.push(grupo);
			}
		} else {
			if (actualResultados.hasOwnProperty(grupo)) {
				delete actualResultados[grupo];
			}
			if (actualTipos.includes(grupo)) {
				let indexof = actualTipos.indexOf(grupo);
				actualTipos.splice(indexof, 1);
			}
		}
		setTiposSel(actualTipos);
		setResultados(actualResultados);
	}

	const handleCheckbox = (checked, grupo, valor) => {
		const actualResultados = {...resultados};
		if (checked) {
			if (actualResultados.hasOwnProperty(grupo) && !actualResultados[grupo].includes(valor)) {
				actualResultados[grupo].push(valor);
				setResultados(actualResultados);
			} else if (!actualResultados.hasOwnProperty(grupo)) {
				actualResultados[grupo] = [valor];
				setResultados(actualResultados);
			}
		} else {
			if (actualResultados.hasOwnProperty(grupo)) {
				const filtro = actualResultados[grupo].filter(e => {
					return e.trim() !== valor.trim()
				});
				actualResultados[grupo] = filtro;
				setResultados(actualResultados);
			}
		}
	}

	return (
		<IonModal
		  mode="ios"
          isOpen={abrir}
          onDidDismiss={() => { setResultados({}); setTiposSel([]); onDidDismiss() }}
        >
			<IonHeader mode="ios" >
				<p className="ion-text-center">Remover valores y tipos</p>
			</IonHeader>
			<IonContent>
				<IonAlert
					mode="ios"
					isOpen={showAlert}
					header={'Error'}
					message={'Debes elegir al menos un atributo para eliminar'}
					buttons={['OK']}
					onDidDismiss={() => { setShowAlert(false) }}
				/>

				<IonAlert 
					mode="ios"
					isOpen={showAlertFinal}
					header={'Confirmación'}
					message={'¿Está seguro de eliminar los valores seleccionados?'} 
					buttons={[
						{
							text: 'Eliminar',
							handler: () => {
								const ret = {
									resultados: resultados,
									tiposSel: tiposSel
								}
								onSuccess(ret); setResultados({}); setTiposSel([]);
							}
						},
						{
							text: 'Cancelar',
							handler: () => {
								setResultados({}); setTiposSel([]); onDidDismiss();
							}
						}
					]}
					onDidDismiss={() => { setShowAlertFinal(false) }}
				/>

				{Object.keys(tipos).map((k) => {
					const valoresTipos = [...tipos[k]];
					return (
						<IonList key={'item_list_' + k}>
							<IonItemDivider style={{paddingLeft:'27px', paddingRight: '20px'}} class="capitalizeForTitlePurposes">
								{k}
								<IonCheckbox slot="end" onIonChange={e => { handleEveryThing(e.detail.checked, k, valoresTipos) }} />
							</IonItemDivider>
							<IonGrid>
								<IonRow>
								{valoresTipos.map((kk) => {
									return (
										<IonCol size="6" key={'item_to_list_' + kk}>
											<IonItem>
												<IonLabel>{kk}</IonLabel>
												<IonCheckbox slot="end" onIonChange={e => { handleCheckbox(e.detail.checked, k, kk) }} checked={resultados.hasOwnProperty(k) && resultados[k].includes(kk)} color="primary" value={kk} />
											</IonItem>
										</IonCol>
									);
								})}
								</IonRow>
							</IonGrid>
						</IonList>
					)
				})}
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonButton onClick={() => { setResultados({}); setTiposSel([]); onDidDismiss() }} expand="full" className="botonCerrar">
									Cancelar
								</IonButton>
							</IonCol>
						  	<IonCol>
						    	<IonButton expand="full" className="botonLineal" onClick={() => { if (!Object.keys(resultados).length) { setShowAlert(true) } else { setShowAlertFinal(true) } }}>
									Eliminar selección
								</IonButton>
						  	</IonCol>
						</IonRow>
					</IonGrid>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	)
}

const AddItemModal2 = ({ showModal, onDidDismiss, toEdit }) => {
  const { store } = React.useContext(MobXProviderContext);
	
  const [showToast, setShowToast] = useState(false);
  const [name, setName] = useState("");
  const [reference, setReference] = useState("");
  const [originalPrice, setOriginalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [attributes, setAttributes] = useState({});
  const [combinations, setCombinations] = useState([]);
  const [adiciones, setAdiciones] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [mensajeLoading] = useState('Guardando información...');
  
  const [abrirModalTipoAtributo, setAbrirModalTipoAtributo] = useState(false);
  const [abrirModalValorAtributo, setAbrirModalValorAtributo] = useState(false);
  const [abrirModalCombinaciones, setAbrirModalCombinaciones] = useState(false);
  const [abrirModalRemover, setAbrirModalRemover] = useState(false);
  
  
  
  const [error, setError] = useState("");
  const [baseimage, setBaseimage] = useState('/img/uploadlogo.jpg');
  
  const [imagesPre, setImagesPre] = useState([]);
  
  const fileUploader = useRef(null);

	useEffect(() => {
		if (toEdit !== null) {
			const item = {...toEdit.item};
			fetch('https://micatalogovirtual.com/obtenerCombis.php?id=' + store.activeUser.id + '&id_product=' + toEdit.id).then(response => {
				response.json().then(data => {
					if (data && data.length) {
						setCombinations(data);
					}
				})
			}).catch(err => console.error(err))
			setName(item.name);
			setReference(item.reference);
			setOriginalPrice(item.originalPrice);
			setPrice(item.price);
			setDescription(item.description);
			setCategories(item.categories);
			if (item.hasOwnProperty('adiciones')) {
				setAdiciones(item.adiciones);
			}
			if (item.quantity) {
				setQuantity(item.quantity);
			}
			if (item.hasOwnProperty('cover')) {
				if (typeof item.cover === 'object') {
					setImagesPre([...item.cover]);
				} else {
					// El cover es solo un string con un link
				}
			}
		}
		
	}, [toEdit]);

	useEffect(() => {
		if (showModal) {
			let attributestodeliver = {};
			const user = {...store.activeUser};
			if (user.hasOwnProperty('combinaciones') && Object.keys(user.combinaciones).length) {
				attributestodeliver = {
					...user.combinaciones
				};
			}
			setAttributes({...attributestodeliver});
			
		}
	}, [showModal, store.activeUser])
	
	const handleCategoryClick = () => {
		let element: HTMLElement = document.getElementById('categoryUploader');
		element.click();
	}
	
	const handleChangeFile = (e) => {
		var nuevasFotos = Array.from(e.target.files);
		var actualesFotos = [...imagesPre];
		var actualizarArr = nuevasFotos.concat(actualesFotos);
		
		setImagesPre(actualizarArr);
		// let file = e.target.files[0];
		// setProductImage(file);
		// setBaseimage(URL.createObjectURL(file));
	}
	
	const removeImageIndex = (key) => {
		var array = [...imagesPre];
		array.splice(key, 1);
		setImagesPre(array);
	}
	
	const _renderImagesPre = () => {
		const images = [...imagesPre];
		return images.map((file, k) => {
			let baseImage;
			if (typeof file === 'string') {
				baseImage = RetrieveImage(file); 
			} else {
				baseImage = URL.createObjectURL(file);
			}
			return (
				<div className="contenedorImagenEditable" key={k}>
					<IonButton onClick={() => { removeImageIndex(k); }}><IonIcon icon={closeOutline} /></IonButton>
					<IonImg src={baseImage} />
				</div>
			);
		});
	}

	const rowRenderer = ({
		key, // Unique key within array of rows
		index, // Index of row within collection
		isScrolling, // The List is currently being scrolled
		isVisible, // This row is visible within the List (eg it is not an overscanned row)
		style, // Style object to be applied to row (to position it)
	  }) => {
		// return (
		//   <div key={key} style={style}>
		// 	{list[index]}
		//   </div>
		// );
		let nombreCombi = [];
		let value = combinations[index];

		value.data.forEach((val) => {
			if (val.attribute_group === 'tallas') {
				nombreCombi.push('Talla ' + val.value)
			} else {
				nombreCombi.push(val.value);
			}
		});
		let finalNombre = nombreCombi.join(' - ');
		return (
			<div key={key} style={style} className="contenedorAtributos">
				<div className="itemTitleCombi">
					{finalNombre}
				</div>
				<div className="inputQuantity">
					<IonInput
						className="inputSpeN"
						type="number"
						onIonInput={(e) => { handlePriceComb(e.target.value, index) }}
						placeholder={'Precio'}
						value={parseInt(value.price)}
					/>
					<IonInput type="number" name="quantity" value={parseInt(value.quantity)} onIonInput={(e) => {handleQuantityComb(e.target.value, index)}} />
					<IonItem lines="none" className="menudito">
						<IonButton onClick={() => { eliminarCombinacion(index); }}><IonIcon icon={closeOutline} /></IonButton>
					</IonItem>
				</div>
			</div>
		);


	  }
	
	const renderQuantityFields = () => {
		let render;
		if (combinations.length) {
			render = (
				<div className="list joserivas">
					<AutoSizer disableHeight>
					{({ width }) => (
						<List
							width={width}
							height={370}
							rowCount={combinations.length}
							rowHeight={59}
							rowRenderer={rowRenderer}
						/>
					)}
					</AutoSizer>
				</div>
			)
		} else {
			render = (
				<div className="contenedorAtributos">
					<div className="itemTitleCombi">
						Cantidad total
					</div>
					<div className="inputQuantity">
						<IonInput type="number" name="quantity" value={quantity} onIonInput={(e) => {handleQuantity(e.target.value)}} />
					</div>
				</div>
			)
		}
		
		return render;
	}
	
	const handleQuantity = (qty) => {
		let totalQuantity = parseInt(qty);
		setQuantity(totalQuantity);
	}
	
	const handleQuantityComb = async (qty, index) => {
		// console.log(qty, quantity);
		const combis = [...combinations];
		let totalQuantity = parseInt(quantity) + parseInt(qty);
		
		setQuantity(totalQuantity);
		let objeto = {...combis[index]};
		objeto.quantity = qty;
		// console.log(objeto);
		combis[index] = objeto;
		// console.log(combis);
		// combis[index].set('quantity', qty);
		await setCombinations(combis);
		// console.log(combinations[index]);
	}

	const handlePriceComb = async (price, index) => {
		const combis = [...combinations];
		let objeto = {...combis[index]};
		objeto.price = price;
		// console.log(objeto);
		combis[index] = objeto;
		// console.log(combis);
		// combis[index].set('quantity', qty);
		await setCombinations(combis);
	}
	
	const eliminarCombinacion = async (index) => {
		const combis = [...combinations];
		// console.log(combis);
		// delete combis[index];
		combis.splice(index, 1);
		// console.log(combis);
		await setCombinations(combis);  
	}
		
	const mostrarMensajeError = (message) => {
		setError(message);
		setShowToast(true);
	}
	
	const handleAgregar = () => {
		// console.log(combinations)
		// return false;
		if (name === '') {
			mostrarMensajeError('Debe llenar el campo nombre');
			return false;
		} else if (reference === '') {
			mostrarMensajeError('Debe llenar el campo referencia');
			return false;
		} else if (originalPrice === '') {
			mostrarMensajeError('Debe llenar el campo precio');
			return false;
		} else if (price === '') {
			mostrarMensajeError('El campo precio con descuento no puede estar vacío');
			return false;
		}
		
		let returnValues = {
			name, reference, originalPrice, price, description, categories, combinations, adiciones, quantity, imagesPre
		};
		if (toEdit !== null) {
			returnValues.id = toEdit.id;
		}
		
		onDidDismiss({ result: returnValues });
		setName('');
		setReference('');
		setOriginalPrice(0);
		setPrice(0);
		setDescription('');
		setCategories(null);
		setQuantity(0);
		setCombinations([]);
		setImagesPre([]);
		setError("");
		setBaseimage('/img/uploadlogo.jpg');
	}
	
	// console.log(attributes);
	
	// let values = store.categoryByKey(toEdit);
	// console.log(values);

	const handleModalTipoAtributo = async (result) => {
		setShowLoading(true);

		const actualAttributes = {...attributes};
		actualAttributes[result.tipoatributo] = [];
		setAttributes(actualAttributes);
		const definitiveAttributes = {...actualAttributes};

		await store.saveInClientProfile({combinaciones: definitiveAttributes});

		setShowLoading(false);
	}

	const handleModalValorAtributo = async (result) => {
		setAbrirModalValorAtributo(false);
		setShowLoading(true);

		const actualAttributes = {...attributes};
		const valoresN = result.valoratributo.split(',').filter(el => { return el !== null && el.trim() !== '' }).map(item => { return item.toLowerCase().trim() });
		actualAttributes[result.tipoatributo] = actualAttributes[result.tipoatributo].concat(valoresN);
		setAttributes(actualAttributes);
		const definitiveAttributes = {...actualAttributes};
		
		await store.saveInClientProfile({combinaciones: definitiveAttributes});

		setShowLoading(false);
	}

	const handleCombinaciones = async (result) => {
		let getProducts = (arrays) => {
			if (arrays.length === 0) {
				return [[]];
			}
		
			let results = [];
		
			getProducts(arrays.slice(1)).forEach((product) => {
				arrays[0].forEach((value) => {
					results.push([value].concat(product));
				});
			});
		
			return results;
		};
		
		let getAllCombinations = (attributes) => {
			let attributeNames = Object.keys(attributes);
		
			let attributeValues = attributeNames.map((name) => attributes[name]);
		
			return getProducts(attributeValues).map((product) => {
				let obj = {};
				attributeNames.forEach((name, i) => {
					obj[name] = product[i];
				});
				return obj;
			});
		};
		const combinacionesgeneradas = getAllCombinations(result.resultados);
		
		const currentCombinations = [];

		combinacionesgeneradas.map((e) => {
			const values = [];
			Object.keys(e).map(attribute_group => {
				values.push({
					attribute_group: attribute_group,
					value: e[attribute_group]
				})
				return values;
			})
			currentCombinations.push({
				data: values,
				quantity: parseInt(result.quantity)
			});
			return currentCombinations;
		})
		setCombinations(currentCombinations);
	}

	const handleCombinacionesParaRemover = async (result) => {
		setShowLoading(true);

		const currentAttributesAndValues = {...attributes}
		if (Object.keys(result.resultados).length) {
			for (let i in result.resultados) {
				const arrayOfValues = result.resultados[i];
				const currentArrayOfValues = [...currentAttributesAndValues[i]];
				arrayOfValues.map(val => {
					const indexOf = currentArrayOfValues.indexOf(val);
					currentArrayOfValues.splice(indexOf, 1);
				})
				currentAttributesAndValues[i] = currentArrayOfValues;
			}
		}

		if (result.tiposSel.length) {
			result.tiposSel.map(val => {
				if (currentAttributesAndValues.hasOwnProperty(val)) {
					delete currentAttributesAndValues[val];
				}
			})
		}
		setAttributes(currentAttributesAndValues);
		const definitiveAttributes = {...currentAttributesAndValues};
		await store.saveInClientProfile({combinaciones: definitiveAttributes});
		setShowLoading(false);
	}
	
	return (
    <IonModal mode="ios" isOpen={showModal} onDidDismiss={() => onDidDismiss()}>
		<IonToast
        isOpen={showToast}
		duration={3000}
		color="dark"
        onDidDismiss={() => setShowToast(false)}
        message={error}
        position="bottom"
      />
	  <ModalTipoAtributo abrir={abrirModalTipoAtributo} onDidDismiss={() => { setAbrirModalTipoAtributo(false) }} onSuccess={(result) => { handleModalTipoAtributo(result) }} />
	  <ModalValorAtributo abrir={abrirModalValorAtributo} tipos={attributes} onDidDismiss={() => { setAbrirModalValorAtributo(false) }} onSuccess={(result) => { handleModalValorAtributo(result) }} />
	  <ModalRemovedorDeCombinaciones abrir={abrirModalRemover} tipos={attributes} onDidDismiss={() => { setAbrirModalRemover(false) } } onSuccess={(result) => { setAbrirModalRemover(false); handleCombinacionesParaRemover(result) }} />
	  <ModalGeneradorDeCombinaciones abrir={abrirModalCombinaciones} tipos={attributes} onDidDismiss={() => { setAbrirModalCombinaciones(false) } } onSuccess={(result) => { setAbrirModalCombinaciones(false); handleCombinaciones(result) }} />
	  
	
	  <IonLoading
		cssClass='homepageloader'
		isOpen={showLoading}
		message={mensajeLoading}
	  />
	  
      <IonContent>
		<div className="contenedorImages">
			<input type="file" id="categoryUploader" accept="image/*" multiple onChange={handleChangeFile} className="uploaderInput" />
			<IonButton onClick={handleCategoryClick} mode="md"className="logoUploader notabsolute square">
				<IonImg ref={fileUploader} src={baseimage} />
			</IonButton>
			{_renderImagesPre()}
		</div>
		<h3 className="titleSubseccion">Personalizar producto</h3>
        <IonItem className="itemPadding">
          <IonLabel position="floating">Nombre producto</IonLabel>
		  <IonInput
            type="text"
            onIonChange={(e) => setName(e.detail.value)}
            name="nombrecategoria"
			required={true}
			value={name}
          />
        </IonItem>
		<IonItem className="itemPadding">
          <IonLabel position="floating">Referencia</IonLabel>
		  <IonInput
            type="text"
            onIonChange={(e) => setReference(e.detail.value)}
            name="reference"
			required={true}
			value={reference}
          />
        </IonItem>
		<IonItem className="itemPadding">
          <IonLabel position="floating">Precio</IonLabel>
		  <IonInput
            type="number"
            onIonChange={(e) => {setOriginalPrice(e.detail.value); setPrice(e.detail.value);}}
            name="original_price"
			required={true}
			value={originalPrice}
          />
        </IonItem>
		<IonItem className="itemPadding">
          <IonLabel position="floating">Precio con descuento</IonLabel>
		  <IonInput
            type="number"
            onIonChange={(e) => setPrice(e.detail.value)}
            name="price"
			required={true}
			value={price}
          />
        </IonItem>
		<IonItem className="itemPadding">
          <IonLabel position="floating">Descripción</IonLabel>
		  <IonTextarea
			clearOnEdit={false}
            onIonChange={(e) => setDescription(e.detail.value)}
            name="description"
			value={description}
          />
        </IonItem>
		<IonItem className="itemPadding">
          <IonLabel position="floating">Categorías</IonLabel>
		  <IonSelect name="categories" required={true} multiple={true} value={categories} onIonChange={(e) => {setCategories(e.detail.value)}}>
				{store.itemCategories.filter(([key, value]) => !value.hasOwnProperty('parent')).map(([key, value]) => (<span key={value.id}>
					<IonSelectOption value={value.name}>
						{value.name}
					</IonSelectOption>
					{store.itemCategories.filter(([k, v]) => v.hasOwnProperty('parent') && v.parent === value.id).length ? (
						<>
						{store.itemCategories.filter(([k, v]) => v.hasOwnProperty('parent') && v.parent === value.id).map(([k,v]) => 
							<IonSelectOption key={'subcate' + v.id} value={v.name}>
								-- {v.name}
							</IonSelectOption>
						)}
						</>
					) : null}
				</span>))}
		  </IonSelect>
        </IonItem>

		<AdicionesComponent prevAdiciones={adiciones} adicionesActualizadas={(data) => { setAdiciones(data) }} />
		
		<h3 className="titleSubseccion mt-5">Combinaciones</h3>
		<IonItem className="sinBorde itemPadding">
			<IonButton mode="md" className="inlineButton rect" onClick={() => setAbrirModalTipoAtributo(true) }>
				<IonIcon slot="icon-only" icon={addCircleOutline} /> 
				Agregar tipos de atributos
			</IonButton>
		</IonItem>
		<IonItem className="sinBorde itemPadding">
			<IonButton mode="md" className="inlineButton rect" onClick={() => setAbrirModalValorAtributo(true) }>
				<IonIcon slot="icon-only" icon={addCircleOutline} /> 
				Agregar valores de atributos 
			</IonButton>
		</IonItem>
		<IonItem className="sinBorde itemPadding">
			<IonButton mode="md" className="inlineButton rect" onClick={() => setAbrirModalRemover(true) }>
				<IonIcon slot="icon-only" icon={removeCircleOutline} /> 
				Remover valores y tipos de atributos
			</IonButton>
		</IonItem>
		<IonItem className="sinBorde itemPadding">
			<IonButton mode="md" className="inlineButton rect" onClick={() => setAbrirModalCombinaciones(true) }>
				<IonIcon slot="icon-only" icon={flashOutline} />
				Generar combinaciones
			</IonButton>
		</IonItem>

		<h3 className="titleSubseccion mt-5">Cantidades y Precios</h3>
		<IonItem className="sinBorde">
			<div id="realQtyCont">
				{renderQuantityFields()}
			</div>
		</IonItem>
		
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
				  className="botonCerrar"
                  expand="full"
                  onClick={() => {
					setName('');
					setReference('');
					setOriginalPrice(0);
					setPrice(0);
					setDescription('');
					setCategories(null);
					setQuantity(0);
					setCombinations([]);
					setError("");
					setAdiciones([]);
					setBaseimage('/img/uploadlogo.jpg');
					setImagesPre([]);
                    onDidDismiss();
                  }}
                >
                  Cancelar
				  <IonRippleEffect type="unbounded"></IonRippleEffect>
                </IonButton>
              </IonCol>
			  <IonCol>
                <IonButton
                  expand="full"
				  className="botonLineal"
                  onClick={() => {
					handleAgregar()
                  }}
                >
				Guardar cambios <IonImg src="/img/addmas.jpg" />
				  <IonRippleEffect type="unbounded"></IonRippleEffect>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </IonModal>
	);
};

export default AddItemModal2;
