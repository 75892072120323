import React, { useState, useRef } from "react";
import {
  IonModal,
  IonButton,
  IonToolbar,
  IonContent,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonImg,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonRippleEffect 
} from "@ionic/react";
import { MobXProviderContext } from "mobx-react";

const AddItemModal2 = ({ showModal, onDidDismiss }) => {
	const { store } = React.useContext(MobXProviderContext);
	
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [categoryimage, setCategoryimage] = useState(null);
  const [baseimage, setBaseimage] = useState('/img/uploadlogo.jpg');
  const [categories, setCategories] = useState([]);
  const [parent, setParent] = useState('');
  
  const fileUploader = useRef(null);

	const handleCategoryClick = () => {
		let element: HTMLElement = document.getElementById('categoryUploader');
		element.click();
	}
	
	const handleChangeFile = (e) => {
		let file = e.target.files[0];
		setCategoryimage(file);
		setBaseimage(URL.createObjectURL(file));
	}

  React.useEffect(() => {
    if (store.hasOwnProperty('itemCategories') && store.itemCategories) {
      var cates = [];
      Object.keys(store.itemCategories).forEach(cate => {
        var categoria = {...store.itemCategories[cate][1]};
        cates.push(categoria);
      })
      setCategories(cates);
    }
  }, [store.itemCategories])
	
	// let values = store.categoryByKey(toEdit);
	// console.log(values);
	
	return (
    <IonModal mode="ios" isOpen={showModal} onDidDismiss={() => onDidDismiss()}>
      <IonContent>
		<input type="file" id="categoryUploader" accept="image/*" onChange={handleChangeFile} className="uploaderInput" />
		<IonButton onClick={handleCategoryClick} mode="md"className="logoUploader notabsolute">
			<IonImg ref={fileUploader} src={baseimage} />
		</IonButton>
		<h3 className="titleSubseccion">Nueva categoría</h3>
    <IonItem className="itemPadding">
          <IonLabel position="floating">Nombre categoría</IonLabel>
		  <IonInput
            type="text"
            onIonChange={(e) => setName(e.detail.value)}
            name="nombrecategoria"
			required={true}
          />
        </IonItem>
        {categories.length ? 
        <IonItem className="itemPadding">
          <IonLabel position="floating">Categoría padre</IonLabel>
		  <IonSelect value={parent} onIonChange={e => setParent(e.detail.value)}>
        {categories.filter(item => {
          return !item.hasOwnProperty('parent');
        }).map(item => 
          <IonSelectOption key={item.id} value={item.id}>{item.name}</IonSelectOption>
        )}
      </IonSelect>
        </IonItem>
        : null}
		<span className="errorMsg">{error}</span>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
				  className="botonCerrar"
                  expand="full"
                  onClick={() => {
                    setName("");
                    setCategoryimage(null);
                    setError("");
                    setParent('');
					setBaseimage('/img/uploadlogo.jpg');
                    onDidDismiss();
                  }}
                >
                  Cancelar
				  <IonRippleEffect type="unbounded"></IonRippleEffect>
                </IonButton>
              </IonCol>
			  <IonCol>
                <IonButton
                  expand="full"
				  className="botonLineal"
                  onClick={() => {
					if (name === '') {
						setError('Debe llenar el campo nombre');
						return false;
					} else {
						setError('');
					}
                    let returnValues = {
                      name,
                      parent,
                      categoryimage
                    };
                    onDidDismiss({ result: returnValues });
                    setName("");
                    setParent('');
                    setCategoryimage(null);
                    setError("");
					setBaseimage('/img/uploadlogo.jpg');
                  }}
                >
                  Agregar <IonImg src="/img/addmas.jpg" />
				  <IonRippleEffect type="unbounded"></IonRippleEffect>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </IonModal>
	);
};

export default AddItemModal2;
