import { Route, Redirect } from "react-router";
import React from "react";

import TabOnePage from "../pages/TabOnePage";
import TabTwoPage from "../pages/TabTwoPage";
import EditCategory from "../pages/EditarCategoria";
import Lineas from "../pages/Lineas";
import Notas from "../pages/Notas";
import Pedidos from "../pages/Pedidos";
import Productos from "../pages/Productos";
import Facturacion from "../pages/Facturacion";

import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonRouterOutlet,
  IonIcon
} from "@ionic/react";

import { homeOutline, settingsOutline, logoWhatsapp, cardOutline } from 'ionicons/icons';
// MOBX
import { observer } from "mobx-react";
import TabOneDetailPage from "./TabOneDetailPage";

const HomePage = () => {
	return (
    <IonTabs>
      <IonRouterOutlet>
	  	<Route path="/tabs/home/category/:id" exact={true}>
			<EditCategory />
        </Route>
		
		<Route path="/tabs/home">
          <TabOnePage />
        </Route>

        <Route path="/tabs/tab1-detail/:id" exact={true}>
          <TabOneDetailPage />
        </Route>

		<Route path="/tabs/lineas/" exact={true}>
			<Lineas />
		</Route>
		
		<Route path="/tabs/productos/" exact={true}>
			<Productos />
		</Route>
		
		<Route path="/tabs/notas/" exact={true}>
			<Notas />
		</Route>
		
		<Route path="/tabs/facturacion/" exact={true}>
			<Facturacion />
		</Route>
		
		<Route path="/tabs/pedidos/" exact={true}>
			<Pedidos />
		</Route>
		
        <Route path="/tabs/configuracion" exact={true}>
          <TabTwoPage />
        </Route>
        <Route path="/tabs" render={() => <Redirect to="/tabs/home" />} exact={true} />
      </IonRouterOutlet>

      <IonTabBar slot="bottom">
        <IonTabButton mode="md" layout="label-hide" tab="tab1" href="/tabs/home">
          <IonIcon size="medium" icon={homeOutline} />
		  <span>Inicio</span>
        </IonTabButton>

        <IonTabButton tab="lineas" mode="md" layout="label-hide" href="/tabs/lineas">
			<IonIcon size="medium" icon={logoWhatsapp} />
			<span>Líneas</span>
        </IonTabButton>
		
		<IonTabButton tab="pedidos" mode="md" layout="label-hide" href="/tabs/pedidos">
			<IonIcon size="medium" src="/img/pedidos.svg" />
			<span>Pedidos</span>
        </IonTabButton>
		
		<IonTabButton tab="notas" mode="md" layout="label-hide" href="/tabs/facturacion">
			<IonIcon size="medium" icon={cardOutline} />
			<span>Facturación</span>
        </IonTabButton>
		
		{/*<IonTabButton tab="notas" mode="md" layout="label-hide" href="/tabs/notas">
			<IonIcon size="medium" icon={receiptOutline} />
			<span>Notas</span>
        </IonTabButton>*/}
		
		<IonTabButton tab="tab2" mode="md" layout="label-hide" href="/tabs/configuracion">
			<IonIcon size="medium" icon={settingsOutline} />
			<span>Config.</span>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
	);
};

export default observer(HomePage);
